import { React, useState } from 'react';
import './App.css';
import Navbar from './Components/Navbar/Navbar';
import Sidebar from './Components/Sidebar/Sidebar';
import {
  BrowserRouter,
  Navigate,
  Routes,
  Route
} from "react-router-dom";
import Dashboard from './Pages/Dashboard';
import Products from './Pages/Products';
import Profile from './Pages/Profile';
import Collections from './Pages/Collection';
import ListPurchaseOrder from './Pages/ListPurchaseOrder';
import AdminUsers from './Pages/AdminUsers';
import AdminProfile from './Pages/AdminProfile';
import ProductClassification from './Pages/ProductClassification';
import Missing from './Pages/Missing'
import Cookies from 'universal-cookie';
import ProducStudy from './Pages/ProductStudy';
import Provider from './Pages/Provider';
import PurchaseOrder from './Pages/PurchaseOrder';

import ShowPurchaseOrder from './Pages/ShowPurchaseOrder';
import StockRoom from './Pages/StockRoom';
import ListStockRoom from './Pages/ListStockRoom';
import Layout from "./Components/global/Layout"; //Sidebar
import PrimarySearchAppBar from "./Components/global/AppBar/PrimarySearchAppBar"; //topbar
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";

import Login from './Pages/auth/Login';
const cookies = new Cookies();

function App() {
  const [theme, colorMode] = useMode();
  const [token, setToken] = useState(cookies.get('token'));
  let routes;

  if (token) {
    routes = (
      <Routes>
        <Route path="/" element={<Navigate to="/dashboard" replace />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/products" element={<Products />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/collections" element={<Collections />} />
        <Route path="/admStockRoom/:id" element={<ListPurchaseOrder />} />
        <Route path="/adminUsers" element={<AdminUsers />} />
        <Route path="/adminProfile" element={<AdminProfile />} />
        <Route path="/clasificacion" element={<ProductClassification />} />
        <Route path="/productStudy" element={<ProducStudy />} />
        <Route path="/products/providers" element={<Provider />} />
        <Route path="/purchaseOrder" element={<PurchaseOrder />} />
        <Route path="/purchaseOrder/:id" element={<ShowPurchaseOrder />} />
        <Route path="/listPurchaseOrder" element={<StockRoom />} />
        <Route path="/listingresoorden" element={<ListStockRoom />} />
        {/* catch all */}
        <Route path="*" element={<Missing />} />

      </Routes>
    );
  } else {
    routes = (
      <Routes>
        <Route path="/auth" element={<Login />} />
        <Route
          path="*"
          element={<Navigate to="/auth" replace />}
        />

      </Routes>
    );
  }

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <div id="app" style={{ height: "100vh", width: "100%", display: "flex", overflow: "none"}}>
          {token && <Layout />}
          <main style={{ width: "100%"}}>
            <CssBaseline />
            {token && <PrimarySearchAppBar />}
            {routes}
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
