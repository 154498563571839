import React, { useState, useEffect } from 'react';
import './styles.css'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import LinearProgress from '@mui/material/LinearProgress';
import { MdDelete, MdOutlineModeEditOutline } from 'react-icons/md';
import { TbSortAscendingLetters, TbSortDescendingLetters, TbSortAscendingNumbers, TbSortDescendingNumbers } from 'react-icons/tb'
import Swal from 'sweetalert2' //npm install sweetalert2
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Formik } from 'formik';
import Pagination from 'react-bootstrap/Pagination';
import Grid from '@mui/material/Grid';
import api from "../services/api";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import {
    HiInformationCircle
} from "react-icons/hi";
import LoadPage from '../Components/LoadPage/LoadPage';
import Cookies from 'universal-cookie';
import { PageErrorPermisos } from './PageErrorPermisos';

const cookies = new Cookies();


function Products() {
    //Valida el formulario
    const [validated, setValidated] = useState(false);
    //Hooks para agregar y listar los prdouctos
    const [listaProductos, setListaProductos] = useState([])
    const [idProducto, setIdProducto] = useState("")
    const [image, setImage] = useState({ preview: '', data: '', valido: true })
    const [name, setName] = useState("")
    const [sku, setSKU] = useState("")
    const [cost, setcost] = useState(0)
    const [price, setPrice] = useState(0)
    const [stock, setStock] = useState(0)
    const [colecciones, setColecciones] = useState("")
    const [listaColecciones, setListaColecciones] = useState([])
    const [observaciones, setObservaciones] = useState("")
    const [permisos, setPermisos] = useState(undefined)
    const [filtroColecciones, setFiltroColecciones] = useState("Colecciones")
    const [seleccion, setSeleccion] = useState("")
    const [ascendente, setAscendente] = useState(true)
    const [modalDemanda, setModalDemanda] = useState(false)
    const [demanda, setDemanda] = useState("0");
    //hooks para la busqueda
    const [productos, setProductos] = useState([])
    const [busqueda, setBusqueda] = useState("");

    //Efffect para obtener productos
    useEffect(() => {
        getProductos();
    }, [])

    //Valores iniciales
    const [currentPage, setCurrentPage] = useState(1);
    const [PerPage, setPerPage] = useState(5);

    // Obtiene los indices a partir de la lista
    const totalPages = Math.ceil(productos.length / PerPage);
    const indexOfLast = currentPage * PerPage;
    const indexOfFirst = indexOfLast - PerPage;
    const currentListProducts = productos.slice(indexOfFirst, indexOfLast);

    // Cambiar pagina
    const paginate = pageNumber => setCurrentPage(pageNumber);

    const createPaginationItem = (i) => {
        return <Pagination.Item
            key={i}
            active={i === currentPage}
            onClick={() => paginate(i)}
        >
            {i}
        </Pagination.Item>
    };


    const createListPagination = () => {
        const actualpage = currentPage
        const leftBound = actualpage > 3 ? actualpage - 1 : 2;
        const pageBound = actualpage !== totalPages ? actualpage === 1 ? 3 : actualpage === 2 ? 3 : actualpage : 0;
        const rightBound = pageBound === 0 ? 0 : pageBound + 1 !== totalPages ? pageBound + 1 : 0;
        const leftPage = leftBound + 2 === totalPages ? leftBound - 1 : actualpage === totalPages ? actualpage - 3 : leftBound < totalPages ? leftBound : 0;
        const page = leftBound + 2 === totalPages ? leftBound : actualpage === totalPages ? actualpage - 2 : pageBound < totalPages ? pageBound : 0;
        const rightPage = leftBound + 2 === totalPages ? leftBound + 1 : actualpage === totalPages ? actualpage - 1 : rightBound < totalPages ? rightBound : 0;
        return <>
            {leftPage > 2 && (<Pagination.Ellipsis />)}
            {leftPage > 1 && (<>{createPaginationItem(leftPage)}</>)}
            {page > 1 && (<>{createPaginationItem(page)}</>)}
            {rightPage > 1 && (<>{createPaginationItem(rightPage)}</>)}
            {rightPage < totalPages - 3 && (<Pagination.Ellipsis />)}
        </>
    };
    //el filtro de la busqueda por el termino de busqueda
    const filtrar = (terminoBusqueda) => {
        var resultadosBusqueda = listaProductos.filter((item) => {
            if (item.name?.toString().toLowerCase().includes(terminoBusqueda.toLowerCase())
                || item.sku?.toString().toLowerCase().includes(terminoBusqueda.toLowerCase())
            ) {
                return item;
            }
        });
        setProductos(resultadosBusqueda);
    }
    //busqueda
    const handleChange = e => {
        setBusqueda(e.target.value);
        filtrar(e.target.value);
    }
    //Modal Agregar
    const [show, setShow] = useState(false);
    //Handle del modal add
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setModalDemanda(false);
        if (permisos === 2 || permisos === 3 || permisos > 5) {
            const img = {
                preview: '',
                data: '',
                valido: true
            }
            setImage(img)
            setName("")
            setSKU("")
            setcost("")
            setPrice("")
            setStock("")
            setColecciones(listaColecciones[0].name)
            setObservaciones("")
            setValidated(false)
            setShow(true)
        } else {
            //Alerta de error por no tener permiso
            Swal.fire({
                icon: 'error',
                title: "No tienes permiso",
                showConfirmButton: false,
                timer: 2000
            })
        }
    };
    //Para abrir o cerrar  el modal de actualizar la demanda de un producto
    const showModalDemanda = (estado, item) => {
        setShow(estado);
        setModalDemanda(estado);
        setIdProducto(item._id);
        setName(item.name);
    }
    //Modal Edit
    const [showEdit, setShowEdit] = useState(false);
    //Handle del modal edit
    const handleCloseEdit = () => setShowEdit(false);
    const handleShowEdit = () => {
        setModalDemanda(false);
        if (permisos === 2 || permisos === 3 || permisos > 5) {
            setValidated(false)
            setShowEdit(true);
        } else {
            //Alerta de error por no tener permiso
            Swal.fire({
                icon: 'error',
                title: "No tienes permiso",
                showConfirmButton: false,
                timer: 2000
            })
        }
    }
    //Funcion para obtener los productos
    const getProductos = async () => {
        if (filtroColecciones === "Colecciones") {
            const res = await api.get('/products/')
            setListaProductos(res.data);
            setProductos(res.data);
        } else {
            const res = await api.get('/collections/' + filtroColecciones)
            setListaProductos(res.data);
            setProductos(res.data);
        }
        getPermisos();
        getCollections();
    }

    //Funcion que valida la extension del archivo y almmacena su estado
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        let valido = true
        switch (file.type) {
            case 'image/png':
                valido = true
                break;
            case 'image/jpg':
                valido = true
                break;
            case 'image/jpeg':
                valido = true
                break;
            case '':
                valido = true
                break;
            default:
                valido = false
                ///Mensaje de error
                Swal.fire({
                    icon: 'error',
                    title: "Formato de la imagen no válido. Solo .jpeg, .jpg y .png",
                    showConfirmButton: false,
                    timer: 2000
                })
        }
        if (valido) {
            const img = {
                preview: URL.createObjectURL(file),
                data: file,
                valido: valido
            }
            setImage(img)
        }

    }

    //Funcion para obtener los permisos del usuario
    const getPermisos = async () => {
        const res = await api.get('/permisos/usuario/' + cookies.get("idPerfil"))
        res.data.permisos.forEach(element => {
            if (element.name === "Products") {
                setPermisos(element.permisos)
            }
        });
    }
    //Funcion para agregar los productos
    const getCollections = async () => {
        const res = await api.get('/collections/')
        setListaColecciones(res.data)
    }
    //Funcion para agregar los productos 
    const addProducto = async () => {
        let formData = new FormData()
        formData.append('image', image.data)
        formData.append('name', name)
        formData.append('sku', sku)
        formData.append('cost', cost)
        formData.append('price', price)
        formData.append('stock', stock)
        formData.append('coleccion', colecciones)
        formData.append('observaciones', observaciones)
        formData.append('creator', cookies.get("idUsuario"))
        await api.post('/products/createProduct', formData).then((res) => {
            if (res.data.status) {
                //Refresacar la pagina
                getProductos()
                //Alerta de agregado
                Swal.fire({
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 2000
                })
                //Para cerrar el modal
                setShow(false)
            }
        }).catch((err) => {
            console.log(err);
        })
    }
    //funcion para obtener un product. Al presionar el boton edit pasa el producto de la fila seleccionada para obtner el producto y mostralo en el modal
    const getProduct = async (item) => {
        //Mostrar el modal
        const img = {
            preview: item.image,
            data: '',
            valido: true
        }
        setImage(img)
        setIdProducto(item._id)
        setName(item.name)
        setSKU(item.sku)
        setPrice(item.price)
        setcost(item.cost)
        setStock(item.stock)
        setColecciones(item.collectionName)
        setObservaciones(item.observations)
        handleShowEdit(true)
    }
    //Funcion para actualizar el product
    const updateProduct = async (e) => {
        e.preventDefault();
        const id = idProducto
        let formData = new FormData()
        formData.append('image', image.data)
        formData.append('name', name)
        formData.append('sku', sku)
        formData.append('cost', cost)
        formData.append('price', price)
        formData.append('stock', stock)
        formData.append('coleccion', colecciones)
        formData.append('observaciones', observaciones)
        await api.put('/products/updateProduct/' + id, formData).then(async (res) => {
            if (res.data.status) {
                //Refresacar la pagina
                getProductos();
                //Alerta de actualizado
                Swal.fire({
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 2000
                })
                //Para cerrar el modal
                setShowEdit(false)
            } else {
                //logs de error
                const mensaje = {
                    message: "Error al actualizar un producto",
                    level: 3
                }
                await api.post('/logger', mensaje)
                //Alerta de actualizado
                Swal.fire({
                    icon: 'error',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        }).catch((err) => {
            console.log(err)
            Swal.fire({
                icon: 'error',
                title: err.response.data.message || "Error al actualizar",
                showConfirmButton: false,
                timer: 2000
            })
        })


    }
    const changeCollection = async (collection) => {
        setFiltroColecciones(collection)
        if (collection !== "Colecciones") {
            const res = await api.get('/collections/' + collection)
            setListaProductos(res.data);
            setProductos(res.data);
        } else {
            const res = await api.get('/products/')
            setListaProductos(res.data);
            setProductos(res.data);
        }
        setCurrentPage(1)
    }


    //Funcion para eliminar un producto 
    const deleteProduct = async (id) => {
        if (permisos === 1 || permisos === 3 || permisos === 5 || permisos === 7) {
            Swal.fire({
                title: '¿Estás seguro de eliminar el producto?',
                text: "¡No podrás revertirlo!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const res = await api.delete('/products/deleteProduct/' + id)
                    if (res.data.status) {
                        //Refresacar la pagina
                        getProductos();
                        //Alerta de editado
                        Swal.fire({
                            icon: 'success',
                            title: res.data.message,
                            showConfirmButton: false,
                            timer: 2000
                        })
                    } else {
                        //logs de error
                        const mensaje = {
                            message: "Error al eliminar un producto",
                            level: 3
                        }
                        await api.post('/logger', mensaje)
                        ///Mensaje de error
                        Swal.fire({
                            icon: 'error',
                            title: res.data.message,
                            showConfirmButton: false,
                            timer: 2000
                        })
                    }
                }
            });
        } else {
            //Alerta de error por no tener permiso
            Swal.fire({
                icon: 'error',
                title: "No tienes permiso",
                showConfirmButton: false,
                timer: 2000
            })
        }
    }
    const ordenar = async type => {
        if (type === seleccion) {
            setAscendente(!ascendente);
        } else {
            setSeleccion(type);
            setAscendente(true);
        }
        const sort = ascendente ? "mayor" : "menor";
        const res = await api.get('/products/' + type + '/' + sort);
        setListaProductos(res.data);
        setProductos(res.data);
    }
    const selectFile = async (event) => {
        const formData = new FormData();
        formData.append("xlsx", event.target.files[0]);
        Swal.fire({
            position: 'center',
            title: 'Espera un momento...',
            showConfirmButton: false,
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            },
        })
        await api.post('/products/upload/', formData, {
            headers: {
                'id': cookies.get("idUsuario")
            }
        }).then(async (response) => {
            Swal.close()
            if (response.data.status) {
                //Refresacar la pagina
                getProductos();
                //Alerta de editado
                Swal.fire({
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: true
                })
            }
        }).catch(async (err) => {
            Swal.close()
            if (err.response.status === 400) {
                Swal.fire({
                    icon: 'error',
                    title: err.response.data.message,
                    showConfirmButton: true
                })
            } else {
                //logs de error
                const mensaje = {
                    message: "Error al agregar un producto a partir de un archivo",
                    level: 3
                };
                await api.post('/logger', mensaje);
                ///Mensaje de error
                Swal.fire({
                    icon: 'error',
                    title: mensaje.message,
                    showConfirmButton: true
                })
            }
        })
        event.target = null;
    }
    const colocarDemanda = async () => {
        const demandaProducto = {
            demandaAnualPromedio: demanda
        };
        await api.put('/products/putDemanda/' + idProducto, demandaProducto)
            .then(async response => {
                if (response.data.status) {
                    //Refresacar la pagina
                    getProductos();
                    //alerta
                    Swal.fire({
                        icon: 'success',
                        title: response.data.message,
                        showConfirmButton: false,
                        timer: 2000
                    });
                    //Para cerrar el modal
                    setShow(false);
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: response.data.message,
                        showConfirmButton: true
                    })
                }
            })
    }
    if (permisos === undefined) {
        return (
            <LinearProgress />
        )
    }
    if (permisos === undefined) {
        return (
            <PageErrorPermisos />
        )
    }
    return (
        <div className='Contenedor'>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <h3>Productos</h3>
                </Grid>
                <Grid item xs={8}>
                </Grid>
                <Grid item xs={2}>
                    <Button variant="primary" onClick={handleShow}>
                        Añadir Producto
                    </Button>
                </Grid>
                <Grid item xs={2}>
                    <IconButton color="primary" aria-label="upload file" component="label" >
                        <input hidden accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" type="file" onChange={selectFile} />
                        <FileUploadIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <div className='row'>
                <div className='col-sm-12'></div>
                <div className='col-sm-9'></div>
                <div className='col-sm-1'>
                </div>
                <div className='col-sm-1'>

                </div>
                <div className='col-sm-12'>
                    <div className="containerInput">
                        <input
                            className="form-control inputBuscar"
                            value={busqueda}
                            placeholder="Búsqueda por el nombre o sku del producto"
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className='col-sm-12'>
                    <div className='cuerpo-table'>
                        <table className="table-responsive table-hover">
                            <thead>
                                <tr className="h6">
                                    <th colSpan={2}>Opciones</th>
                                    <th></th>
                                    <th><a href="#nombre" onClick={() => ordenar("name")}>Nombre {seleccion === "name" && (ascendente ? <TbSortAscendingLetters /> : <TbSortDescendingLetters />)}</a></th>
                                    <th><a href="#sku" onClick={() => ordenar("sku")}>SKU {seleccion === "sku" && (ascendente ? <TbSortAscendingLetters /> : <TbSortDescendingLetters />)}</a></th>
                                    <th><a href="#costo" onClick={() => ordenar("cost")}>Costo {seleccion === "cost" && (ascendente ? <TbSortAscendingNumbers /> : <TbSortDescendingNumbers />)}</a></th>
                                    <th><a href="#precio" onClick={() => ordenar("price")}>Precio {seleccion === "price" && (ascendente ? <TbSortAscendingNumbers /> : <TbSortDescendingNumbers />)}</a></th>
                                    <th><a href="#stock" onClick={() => ordenar("stock")}>Stock {seleccion === "stock" && (ascendente ? <TbSortAscendingNumbers /> : <TbSortDescendingNumbers />)}</a></th>
                                    <th>
                                        <TextField
                                            id="outlined-select-currency"
                                            select
                                            defaultValue="Colecciones"
                                            onChange={(event) => changeCollection(event.target.value)}
                                            value={filtroColecciones}
                                        >
                                            <MenuItem value="Colecciones">
                                                Colecciones
                                            </MenuItem>
                                            {listaColecciones.map((option) => (
                                                <MenuItem key={option._id} value={option.name}>
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </th>
                                    <th>Modelo</th>
                                    <th>Utilidad  </th>
                                    <th>Demanda An. Pr.<OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-mantenimiento"> Demanda Anual Promedio</Tooltip>}>
                                        <span >
                                            <HiInformationCircle />
                                        </span>
                                    </OverlayTrigger>
                                    </th>
                                    <th>CO<OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-mantenimiento"> Cantidad Óptima para realizar un pedido</Tooltip>}>
                                        <span >
                                            <HiInformationCircle />
                                        </span>
                                    </OverlayTrigger>
                                    </th>
                                    <th>PR
                                        <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-mantenimiento"> Punto de Reorden para comparar más productos</Tooltip>}>
                                            <span >
                                                <HiInformationCircle />
                                            </span>
                                        </OverlayTrigger>
                                    </th>
                                    <th>SG
                                        <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-mantenimiento"> Stock de seguridad para pedidos</Tooltip>}>
                                            <span >
                                                <HiInformationCircle />
                                            </span>
                                        </OverlayTrigger>
                                    </th>
                                    <th>Clasificación</th>
                                    <th>Observaciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {permisos < 4 ? <tr><td colSpan={16}>No tienes permiso para ver esta tabla</td></tr> :
                                    <> {currentListProducts && currentListProducts.map(item => (
                                        <tr key={item._id}>
                                            <td><Button variant="outline-dark" onClick={() => getProduct(item)} ><MdOutlineModeEditOutline /></Button></td>
                                            <td><Button variant="outline-danger" onClick={() => deleteProduct(item._id)}><MdDelete /></Button></td>
                                            <td>{item.image === undefined ? <img src="https://reclycar.es/assets/img/default.png" alt="imagen de perfil" height='50' /> : <img src={item.image} alt="imagen de perfil" height='50' />} {" "}</td>
                                            <td>{item.name}</td>
                                            <td>{item.sku}</td>
                                            <td>{item.cost}</td>
                                            <td>{item.price}</td>
                                            <td>{item.stock}</td>
                                            <td>{item.collectionName}</td>
                                            <td>{item.model?.name}</td>
                                            <td>{item.utility}</td>
                                            <td>{item.averageAnnualDemand ? item.averageAnnualDemand
                                                : <Button bg="secondary" pill size="sm"
                                                    onClick={() => showModalDemanda(true, item)}
                                                >
                                                    Agregar demanda
                                                </Button>}</td>
                                            <td>{item.model?.optimalAmount}</td>
                                            <td>{item.model?.reorderPoint}</td>
                                            <td>{item.model?.securityStock}</td>
                                            <td>{item.clasification.type}</td>
                                            <td>{item.observations}</td>
                                        </tr>
                                    ))}
                                    </>}
                            </tbody>
                        </table>

                        <div className='row'>
                            <div className="col-sm-8">
                                <nav aria-label="Page navigation example">
                                    <div className="pagination">
                                        <li
                                            className="page-item"
                                            onClick={() => paginate(currentPage - 1)}
                                            disabled={currentPage === 1}
                                        />
                                        {createPaginationItem(1)}
                                        {totalPages > 1 && (<>
                                            {createListPagination()}
                                            {createPaginationItem(totalPages)}
                                        </>)}
                                        <div
                                            className="page-item"
                                            onClick={() => paginate(currentPage + 1)}
                                            disabled={currentPage === totalPages}
                                        />
                                    </div>
                                </nav>
                            </div>
                            <div className="col-sm-2">
                                <select
                                    defaultValue="5"
                                    onChange={(event) => { setPerPage(event.target.value); paginate(1) }}
                                    value={PerPage}
                                >
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                            <div className="col-sm-2">
                                Total de productos: {productos.length}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        {modalDemanda ? "Demanda del producto - " + name : "Añadir producto"}
                    </Modal.Title>
                </Modal.Header>
                {!modalDemanda ? (<>
                    <Formik
                        validate={() => {
                            setValidated(false)
                            let errores = {};
                            // Validacion nombre
                            if (!name) {
                                errores.name = '*Por favor ingresa una nombre. Campo obligatorio*'
                            } else if (!/^[\wÀ-ÿ\s,"-.:-;+*~´°!"#$%&/()=?|¬¨^`<>]{1,100}$/.test(name)) {
                                errores.name = 'El nombre no es correcto, no puede sobre pasar los 100 caracteres'
                            }
                            // Validacion SKU
                            if (!/^[\wÀ-ÿ\s,"-.:-;+*~´°!"#$%&/()=?|¬¨^`<>]*$/.test(sku)) {
                                errores.sku = 'El SKU no puede tener espacios'
                            }
                            // Validacion price
                            if (!/^\d*(?:\.{0,1}\d{0,4})?$/.test(price)) {
                                errores.price = 'El precio es un número entero. Ejemplo: 100.00'
                            }
                            // Validacion costo
                            if (!/^\d*(?:\.{0,1}\d{0,4})?$/.test(cost)) {
                                errores.cost = 'El precio es un número entero. Ejemplo: 145.00'
                            }
                            // Validacion stock
                            if (!/^[0-9]*$/.test(stock)) {
                                errores.stock = 'El stock es un número entero. Ejemplo: 20'
                            }
                            if (!/^[\wÀ-ÿ\s,"-.:-;+*~´°!"#$%&/()=?|¬¨^`<>]*$/.test(observaciones)) {
                                errores.observations = 'La observación es incorrecta'
                            }
                            if (!image.valido) {
                                errores.file = 'La tipo de archivo es incorrecto. Solo se permite tipo .jpg, .png y jpeg'
                            }

                            if (!errores.name) {
                                setValidated(true)
                            }
                            return errores;
                        }}
                    >
                        {({
                            handleBlur,
                            touched,
                            errors,
                        }) => (
                            <Form >
                                <Modal.Body className="mb-3">
                                    <h6>El nombre es obligatorio</h6>
                                    <Row className="mb-3">
                                        <Form.Group className="position-relative mb-3">
                                            <Form.Label>Imagen de Perfil: </Form.Label>
                                            {image.preview && <img src={image.preview} alt="imagen de perfil" width='100' height='100' />}
                                            <IconButton color="primary" aria-label="upload picture" component="label">
                                                <input hidden accept="image/png, image/jpeg, image/jpg" onChange={handleFileChange} type="file" />
                                                <PhotoCamera />
                                            </IconButton>
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" >
                                            <Form.Label>Nombre</Form.Label>
                                            <Form.Control
                                                type="text"
                                                id="name"
                                                name="name"
                                                placeholder="Nombre"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                onBlur={handleBlur}
                                                isValid={touched.name && !errors.name}
                                                isInvalid={!!errors.name}
                                            />
                                            <Form.Control.Feedback type="invalid" >
                                                {errors.name}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6">
                                            <Form.Label>SKU</Form.Label>
                                            <Form.Control
                                                type="text"
                                                id="sku"
                                                name="sku"
                                                placeholder="SKU"
                                                value={sku}
                                                onChange={(e) => setSKU(e.target.value)}
                                                onBlur={handleBlur}
                                                isValid={touched.sku && !errors.sku}
                                                isInvalid={!!errors.sku}
                                            />
                                            <Form.Control.Feedback type="invalid" >
                                                {errors.sku}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="4">
                                            <Form.Label>Costo</Form.Label>
                                            <Form.Control
                                                type="number"
                                                id="cost"
                                                name="cost"
                                                placeholder="Costo"
                                                value={cost}
                                                onChange={(e) => setcost(e.target.value)}
                                                onBlur={handleBlur}
                                                isValid={touched.cost && !errors.cost}
                                                isInvalid={!!errors.cost}
                                            />
                                            <Form.Control.Feedback type="invalid" >
                                                {errors.cost}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4">
                                            <Form.Label>Precio</Form.Label>
                                            <Form.Control
                                                type="number"
                                                id="price"
                                                name="price"
                                                placeholder="Precio"
                                                value={price}
                                                onChange={(e) => setPrice(e.target.value)}
                                                onBlur={handleBlur}
                                                isValid={touched.price && !errors.price}
                                                isInvalid={!!errors.price}
                                            />
                                            <Form.Control.Feedback type="invalid" >
                                                {errors.price}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4">
                                            <Form.Label>Stock</Form.Label>
                                            <Form.Control
                                                type="number"
                                                id="stock"
                                                name="stock"
                                                placeholder="Stock"
                                                value={stock}
                                                onChange={(e) => setStock(e.target.value)}
                                                onBlur={handleBlur}
                                                isValid={touched.stock && !errors.stock}
                                                isInvalid={!!errors.stock}
                                            />
                                            <Form.Control.Feedback type="invalid" >
                                                {errors.stock}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3 position-relative">
                                        <Form.Group as={Col} controlId="formGridState">
                                            <Form.Label>Colección</Form.Label>
                                            <Form.Select
                                                as="select"
                                                id="colecciones"
                                                name="colecciones"
                                                value={colecciones}
                                                onChange={(e) => setColecciones(e.target.value)}
                                            >
                                                {listaColecciones.map(item => (
                                                    <option key={item._id} value={item.name}>{item.name}</option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="12">
                                            <Form.Label>Observación</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                id="observaciones"
                                                name="observaciones"
                                                placeholder="observaciones"
                                                value={observaciones}
                                                onChange={(e) => setObservaciones(e.target.value)}
                                                onBlur={handleBlur}
                                                isValid={touched.observations && !errors.observations}
                                                isInvalid={!!errors.observations}
                                            />
                                            <Form.Control.Feedback type="invalid" >
                                                {errors.observations}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleClose}>
                                        Cancelar
                                    </Button>
                                    <Button variant="primary" onClick={addProducto} disabled={!validated}>
                                        Guardar
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        )}
                    </Formik></>) :
                    (<>
                        <Modal.Body>
                            <Form>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label> Demanda anual promedio:
                                        <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-mantenimiento"> Demanda anual es ...</Tooltip>}>
                                            <span >
                                                <HiInformationCircle />
                                            </span>
                                        </OverlayTrigger></Form.Label>
                                    <Form.Control
                                        type="number"
                                        autoFocus
                                        placeholder="0.00" aria-label="Amount (to the nearest dollar)"
                                        onChange={(e) => setDemanda(e.target.value)} value={demanda}
                                    />
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Cancelar
                            </Button>
                            <Button variant="primary" onClick={colocarDemanda}>
                                Aplicar demanda
                            </Button>
                        </Modal.Footer>

                    </>)
                }

            </Modal>
            <Modal show={showEdit} onHide={handleCloseEdit} size="lg" >
                <Modal.Header closeButton>
                    <Modal.Title><h3>Editar Producto</h3></Modal.Title>
                </Modal.Header>
                <Formik
                    validate={() => {
                        let errores = {};
                        // Validacion nombre
                        if (!name) {
                            errores.name = '*Por favor ingresa una nombre. Campo obligatorio*'
                        } else if (!/^[\wÀ-ÿ\s,"-.:-;+*~´°!"#$%&/()=?|¬¨^`<>]{1,100}$/.test(name)) {
                            errores.name = 'El nombre no es correcto, no puede sobre pasar los 100 caracteres'
                        }
                        // Validacion SKU
                        if (!/^[\wÀ-ÿ\s,"-.:-;+*~´°!"#$%&/()=?|¬¨^`<>]*$/.test(sku)) {
                            errores.sku = 'El SKU no puede tener espacios'
                        }
                        // Validacion price
                        if (!/^\d*(?:\.{0,1}\d{0,4})?$/.test(price)) {
                            errores.price = 'El precio es un número entero. Ejemplo: 100.00. No obligatorio.'
                        }
                        // Validacion costo
                        if (!/^\d*(?:\.{0,1}\d{0,4})?$/.test(cost)) {
                            errores.cost = 'El precio es un número entero. Ejemplo: 145.00. No obligatorio.'
                        }
                        // Validacion stock
                        if (!/^[0-9]*$/.test(stock)) {
                            errores.stock = 'El stock es un número entero. Ejemplo: 20. No obligatorio.'
                        }
                        if (!/^[\wÀ-ÿ\s,"-.:-;+*~´°!"#$%&/()=?|¬¨^`<>]*$/.test(observaciones)) {
                            errores.observations = 'La observación es incorrecta'
                        }
                        if (!image.valido) {
                            errores.file = 'La tipo de archivo es incorrecto. Solo se permite tipo .jpg, .png y jpeg'
                        }

                        if (!errores.name) {
                            setValidated(true)
                        }
                        return errores;
                    }}
                >
                    {({
                        handleBlur,
                        touched,
                        errors,
                    }) => (
                        <Form>
                            <Modal.Body>
                                <h6>*Los campos nombre y colecciones son obligatorios*</h6>
                                <Row className="mb-3">
                                    <Form.Group className="position-relative mb-3">
                                        <Form.Label>Imagen de Perfil</Form.Label>
                                        {image.preview && <img src={image.preview} alt="imagen de perfil" width='100' height='100' />}
                                        <IconButton color="primary" aria-label="upload picture" component="label">
                                            <input hidden accept="image/png, image/jpeg, image/jpg" onChange={handleFileChange} type="file" />
                                            <PhotoCamera />
                                        </IconButton>
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="6" >
                                        <Form.Label>Nombre</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="name"
                                            name="name"
                                            placeholder="Nombre"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            onBlur={handleBlur}
                                            isValid={touched.name && !errors.name}
                                            isInvalid={!!errors.name}
                                        />
                                        <Form.Control.Feedback type="invalid" >
                                            {errors.name}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="6">
                                        <Form.Label>SKU</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="sku"
                                            name="sku"
                                            placeholder="SKU"
                                            value={sku == null ? "" : sku}
                                            onChange={(e) => setSKU(e.target.value)}
                                            onBlur={handleBlur}
                                            isValid={touched.sku && !errors.sku}
                                            isInvalid={!!errors.sku}
                                        />
                                        <Form.Control.Feedback type="invalid" >
                                            {errors.sku}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="4">
                                        <Form.Label>Costo</Form.Label>
                                        <Form.Control
                                            type="number"
                                            id="cost"
                                            name="cost"
                                            placeholder="Costo"
                                            value={cost == null ? 0 : cost}
                                            onChange={(e) => setcost(e.target.value)}
                                            onBlur={handleBlur}
                                            isValid={touched.cost && !errors.cost}
                                            isInvalid={!!errors.cost}
                                        />
                                        <Form.Control.Feedback type="invalid" >
                                            {errors.cost}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4">
                                        <Form.Label>Precio</Form.Label>
                                        <Form.Control
                                            type="number"
                                            id="price"
                                            name="price"
                                            placeholder="Precio"
                                            value={price == null ? "0" : price}
                                            onChange={(e) => setPrice(e.target.value)}
                                            onBlur={handleBlur}
                                            isValid={touched.price && !errors.price}
                                            isInvalid={!!errors.price}
                                        />
                                        <Form.Control.Feedback type="invalid" >
                                            {errors.price}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4">
                                        <Form.Label>Stock</Form.Label>
                                        <Form.Control
                                            type="number"
                                            id="stock"
                                            name="stock"
                                            placeholder="Stock"
                                            value={stock == null ? "0" : stock}
                                            onChange={(e) => setStock(e.target.value)}
                                            onBlur={handleBlur}
                                            isValid={touched.stock && !errors.stock}
                                            isInvalid={!!errors.stock}
                                        />
                                        <Form.Control.Feedback type="invalid" >
                                            {errors.stock}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col}>
                                        <Form.Label>Colección</Form.Label>
                                        <Form.Select
                                            as="select"
                                            id="colecciones"
                                            name="colecciones"
                                            value={colecciones}
                                            onChange={(e) => setColecciones(e.target.value)}
                                            onBlur={handleBlur}
                                            isValid={touched.colecciones && !errors.colecciones}
                                            isInvalid={!!errors.colecciones}
                                        >
                                            {listaColecciones.map(item => (
                                                <option value={item.name} key={item._id} >{item.name}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="12">
                                        <Form.Label>Observación</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            id="observaciones"
                                            name="observaciones"
                                            placeholder="observaciones"
                                            value={observaciones}
                                            onChange={(e) => setObservaciones(e.target.value)}
                                            onBlur={handleBlur}
                                            isValid={touched.observations && !errors.observations}
                                            isInvalid={!!errors.observations}
                                        />
                                        <Form.Control.Feedback type="invalid" >
                                            {errors.observations}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleCloseEdit}>
                                    Cancelar
                                </Button>
                                <Button variant="primary" onClick={updateProduct} disabled={!validated}>
                                    Guardar
                                </Button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </div >
    )
}
export default Products
