import React, { useState, useEffect } from 'react';
import api from "../../services/api";
import Table from 'react-bootstrap/esm/Table';
import { FaBoxes, FaReceipt } from 'react-icons/fa';
import Swal from "sweetalert2";
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import './pages.css';

const ListCategorias = ({ clasificacion }) => {
    const [productosProximaCompra, setProductosProximaCompra] = useState([]);
    const [productosCompraImportante, setProductosCompraImportante] = useState([]);
    const [productosCompraUrgente, setProductosCompraUrgente] = useState([]);
    //const lista = [{ name: "Producto 1", estado: "Compra Urgente" }, { name: "Producto 2", estado: "Compra Urgente" }, { name: "Producto 3", estado: "Compra importante" }, { name: "Producto 4", estado: "Compra importante" }, { name: "Producto 5", estado: "Próximo a requerir compra" }]

    const navigate = useNavigate();


    useEffect(() => {
        //Funcion para obtener los permisos del usuario
        const getProductos = async () => {
            const res = await api.get('/products/getProductsByAlertAndClasification/' + clasificacion);
            setProductosProximaCompra(res.data.productosProximaCompra);
            setProductosCompraImportante(res.data.productosCompraImportante);
            setProductosCompraUrgente(res.data.productosCompraUrgente);
        };
        getProductos();
    }, []);

    const abrirOrdenDeCompra = () => {
        Swal.fire({
            title: '¿Deseas hacer una Orden de Compra?',
            text: "Serás redireccionado a la pagina de Orden de Compra",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, la haré',
            cancelButtonText: "No"
        }).then((result) => {
            if (result.isConfirmed) {
                navigate('/purchaseOrder', { replace: true });
            }
        })
    }
    return (
        <div className="ListaProductos">
            <h5>Categoría {clasificacion}</h5>
            <Table responsive="sm borderless">
                <tbody>
                    {productosCompraUrgente.map(item => (
                        <tr >
                            <td className={clasificacion === "A" ? "color-urgente" : "color-importante"}><FaBoxes /></td>
                            <td className="d-flex justify-content-between align-items-start">
                                <p>{item.name} - Compra Urgente  </p>
                                <Button variant="primary"
                                    onClick={() => abrirOrdenDeCompra()} size="sm">
                                    <FaReceipt />
                                </Button>
                            </td>
                        </tr>
                    )
                    )}
                    {productosCompraImportante.map(item => (
                        <tr href="/purchaseOrder">
                            <td className="color-importante"><FaBoxes /></td>
                            <td className="d-flex justify-content-between align-items-start">
                                <p>{item.name} - Compra importante </p>
                                <Button variant="primary"
                                    onClick={() => abrirOrdenDeCompra()} size="sm">
                                    <FaReceipt />
                                </Button>
                            </td>
                        </tr>
                    )
                    )}
                    {productosProximaCompra.map(item => (
                        <tr >
                            <td className="color-proximo" ><FaBoxes /></td>
                            <td className="d-flex justify-content-between align-items-start">
                                <p>{item.name} - Próximo a requerir compra </p>
                                <Button variant="primary"
                                    onClick={() => abrirOrdenDeCompra()} size="sm">
                                    <FaReceipt />
                                </Button>
                            </td>
                        </tr>
                    )
                    )}
                </tbody>
            </Table>
        </div>
    );
};

export default ListCategorias;