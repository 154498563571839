import React, {useEffect, useState} from "react";
import Cookies from 'universal-cookie';
import Card from 'react-bootstrap/Card';
import api from "../services/api";

const cookies = new Cookies();


const Profile = () => {
    const [usuario, setUsuario] = useState({})

    //Efffect para obtener productos
    useEffect(() => {
        getUsuario()
    }, [])


    //Funcion para obtener los permisos del usuario
    const getUsuario = async () => {
        const res = await api.get('/user/' + cookies.get("idUsuario"))
        setUsuario(res.data.usuario)
    }

    return (
        <div className="Contenedor">
            <div class="row justify-content-md-center align-items-center">
                <Card style={{ width: '18rem' }} className="text-center">
                    <Card.Img variant="top" src={usuario.image}/>
                    <Card.Body>
                        <Card.Title>Perfil</Card.Title>
                        <Card.Subtitle className="mb-2 text-muted"><strong>Username:</strong> {usuario.name}</Card.Subtitle>
                        <Card.Text>
                            <strong>Email:</strong> {usuario.email}
                        </Card.Text>
                        <Card.Text>
                            <strong>Perfil:</strong> {cookies.get('perfil')}
                        </Card.Text>
                        <Card.Text>
                            <strong>Role:</strong> {usuario.role}
                        </Card.Text>
                        {cookies.get("perfil") === "Administrador" ? (
                            <a href='/adminUsers' class="btn btn-primary">Administrar Usuarios</a>
                        ): ""}

                    </Card.Body>
                </Card>
            </div>
        </div>
    );
};
export default Profile;