import React, { useState, useEffect } from 'react';
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import './styles.css'
import Button from 'react-bootstrap/Button';
import { useParams } from 'react-router-dom';
import DocuPDF from "../Components/Pages/DocuPDF";
import VistaWeb from "../Components/Pages/VistaWeb";
import { useNavigate} from 'react-router-dom';



function ShowPurchaseOrder() {
    const [verWeb, setVerWeb] = React.useState(true);
    const [verPDF, setVerPDF] = React.useState(false);

    const { id } = useParams();
    const navigate = useNavigate();

    const goBack = () => {
        navigate('/listPurchaseOrder', {replace: true});
    }


    /*
    if (purchaseOrder) {
        return (
            <div className='Contenedor'>
                No existe la orden de compra
            </div >
        )
    }
*/
    const Menu = () => (
        <nav
            style={{
                display: "flex",
                borderBottom: "1px solid black",
                paddingBottom: "5px",
                justifyContent: "space-around",
            }}
        >
            <Button
                variant="dark"
                onClick={goBack}
            >
                Regresar
            </Button>
            <Button
                variant="dark"
                onClick={() => {
                    setVerPDF(!verPDF);
                    setVerWeb(!verWeb);
                }}
            >
                {verPDF ? "Ocultar PDF" : "Ver PDF"}
            </Button>
            <PDFDownloadLink
                document={<DocuPDF
                    id={id}
                />}
                fileName={"orden-" + id + ".pdf"}
            >
                <Button variant="info">Descargar PDF</Button>
            </PDFDownloadLink>
        </nav>
    );

    return (
        <div className='Contenedor'>
            <Menu />
            <>
                {verWeb ? <VistaWeb
                    id={id}
                /> : null}
                {verPDF ? (
                    <PDFViewer style={{ width: "100%", height: "90vh" }}>
                        <DocuPDF
                            id={id}
                        />
                    </PDFViewer>
                ) : null}
            </>
        </div >
    )
}
export default ShowPurchaseOrder;

