import React, { useState, useEffect, Fragment } from 'react';
import api from "../../services/api";
import { Document, Page, Text, View, Image, StyleSheet } from "@react-pdf/renderer";

const borderColor = 'black'
const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 15,
        paddingLeft: 15,
        paddingRight: 15,
        lineHeight: 1.5,
        flexDirection: 'column',
    },
    logo: {
        width: 74,
        height: 66,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    titleContainer: {
        flexDirection: 'row',
        alignItems: "center",
        alignContent: "center",
        marginTop: 12,
    },
    reportTitle: {
        color: 'black',
        fontSize: 15,
        textAlign: 'right',
        textTransform: 'uppercase',
        fontWeight: "bold"
    },
    invoiceNoContainer: {
        flexDirection: 'row',
        marginTop: 36,
        justifyContent: 'flex-end'
    },
    invoiceDateContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    invoiceDate: {
        fontSize: 12,
        fontStyle: 'bold',
    },
    label: {
        width: 60
    },
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 24,
        borderWidth: 2,
        borderColor: 'black',
        borderStyle: "solid"
    },
    container: {
        flexDirection: 'row',
        borderBottomColor: borderColor,
        backgroundColor: 'red',
        color: "white",
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 2
    },
    item: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    qty: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    unity: {
        width: '50%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    description: {
        width: '40%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    amount: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    amountTotal: {
        width: '15%'
    },
    row: {
        flexDirection: 'row',
        borderBottomColor: borderColor,
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontStyle: 'bold',
    },
    itemRow: {
        width: '10%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
    },
    qtyRow: {
        width: '10%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
    },
    unityRow: {
        width: '50%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
    },
    amountRow: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    amountTotalRow: {
        width: '15%',
        textAlign: 'right',
        paddingRight: 8,
    },
    rowFinal: {
        flexDirection: 'row',
        borderBottomColor: borderColor,
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontSize: 12,
        fontStyle: 'bold',
    },
    descriptionFinal: {
        width: '85%',
        textAlign: 'right',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingRight: 8,
    },
    totalFinal: {
        width: '15%',
        textAlign: 'right',
        paddingRight: 8,
    }
});

const DocuPDF = ({ id }) => {
    const [purchaseOrder, setPurchaseOrder] = useState({});
    const [total, setTotal] = useState(0);
    const [subTotal, setSubTotal] = useState(0);
    const [igv, setIgv] = useState(0);
    //Efffect para obtener la orden de compra
    useEffect(() => {
        getPurchaseOrder();
    }, [])

    //Funcion para obtener las ordenes de compra
    const getPurchaseOrder = async () => {
        await api.get('/purchaseOrder/getPurshaseOrderById/' + id).then((res) => {
            setPurchaseOrder(res.data.purchaseOrder);
            calcularTotal(res.data.purchaseOrder.products);
        }).catch((err) => {
            console.log(err)
        });

    }
    const calcularTotal = (arrayProductos) => {
        let t = 0;
        let st = 0;
        let imp = 0;

        if (arrayProductos.length > 0) {

            arrayProductos.forEach((p) => {
                t = p.totalPrice + t
            })

            st = t / (1.15)
            imp = t - st
        }

        setSubTotal(st.toFixed(2))
        setIgv(imp.toFixed(2))
        setTotal(t.toFixed(2))
    }

    return (
        <Fragment>
            <Document>
                <Page size="A4" style={styles.page}>
                    <Image style={styles.logo}
                        src="https://picsum.photos/600/400" alt="Company logo"
                    />
                    <View style={styles.titleContainer}>
                        <Text style={styles.reportTitle}>Orden de Compra</Text>
                    </View>
                    <Fragment>
                        <View style={styles.invoiceNoContainer}>
                            <Text style={styles.label}>No:</Text>
                            <Text style={styles.invoiceDate}>541545</Text>
                        </View >
                    </Fragment> F
                    <View style={{ padding: "0" }}>
                        <Text style={{ fontSize: "12px", backgroundColor: "red", color: "white", fontWeight: "bold", marginTop: "10px" }}>
                            Condicones de adquisión:
                        </Text>
                        <View style={{ display: "flex", flexDirection: "row" }}>
                            <View style={{ flex: 1 }}>
                                <Text style={{ fontSize: "12px", backgroundColor: "red", color: "white", fontWeight: "bold" }}>
                                    Proveedor:
                                </Text>
                                <Text style={{ fontSize: "12px", backgroundColor: "red", color: "white", fontWeight: "bold" }}>
                                    Fecha de solicitud:
                                </Text>
                                <Text style={{ fontSize: "12px", backgroundColor: "red", color: "white", fontWeight: "bold" }}>
                                    Fecha de entrega:
                                </Text>
                                <Text style={{ fontSize: "12px", backgroundColor: "red", color: "white", fontWeight: "bold" }}>
                                    Condiciones de pago:
                                </Text>
                                <Text style={{ fontSize: "12px", backgroundColor: "red", color: "white", fontWeight: "bold" }}>
                                    Condiciones de entrega:
                                </Text>
                                <Text style={{ fontSize: "12px", backgroundColor: "red", color: "white", fontWeight: "bold" }}>
                                    Garantía(meses):
                                </Text>
                                <Text style={{ fontSize: "12px", backgroundColor: "red", color: "white", fontWeight: "bold" }}>
                                    Dirección de envío::
                                </Text>
                                <Text style={{ fontSize: "12px", backgroundColor: "red", color: "white", fontWeight: "bold" }}>
                                    Correo de envío:
                                </Text>
                            </View>
                            <View style={{ flex: 1 }}>
                                <Text style={{ fontSize: "12px", fontWeight: "bold" }}>
                                    {purchaseOrder?.supplier?.name}
                                </Text>
                                <Text style={{ fontSize: "12px", fontWeight: "bold" }}>
                                    {((new Date(purchaseOrder.applicationDate).getMonth() > 8) ? (new Date(purchaseOrder.applicationDate).getMonth() + 1) : ('0' + (new Date(purchaseOrder.applicationDate).getMonth() + 1))) + '/' + ((new Date(purchaseOrder.applicationDate).getDate() > 9) ? new Date(purchaseOrder.applicationDate).getDate() : ('0' + new Date(purchaseOrder.applicationDate).getDate())) + '/' + new Date(purchaseOrder.applicationDate).getFullYear()}
                                </Text>
                                <Text style={{ fontSize: "12px", fontWeight: "bold" }}>
                                    {((new Date(purchaseOrder.deliveryDate).getMonth() > 8) ? (new Date(purchaseOrder.deliveryDate).getMonth() + 1) : ('0' + (new Date(purchaseOrder.deliveryDate).getMonth() + 1))) + '/' + ((new Date(purchaseOrder.deliveryDate).getDate() > 9) ? new Date(purchaseOrder.deliveryDate).getDate() : ('0' + new Date(purchaseOrder.deliveryDate).getDate())) + '/' + new Date(purchaseOrder.applicationDate).getFullYear()}
                                </Text>
                                <Text style={{ fontSize: "12px", fontWeight: "bold" }}>
                                    {purchaseOrder?.paymentConditions}
                                </Text>
                                <Text style={{ fontSize: "12px", fontWeight: "bold" }}>
                                    {purchaseOrder?.placeDelivery}
                                </Text>
                                <Text style={{ fontSize: "12px", fontWeight: "bold" }}>
                                    {purchaseOrder?.warranty}
                                </Text>
                                <Text style={{ fontSize: "12px", fontWeight: "bold" }}>
                                    D {purchaseOrder?.shippingAddress}
                                </Text>
                                <Text style={{ fontSize: "12px", fontWeight: "bold" }}>
                                    {purchaseOrder?.shippingMail}
                                </Text>
                            </View>
                        </View>

                        <View style={styles.tableContainer}>
                            <View style={styles.container}>
                                <Text style={styles.item}>ITEM</Text>
                                <Text style={styles.qty}>CANTIDAD</Text>
                                <Text style={styles.unity}>PRODUCTO</Text>
                                <Text style={styles.amount}>PRECIO UNITARIO</Text>
                                <Text style={styles.amountTotal}>PRECIO TOTAL</Text>
                            </View>
                            {purchaseOrder?.products?.map((product, index) => (
                                <View style={styles.row} key={index}>
                                    <Text style={styles.itemRow}>{index + 1}</Text>
                                    <Text style={styles.qtyRow}>{product.amount}</Text>
                                    <Text style={styles.unityRow}>{product.name}</Text>
                                    <Text style={styles.amount}>{product.unitPrice}</Text>
                                    <Text style={styles.amountTotal}>{(product.amount * product.unitPrice).toFixed(2)}</Text>
                                </View>
                            ))}
                            <View style={styles.rowFinal}>
                                <Text style={styles.descriptionFinal}>SUBTOTAL</Text>
                                <Text style={styles.totalFinal}>{Number.parseFloat(subTotal).toFixed(2)}</Text>
                            </View>
                            <View style={styles.rowFinal}>
                                <Text style={styles.descriptionFinal}>IMPUESTOS (15%)</Text>
                                <Text style={styles.totalFinal}>{Number.parseFloat(igv).toFixed(2)}</Text>
                            </View>
                            <View style={styles.rowFinal}>
                                <Text style={styles.descriptionFinal}>TOTAL</Text>
                                <Text style={styles.totalFinal}>{Number.parseFloat(total).toFixed(2)}</Text>
                            </View>
                        </View>
                    </View>
                </Page>
            </Document>
        </Fragment>
    )
}


export default DocuPDF