import axios from 'axios'; //npm i axios
import Cookies from 'universal-cookie';
import Swal from 'sweetalert2'

const cookies = new Cookies();

const instance = axios.create({
    //baseURL: "http://135.181.205.61:8000/api/",
    baseURL: process.env.REACT_APP_REST
});

instance.interceptors.request.use(
    (config) => {
        if (cookies.get("token")) {
            config.headers["Authorization"] = 'Bearer ' + cookies.get("token");  // para baerer
            config.headers["id"] = cookies.get("idUsuario");
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    }, async function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        const originalConfig = error.config;
        if (originalConfig.url !== "/user/login") {
            // Access Token was expired
            if (error.response.status === 401) {
                originalConfig._retry = true;
                try {
                    const rs = await instance.post("/user/token", {
                        token: cookies.get("refreshToken"),
                    });
                    cookies.set("refreshToken", rs.data.refreshToken)
                    cookies.set("token", rs.data.token)
                    return instance(originalConfig);
                } catch (_error) {
                    cookies.remove('refreshToken', { path: "/" })
                    cookies.remove('token', { path: "/" })
                    cookies.remove('name', { path: "/" })
                    cookies.remove('email', { path: "/" })
                    cookies.remove('idPerfil', { path: "/" })
                    cookies.remove('perfil', { path: "/" })
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: '¡Estuviste inactivo por mucho tiempo!',
                        allowOutsideClick: false,
                        showConfirmButton: true,
                    }).then(() => {
                        window.location.href = "/auth"
                    })
                    return Promise.reject(_error);
                }
            }

        }
        return Promise.reject(error);
    }

);

export default instance;