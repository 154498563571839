import React, { useState, useEffect } from 'react';
import ProductosABC from "../Components/Pages/ProductosABC";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import Swal from 'sweetalert2' //npm install sweetalert2
import api from "../services/api";
import Cookies from 'universal-cookie';
import { Link } from "react-router-dom"
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LinearProgress from '@mui/material/LinearProgress';
import { PageErrorPermisos } from './PageErrorPermisos';

const cookies = new Cookies();


const ProductClassification = () => {
    //Valida el formulario
    const [validated, setValidated] = useState(true);

    const [tipoClasificacion, setTipoClasificacion] = useState("Costo Unitario")
    const [frecuenciaActualizacion, setFrecuenciaActualizacion] = useState("diariamente")
    const [valueA, setValueA] = useState(20)
    const [valueB, setValueB] = useState(20)
    const [valueC, setValueC] = useState(60)
    const [porcentajeA, setPorcentajeA] = useState(20)
    const [porcentajeB, setPorcentajeB] = useState(20)
    const [porcentajeC, setPorcentajeC] = useState(60)
    const [listaProductos, setListaProductos] = useState([])
    const [configuracionId, setConfiguracionId] = useState("")
    const [configuracionTipo, setConfiguracionTipo] = useState("Ninguna")
    const [configuracionFrecuencia, setConfiguracionFrecuencia] = useState("Ninguna")
    const [fechaActualizacion, setFechaActualizacion] = useState("Ninguna")
    const [fechaCreacion, setFechaCreacion] = useState("Ninguna")
    const [permisosClasificacionABC, setPermisosClasificacionABC] = useState(undefined)

    //setear los productos orendaos
    const ordenar = async (type, sort) => {
        const res = await api.get('/products/' + type + '/' + sort);
        setListaProductos(res.data);
    };

    //Efffect para obtener productos
    useEffect(() => {
        getPermisos();
    }, [])
    //Modal Agregar
    const [show, setShow] = useState(false);

    //Handle del modal add
    const handleClose = () => setShow(false);
    const handleShow = async () => {
        await api.get('/permisos/usuario/' + cookies.get("idPerfil"))
            .then(res => {
                const permisos = res.data.permisos[2].permisos
                if (res.data.permisos[2].permisos === 2) {
                    setShow(true)
                } else {
                    //Alerta de error por no tener permiso
                    Swal.fire({
                        icon: 'error',
                        title: "No tienes permiso",
                        showConfirmButton: false,
                        timer: 2000
                    })
                }
            })

    };


    //Funcion para obtener los permisos del usuario
    const getPermisos = async () => {
        const res = await api.get('/permisos/usuario/' + cookies.get("idPerfil"))
        setPermisosClasificacionABC(res.data.permisos[2].permisos);
        getProductos();
    }
    //Funcion para obtener los productos
    const getProductos = async () => {
        const res = await api.get('/configuracion/')
        if (res.data.length < 0) {
            //agrega utlidad a los productos si no lo tienes
            await api.put('/products/put')
            Swal.fire({
                icon: 'warning',
                title: "No hay una configuración para la clasificación de los productos",
                text: 'Debes agregar crear una configuración para comenzar',
                showConfirmButton: true,
            }).then(async () => {
                handleShow();
                const res1 = await api.get('/products/')
                setListaProductos(res1.data)
            }
            )
        } else {
            setConfiguracionFrecuencia(res.data[0].frequencyUpdateClassification)
            setConfiguracionTipo(res.data[0].type)
            setConfiguracionId(res.data[0]._id)
            setPorcentajeA(res.data[0].percentageA * 100)
            setPorcentajeB(res.data[0].percentageB * 100)
            setPorcentajeC(res.data[0].percentageC * 100)
            setValueA(res.data[0].percentageA * 100)
            setValueB(res.data[0].percentageB * 100)
            setValueC(res.data[0].percentageC * 100)
            setFechaActualizacion(res.data[0].updateDate)
            setFechaCreacion(res.data[0].creationDate)
            const res1 = await api.get('/products/clasificacion/mayor')
            setListaProductos(res1.data)
        }
    }
    const createConfiguracion = async () => {
        //Para crear la configuracion
        if (configuracionTipo === "Ninguna") {
            const a = valueA / 100
            const b = valueB / 100
            const c = valueC / 100
            const configuracion = {
                type: tipoClasificacion,
                porcentajeA: a,
                porcentajeB: b,
                porcentajeC: c,
                frecuenciaActualizacion: frecuenciaActualizacion,
                creator: cookies.get("idUsuario")
            }
            const res = await api.post('/configuracion/createConfiguration', configuracion)
            getProductos()
            const mensaje = res.data.message
            if (res.data.status) {
                //Refresacar la pagina
                //Alerta de agregado
                Swal.fire({
                    icon: 'success',
                    title: mensaje,
                    showConfirmButton: false,
                    timer: 2000
                }).then(async () => {
                    const clasifiacion = {
                        idCreator: cookies.get("idUsuario"),
                        reiniciar: true
                    }
                    Swal.fire({
                        position: 'center',
                        title: 'Espera un momento...',
                        showConfirmButton: false,
                        didOpen: () => {
                            Swal.showLoading()
                        },
                    })
                    const resp = await api.post('/clasificacion/createClasificacion', clasifiacion)
                    Swal.close()
                    if (resp.data.status) {
                        getProductos()
                        Swal.fire({
                            icon: 'success',
                            title: resp.data.message,
                            showConfirmButton: false,
                            timer: 2000
                        })
                        //Para cerrar el modal
                        setShow(false)
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: resp.data.message,
                            showConfirmButton: false,
                            timer: 2000
                        })
                    }

                }).catch((err) => {
                    console.log(err)
                });
            } else {
                //logs de error al backend
                const mensajeError = {
                    message: "Error al crear la configuracion de la clasificacion de los productos",
                    level: 3
                }
                await api.post('/logger', mensajeError)
                ///Mensaje de error
                Swal.fire({
                    icon: 'error',
                    title: mensaje,
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        } else {
            const a = valueA / 100
            const b = valueB / 100
            const c = valueC / 100
            const configuracion = {
                type: tipoClasificacion,
                porcentajeA: a,
                porcentajeB: b,
                porcentajeC: c,
                frecuenciaActualizacion: frecuenciaActualizacion,
                creator: cookies.get("idUsuario")
            }
            const res = await api.put('/configuracion/updateConfiguration/' + configuracionId, configuracion)
            getProductos();
            if (res.data.status) {
                //Refresacar la pagina
                //Alerta de agregado
                Swal.fire({
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 2000
                }).then(async () => {
                    generarClasificacion();
                }).catch((err) => {
                    console.log(err)
                });
                //Para cerrar el modal
                setShow(false);
            } else {
                //logs de error al backend
                const mensajeError = {
                    message: "Error al crear la configuracion de la clasificacion de los productos",
                    level: 3
                }
                await api.post('/logger', mensajeError)
                ///Mensaje de error
                Swal.fire({
                    icon: 'error',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        }
    }
    const generarClasificacion = async () => {
        if (permisosClasificacionABC === 2) {
            Swal.fire({
                title: '¿Desear reiniciar por completo la clasificación?',
                text: "Si hay productos con categorias que no deseas cambiar, presiona 'No'.",
                showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: 'Si',
                denyButtonText: 'No',
                cancelButtonText: "Cancelar",
                customClass: {
                    actions: 'my-actions',
                    cancelButton: 'order-1 right-gap',
                    confirmButton: 'order-2',
                    denyButton: 'order-3',
                }
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const clasifiacion = {
                        idCreator: cookies.get("idUsuario"),
                        reiniciar: true
                    }
                    Swal.fire({
                        position: 'center',
                        title: 'Espera un momento...',
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        didOpen: () => {
                            Swal.showLoading()
                        },
                    })
                    const resp = await api.post('/clasificacion/createClasificacion', clasifiacion)
                    if (resp.data.status) {
                        Swal.close();
                        getProductos();
                        Swal.fire({
                            icon: 'success',
                            title: resp.data.message,
                            showConfirmButton: false,
                            timer: 2000
                        })
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: resp.data.message,
                            showConfirmButton: false,
                            timer: 2000
                        })
                    }
                } else if (result.isDenied) {
                    const clasifiacion = {
                        idCreator: cookies.get("idUsuario"),
                        reiniciar: false
                    }
                    Swal.fire({
                        position: 'center',
                        title: 'Espera un momento...',
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        didOpen: () => {
                            Swal.showLoading()
                        },
                    })
                    const resp = await api.post('/clasificacion/createClasificacion', clasifiacion)
                    if (resp.data.status) {
                        getProductos();
                        Swal.fire({
                            icon: 'success',
                            title: resp.data.message,
                            showConfirmButton: false,
                            timer: 2000
                        })
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: resp.data.message,
                            showConfirmButton: false,
                            timer: 2000
                        })
                    }
                    Swal.close();
                }
            })

        } else {
            //Alerta de error por no tener permiso
            Swal.fire({
                icon: 'error',
                title: "No tienes permiso",
                showConfirmButton: false,
                timer: 2000
            })
        }
    }
    if (permisosClasificacionABC === undefined) {
        return (
            <LinearProgress />
        )
    }
    if (permisosClasificacionABC < 1) {
        return (
            <PageErrorPermisos />
        )
    }
    return (
        <div className="Contenedor">
            <div className='encabezado'>
                <h3>Clasificación de productos</h3>
                <div className='botones'>
                    <Button variant="primary"
                        onClick={handleShow}
                    >
                        Configuración de clasificación
                    </Button>
                    {" "}
                    <Button variant="primary"
                        onClick={generarClasificacion}
                    >
                        Refrescar
                    </Button>
                </div>
            </div>
            <Container fluid="md">
                <Row>
                    <Col>
                        <ProductosABC lista={listaProductos} permisosClasificacionABC={permisosClasificacionABC} sortProductos={ordenar} fetchProducts={getProductos} />
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                <div className="tituloProctucoClassificacion">
                                    <h5>Tipo de clasficación :</h5>
                                    <p>{configuracionTipo}</p>
                                </div>
                            </Col>
                            <Col>
                                <div className="tituloProctucoClassificacion">
                                    <h5>Frecuencia:</h5>
                                    <p>{configuracionFrecuencia}</p>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="tituloProctucoClassificacion">
                                    <h5>Cantidad de productos:</h5>
                                    <div className="row">
                                        <div className="col-sm-4">
                                            Porcentaje A:
                                            <p style={{ color: "var(--urgente)" }} >
                                                {Math.round(porcentajeA, 2)}
                                            </p>
                                        </div>
                                        <div className="col-sm-4">
                                            Porcentaje B:
                                            <p style={{ color: "var(--importante)" }} >
                                                {Math.round(porcentajeB, 2)}
                                            </p>
                                        </div>
                                        <div className="col-sm-4">
                                            Porcentaje C:
                                            <p style={{ color: "var(--proximo)" }} >
                                                {Math.round(porcentajeC, 2)}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="tituloProctucoClassificacion">
                                    <h5>Fecha de creación:</h5>
                                    <p>{fechaCreacion}</p>
                                </div>
                            </Col>
                            <Col>
                                <div className="tituloProctucoClassificacion">
                                    <h5>Fecha de actualización:</h5>
                                    <p>{fechaActualizacion}</p>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <Modal show={show} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Clasificación de productos</Modal.Title>
                </Modal.Header>
                <Formik
                    validate={() => {
                        setValidated(false)
                        let errores = {};
                        // Validacion nombre
                        let suma = valueA + valueB + valueC
                        if ((valueA + valueB + valueC) !== 100) {
                            errores.valor = '*Error:  La suma de los valores no es 100%. El valor total es ' + suma + '%'
                        }
                        if (suma === 100) {
                            setValidated(true)
                        }
                        return errores;
                    }}
                >
                    {({
                        handleBlur,
                        touched,
                        errors,
                    }) => (
                        <Form >
                            <Modal.Body>
                                <Row className="mb-3">
                                    <Form.Group className="position-relative mb-3">
                                        <Form.Label>Escoja el tipo de clasificación:</Form.Label>
                                        <Form.Select
                                            as="select"
                                            id="tipoClasificacion"
                                            name="tipoClasificacion"
                                            value={tipoClasificacion}
                                            onChange={(e) => setTipoClasificacion(e.target.value)}
                                        >
                                            <option value="Costo Unitario">Clasificación ABC por coste unitario</option>
                                            <option value="Valor total en Inventario">Clasificación ABC por valor total en inventario</option>
                                            <option value="Utilidad">Clasificación ABC por utilización y valor</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group className="position-relative mb-3">
                                        <Form.Label>Porcentaje de Clasificación ABC</Form.Label>
                                        <br />
                                        <Form.Text>A = {valueA}%</Form.Text>
                                        <Form.Range
                                            value={valueA}
                                            min="5"
                                            max="20"
                                            onChange={(e) => { setValueA(Number(e.target.value)) }}
                                            onBlur={handleBlur}
                                            step="5"
                                            isValid={touched.valor && !errors.valor}
                                            isInvalid={!!errors.valor}
                                        />
                                        <Form.Text>B = {valueB}%</Form.Text>
                                        <Form.Range
                                            value={valueB}
                                            onChange={(e) => setValueB(Number(e.target.value))}
                                            min="5"
                                            max="30"
                                            step="5"
                                            onBlur={handleBlur}
                                            isValid={touched.valor && !errors.valor}
                                            isInvalid={!!errors.valor}
                                        />
                                        <Form.Text>c = {valueC}%</Form.Text>
                                        <Form.Range
                                            value={valueC}
                                            onChange={(e) => setValueC(Number(e.target.value))}
                                            onBlur={handleBlur}
                                            min="50"
                                            max="90"
                                            step="5"
                                            isValid={touched.valor && !errors.valor}
                                            isInvalid={!!errors.valor}
                                        />
                                        <p style={{ color: "red" }} >
                                            {errors.valor}
                                        </p>
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group md="4">
                                        <Form.Label>Frecuencia de clasificacion:</Form.Label>
                                        <Form.Select
                                            as="select"
                                            id="tipoClasificacion"
                                            name="tipoClasificacion"
                                            value={frecuenciaActualizacion}
                                            onChange={(e) => setFrecuenciaActualizacion(e.target.value)}
                                        >
                                            <option value="diariamente">Diariamente</option>
                                            <option value="semanalmente">Semanalmente</option>
                                            <option value="mensualmente">Mensualmente</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary"
                                    onClick={handleClose}
                                >
                                    Cancelar
                                </Button>
                                <Button variant="primary"
                                    onClick={createConfiguracion} disabled={!validated}
                                >
                                    Guardar
                                </Button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </div>
    )
}

export default ProductClassification
