import { Card, CardBody, CardHeader, Col, FormGroup, Input, InputGroup, InputGroupText, Label, Row, Table, Button } from "reactstrap";
import Swal from 'sweetalert2'
import Autosuggest from 'react-autosuggest';
import { React, useContext, useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MdRemoveRedEye, MdDelete } from 'react-icons/md';
import api from "../services/api";
import './styles.css';
//import { UserContext } from "../context/UserProvider";
import Cookies from 'universal-cookie';
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';

const cookies = new Cookies();


function PurchaseOrder() {
    const [fechaInicio, setFechaInicio] = useState(new Date());
    const [fechaFin, setFechaFin] = useState(new Date());
    const [productos, setProductos] = useState([]);
    const [total, setTotal] = useState(0);
    const [subTotal, setSubTotal] = useState(0);
    const [igv, setIgv] = useState(0);
    const [a_Productos, setA_Productos] = useState([]);
    const [a_Busqueda, setA_Busqueda] = useState("");
    const [listSuppliers, setListSuppliers] = useState([]);
    const [supplier, setSupplier] = useState(null);
    const [paymentConditions, setPaymentConditions] = useState("Ninguna");
    const [placeDelivery, setPlaceDelivery] = useState("Ninguna");
    const [warranty, setWarranty] = useState("1");
    const [shippingAddress, setShippingAddress] = useState("");
    const [shippingMail, setShippingMail] = useState("");
    const [notes, setNotes] = useState("");
    const [purchaseOrders, setPurchaseOrders] = useState([]);

    const [validated, setValidated] = useState(false);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [stock, setStock] = useState(1);
    const [price, setPrice] = useState("0");
    const [sku, setSku] = useState("");

    //Modal Agregar
    const [show, setShow] = useState(false);
    //Handle del modal add
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    };


    //Funcion para obtener los proveedores
    const getSuppliers = async () => {
        const res = await api.get('/suppliers/')
        setListSuppliers(res.data.suppliers);
        getPurchaseOrders();
    }
    //Efffect para obtener productos
    useEffect(() => {
        getSuppliers();
    }, [])
    //Funcion para obtener las ordenes de compra
    const getPurchaseOrders = async () => {
        const res = await api.get('/purchaseOrder/')
        setPurchaseOrders(res.data.purchaseOrder);
    }
    //para obtener la lista de sugerencias
    const onSuggestionsFetchRequested = async ({ value }) => {
        await api.get('/products/').then((response) => {
            var resultadosBusqueda = response.data.filter((item) => {
                if (item.name?.toString().toLowerCase().includes(value.toLowerCase())
                    || item.sku?.toString().toLowerCase().includes(value.toLowerCase())
                ) {
                    return item;
                }
            });
            setA_Productos(resultadosBusqueda);
        }).catch((error) => {
            console.log("No se pudo obtener datos, mayor detalle: ", error)
        })
    }
    //funcion que nos permite borrar las sugerencias
    const onSuggestionsClearRequested = () => {
        setA_Productos([])
    }
    //devuelve el texto que se mostrara en la caja de texto del autocomplete cuando seleccionas una sugerencia (item)
    const getSuggestionValue = (sugerencia) => {

        return sugerencia.sku + " - " + sugerencia.name + " - $" + sugerencia.price
    }
    //como se debe mostrar las sugerencias - codigo htmlf
    const renderSuggestion = (sugerencia) => (
        <span>
            {sugerencia.sku + " - " + sugerencia.name + " - $" + sugerencia.price}
        </span>
    )
    const sugerenciaSeleccionada = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {

        Swal.fire({
            title: suggestion.name + " -  $" + suggestion.price,
            text: "Ingrese la cantidad",
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Volver',
            showLoaderOnConfirm: true,
            preConfirm: (inputValue) => {


                if (isNaN(parseFloat(inputValue))) {
                    setA_Busqueda("")
                    Swal.showValidationMessage(
                        "Debe ingresar un valor númerico"
                    )
                } else {

                    let producto = {
                        //   _id: suggestion._id,
                        name: suggestion.name,
                        amount: parseInt(inputValue),
                        description: "",
                        unitPrice: parseFloat(suggestion.price),
                        totalPrice: parseFloat(suggestion.price) * parseFloat(inputValue),
                        toDilevery: parseInt(inputValue)
                    }
                    let arrayProductos = []
                    arrayProductos.push(...productos)
                    arrayProductos.push(producto)

                    setProductos((anterior) => [...anterior, producto])
                    calcularTotal(arrayProductos)
                }


            },
            allowOutsideClick: () => !Swal.isLoading()

        }).then((result) => {
            if (result.isConfirmed) {
                setA_Busqueda("")
            } else {
                setA_Busqueda("")
            }
        })
    }
    const eliminarProducto = (name) => {

        let listaproductos = productos.filter(p => p.name !== name)

        setProductos(listaproductos)

        calcularTotal(listaproductos)
    }


    const calcularTotal = (arrayProductos) => {
        let t = 0;
        let st = 0;
        let imp = 0;

        if (arrayProductos.length > 0) {

            arrayProductos.forEach((p) => {
                t = p.totalPrice + t
            })

            st = t / (1.15)
            imp = t - st
        }

        //Monto Base = (Monto con IGV) / (1.18)

        //IGV = (Monto con IGV) – (Monto Base)

        setSubTotal(st.toFixed(2))
        setIgv(imp.toFixed(2))
        setTotal(t.toFixed(2))
    }
    //evento cuando cambie el valor del texto de busqueda
    const onChange = (e, { newValue }) => {
        setA_Busqueda(newValue)
    }
    const inputProps = {
        placeholder: "Buscar producto",
        value: a_Busqueda,
        onChange
    }
    //Para agregar un nuevo proveedor 
    const realizarPedido = async () => {
        console.log(productos)
        //console.log(productos)
        const nuevoPedido = {
            supplier: supplier,
            applicationDate: fechaInicio,
            deliveryDate: fechaFin,
            paymentConditions: paymentConditions,
            deliveryConditions: placeDelivery,
            warranty: warranty,
            shippingAddress: shippingAddress,
            deliveryMail: shippingMail,
            creator: cookies.get("idUsuario"),
            notes: notes,
            products: productos,
            totalToPay: total
        }
        // console.log(nuevoPedido)
        //Para raealizar el pedido
        await api.post('/purchaseOrder/create', nuevoPedido).then(async (res) => {
            if (res.data.status) {
                //Refresacar la pagina
                getSuppliers();
                //Alerta de agregado
                Swal.fire({
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 2000
                })
                window.location.replace('/listPurchaseOrder');
            } else {
                //logs de error al backend
                const mensaje = {
                    message: "Error al realizar el pedido",
                    level: 3
                }
                await api.post('/logger', mensaje)
                ///Mensaje de error
                Swal.fire({
                    icon: 'error',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        }).catch(async (err) => {
            //logs de error al backend
            const mensaje = {
                message: err.response.data.message,
                level: 3
            }
            await api.post('/logger', mensaje);
            ///Mensaje de error
            Swal.fire({
                icon: 'error',
                title: err.response.data.message,
                showConfirmButton: false,
                timer: 2000
            })
        });
    }
    const addProducto = async () => {
        //Funcion para agregar los productos 
        let formData = new FormData()
        formData.append('name', name)
        formData.append('sku', sku)
        formData.append('price', price)
        formData.append('stock', "0")
        formData.append('coleccion', "Bodega")
        formData.append('creator', cookies.get("idUsuario"))
        let producto = {
            // _id: resultadosBusqueda._id,
            name: name,
            amount: stock,
            description: description,
            unitPrice: price,
            totalPrice: price * stock,
            toDilevery: stock
        }
        let arrayProductos = []
        arrayProductos.push(...productos)
        arrayProductos.push(producto)
        setProductos((anterior) => [...anterior, producto])
        calcularTotal(arrayProductos)

        setName("");
        setSku("");
        setStock("");
        setDescription("");
        setPrice("");
        Swal.fire({
            icon: 'success',
            title: "Agregado",
            showConfirmButton: false,
            timer: 2000
        })
        handleClose();
        /*
        await api.post('/products/createProduct', formData).then(async (res) => {
            if (res.data.status) {
                
                //Para cerrar el modal
                setShow(false)
            } else {
                ///Mensaje de error
                Swal.fire({
                    icon: 'error',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        }).catch(async (err) => {
            //logs de error al backend
            const mensaje = {
                message: err.response.data.message,
                level: 3
            }
            await api.post('/logger', mensaje);
            ///Mensaje de error
            Swal.fire({
                icon: 'error',
                title: err.response.data.message,
                showConfirmButton: false,
                timer: 2000
            })
        });
*/

    }
    return (
        <div className="Contenedor">
            <Row>
                <Col sm={8}>
                    <Row className="mb-2">
                        <Col sm={12}>
                            <Card>
                                <CardHeader style={{ backgroundColor: 'black', color: "white" }}>
                                    Proveedor
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col sm={6}>
                                            <FormGroup>
                                                <InputGroup size="sm" >
                                                    <InputGroupText>Proveedor:</InputGroupText>
                                                    <Input type="select"
                                                        // value={supplier} 
                                                        onChange={(e) => setSupplier(e.target.value)}
                                                    >
                                                        <option >Seleccione un proveedor</option>
                                                        {listSuppliers.map(item => (
                                                            <option key={item._id} value={item._id}>{item.name}</option>
                                                        ))}
                                                    </Input>
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                        <Col sm={3}>
                                            <FormGroup>
                                                <Label>Fecha de solicitud:</Label>
                                                <DatePicker
                                                    className="form-control form-control-sm"
                                                    selected={fechaInicio}
                                                    onChange={(date) => setFechaInicio(date)}
                                                    dateFormat='dd/MM/yyyy'
                                                    minDate={fechaInicio}
                                                    showDisabledMonthNavigation
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={3}>
                                            <FormGroup>
                                                <Label>Fecha de entrega:</Label>
                                                <DatePicker
                                                    className="form-control form-control-sm"
                                                    selected={fechaFin}
                                                    onChange={(date) => setFechaFin(date)}
                                                    dateFormat='dd/MM/yyyy'
                                                    minDate={fechaFin}
                                                    showDisabledMonthNavigation
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={6}>
                                            <FormGroup>
                                                <Label>Condiciones de pago</Label>
                                                <Input bsSize="sm"
                                                    value={paymentConditions} onChange={(e) => setPaymentConditions(e.target.value)}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={6}>
                                            <FormGroup>
                                                <Label> Condiciones de Entrega:</Label>
                                                <Input bsSize="sm"
                                                    value={placeDelivery} onChange={(e) => setPlaceDelivery(e.target.value)}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={6}>
                                            <FormGroup>
                                                <Label>Garantía (meses):</Label>
                                                <Input bsSize="sm"
                                                    type="number" value={warranty} onChange={(e) => setWarranty(e.target.value)}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={6}>
                                            <FormGroup>
                                                <Label>Dirección de Envío:</Label>
                                                <Input bsSize="sm"
                                                    value={shippingAddress} onChange={(e) => setShippingAddress(e.target.value)}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={6}>
                                            <FormGroup>
                                                <Label>Correo de envío:</Label>
                                                <Input bsSize="sm"
                                                    value={shippingMail} onChange={(e) => setShippingMail(e.target.value)}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={6}>
                                            <FormGroup>
                                                <Label>Notas:</Label>
                                                <Input bsSize="sm"
                                                    value={notes} onChange={(e) => setNotes(e.target.value)}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <Card>
                                <CardHeader style={{ backgroundColor: 'black', color: "white" }}>
                                    Productos
                                </CardHeader>
                                <CardBody>
                                    <Row className="mb-2">
                                        <Col sm={12}>
                                            <FormGroup>
                                                <Autosuggest
                                                    suggestions={a_Productos}
                                                    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                                                    onSuggestionsClearRequested={onSuggestionsClearRequested}
                                                    getSuggestionValue={getSuggestionValue}
                                                    renderSuggestion={renderSuggestion}
                                                    inputProps={inputProps}
                                                    onSuggestionSelected={sugerenciaSeleccionada}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <Button color="primary" size="sm" onClick={handleShow}>Nuevo Producto</Button>
                                            <Table striped size="sm">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>Producto</th>
                                                        <th>Descripción</th>
                                                        <th>Cantidad</th>
                                                        <th>Costo unitario</th>
                                                        <th>Costo Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        (productos.length < 1) ? (
                                                            <tr>
                                                                <td colSpan="6">Sin productos</td>
                                                            </tr>
                                                        ) :
                                                            (
                                                                productos.map((item) => (
                                                                    <tr key={item._id}>
                                                                        <td>
                                                                            <Button color="danger" size="sm"
                                                                                onClick={() => eliminarProducto(item.name)}
                                                                            >
                                                                                <MdDelete />
                                                                            </Button>
                                                                        </td>
                                                                        <td>{item.name}</td>
                                                                        <td>{item.description}</td>
                                                                        <td>{item.amount}</td>
                                                                        <td>{item.unitPrice}</td>
                                                                        <td>{item.totalPrice.toFixed(2)}</td>
                                                                    </tr>
                                                                ))
                                                            )
                                                    }
                                                </tbody>
                                            </Table>
                                        </Col>

                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Col>

                <Col sm={4}>
                    <Row className="mb-2">
                        <Col sm={12}>
                            <Card>
                                <CardHeader style={{ backgroundColor: 'black', color: "white" }}>
                                    Detalle
                                </CardHeader>
                                <CardBody>
                                    <Row className="mb-2">
                                        <Col sm={12}>
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col sm={12}>
                                            <div className="input-group mb-3">
                                                <div className="input-group-prepend">
                                                    <InputGroupText> Sub Total:</InputGroupText>
                                                </div>
                                                <Input disabled
                                                    value={subTotal}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col sm={12}>
                                            <div className="input-group mb-0">
                                                <div className="input-group-append">
                                                    <InputGroupText> IVA (15%):</InputGroupText>
                                                </div>
                                                <Input disabled
                                                    value={igv}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <div className="input-group mb-0">
                                                <div className="input-group-append">
                                                    <InputGroupText>Total:</InputGroupText>                                                </div>
                                                <Input disabled
                                                    value={total}
                                                />
                                            </div>
                                        </Col>
                                    </Row>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <Button color="primary" block
                                        onClick={realizarPedido}
                                    >
                                        <i className="fas fa-money-check"></i> Realizar Pedido</Button>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Col>
            </Row>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Añadir Nuevo Producto</Modal.Title>
                </Modal.Header>
                <Formik
                    validate={() => {
                        setValidated(false)
                        let errores = {};
                        // Validacion nombre
                        if (!name) {
                            errores.name = '*Por favor ingresa una nombre. Campo obligatorio*'
                        } else if (!/^[\wÀ-ÿ\s,"-.:-;+*~´°!"#$%&/()=?|¬¨^`<>]{1,100}$/.test(name)) {
                            errores.name = 'El nombre no es correcto, no puede sobre pasar los 100 caracteres'
                        }
                        if (!/^[\wÀ-ÿ\s,"-.:-;+*~´°!"#$%&/()=?|¬¨^`<>]*$/.test(sku)) {
                            errores.description = 'La sku es incorrecto'
                        }
                        if (!errores.name || !errores.sku) {
                            setValidated(true)
                        }
                        return errores;
                    }}
                >
                    {({
                        handleBlur,
                        touched,
                        errors,
                    }) => (
                        <Form >
                            <Modal.Body>
                                <h6>*Los nombre es obligatorio*</h6>
                                <Row className="mb-3">
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="8" >
                                        <Form.Label>Nombre</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="name"
                                            name="name"
                                            placeholder="Nombre"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            onBlur={handleBlur}
                                            isValid={touched.name && !errors.name}
                                            isInvalid={!!errors.name}
                                        />
                                        <Form.Control.Feedback type="invalid" >
                                            {errors.name}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" >
                                        <Form.Label>Cantidad</Form.Label>
                                        <Form.Control
                                            type="number"
                                            id="name"
                                            name="0"
                                            value={stock}
                                            onChange={(e) => setStock(e.target.value)}
                                            onBlur={handleBlur}
                                            isValid={touched.stock && !errors.stock}
                                            isInvalid={!!errors.stock}
                                        />
                                        <Form.Control.Feedback type="invalid" >
                                            {errors.stock}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="6" >
                                        <Form.Label>SKU</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="name"
                                            name="name"
                                            placeholder="sku"
                                            value={sku}
                                            onChange={(e) => setSku(e.target.value)}
                                            onBlur={handleBlur}
                                            isValid={touched.sku && !errors.sku}
                                            isInvalid={!!errors.sku}
                                        />
                                        <Form.Control.Feedback type="invalid" >
                                            {errors.name}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="6" >
                                        <Form.Label>Costo</Form.Label>
                                        <Form.Control
                                            type="number"
                                            id="name"
                                            name="0.0"
                                            placeholder="0.0"
                                            value={price}
                                            onChange={(e) => setPrice(e.target.value)}
                                            onBlur={handleBlur}
                                            isValid={touched.price && !errors.price}
                                            isInvalid={!!errors.price}
                                        />
                                        <Form.Control.Feedback type="invalid" >
                                            {errors.price}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>

                                <Row className="mb-3">
                                    <Form.Group as={Col} md="12">
                                        <Form.Label>Descripción</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            id="description"
                                            name="description"
                                            placeholder="Escribir una descripción de la colección"
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                            onBlur={handleBlur}
                                            isValid={touched.description && !errors.description}
                                            isInvalid={!!errors.description}
                                        />
                                        <Form.Control.Feedback type="invalid" >
                                            {errors.description}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Cancelar
                                </Button>
                                <Button color="primary"
                                    onClick={addProducto}
                                    disabled={!validated}
                                >
                                    Guardar
                                </Button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </div>
    )
}
export default PurchaseOrder;
