import React, { useState } from 'react';
import Table from 'react-bootstrap/esm/Table';
import { FaBoxes } from 'react-icons/fa';
import Pagination from 'react-bootstrap/Pagination';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Swal from 'sweetalert2' //npm install sweetalert2
import { TbSortAscendingLetters, TbSortDescendingLetters } from 'react-icons/tb';

import api from "../../services/api";

import './pages.css';

const ProductosABC = ({ lista, permisosClasificacionABC, sortProductos, fetchProducts }) => {
    //Sort
    const [seleccion, setSeleccion] = useState("");
    const [ascendente, setAscendente] = useState(true);
    //Valores iniciales
    const [currentPage, setCurrentPage] = useState(1);
    const [PerPage, setPerPage] = useState(5);
    //state productos
    const [idProducto, setIdProducto] = useState("");
    const [clasificacion, setClasificacion] = useState("");
    const [showChangeClasification, setShowChangeClasification] = useState("");

    // Obtiene los indices a partir de la lista
    const totalPages = Math.ceil(lista.length / PerPage);
    const indexOfLast = currentPage * PerPage;
    const indexOfFirst = indexOfLast - PerPage;
    const currentListProducts = lista.slice(indexOfFirst, indexOfLast);

    // Cambiar pagina
    const paginate = pageNumber => setCurrentPage(pageNumber);


    const createPaginationItem = (i) => {
        return <Pagination.Item
            key={i}
            active={i === currentPage}
            onClick={() => paginate(i)}
        >
            {i}
        </Pagination.Item>
    };
    const createListPagination = () => {
        const actualpage = currentPage
        const leftBound = actualpage > 3 ? actualpage - 1 : 2;
        const pageBound = actualpage !== totalPages ? actualpage === 1 ? 3 : actualpage === 2 ? 3 : actualpage : 0;
        const rightBound = pageBound === 0 ? 0 : pageBound + 1 !== totalPages ? pageBound + 1 : 0;
        const leftPage = leftBound + 2 === totalPages ? leftBound - 1 : actualpage === totalPages ? actualpage - 3 : leftBound < totalPages ? leftBound : 0;
        const page = leftBound + 2 === totalPages ? leftBound : actualpage === totalPages ? actualpage - 2 : pageBound < totalPages ? pageBound : 0;
        const rightPage = leftBound + 2 === totalPages ? leftBound + 1 : actualpage === totalPages ? actualpage - 1 : rightBound < totalPages ? rightBound : 0;
        return <>
            {leftPage > 2 && (<Pagination.Ellipsis />)}
            {leftPage > 1 && (<>{createPaginationItem(leftPage)}</>)}
            {page > 1 && (<>{createPaginationItem(page)}</>)}
            {rightPage > 1 && (<>{createPaginationItem(rightPage)}</>)}
            {rightPage < totalPages - 3 && (<Pagination.Ellipsis />)}
        </>
    };

    //Funcion para actualizar el product
    const updateProduct = async (e) => {
        e.preventDefault();
        
        const clasificacionNueva = {
            clasificacion: clasificacion
        }
        const res = await api.put('/products/updateProductClasification/' + idProducto, clasificacionNueva)
        const msg = res.data.message
        if (res.data.status) {
            //Alerta de actualizado
            Swal.fire({
                icon: 'success',
                title: msg,
                showConfirmButton: false,
                timer: 2000
            })
            fetchProducts();
        } else {
            //logs de error
            const mensaje = {
                message: "Error al actualizar un producto",
                level: 3
            }
            await api.post('/logger', mensaje)
            //Alerta de actualizado
            Swal.fire({
                icon: 'error',
                title: msg,
                showConfirmButton: false,
                timer: 2000
            })
        }
        setShowChangeClasification("");
        setClasificacion("");
    }
    const ordenar = type => {
        if (type === seleccion) {
            setAscendente(!ascendente);
        } else {
            setSeleccion(type);
            setAscendente(true);
        }
        const sort = ascendente ? "mayor" : "menor";
        sortProductos(type, sort);
    }
    return (
        <div className="listaProductos">
            <h5>Productos</h5>
            {clasificacion && (
                <div className='d-flex justify-content-between'>
                <Button variant="secondary" onClick={() => {setShowChangeClasification(""); setClasificacion("")}}>Cancelar</Button>
                <Button variant="primary"
                    onClick={updateProduct}
                >
                    Guardar
                </Button>
            </div>
            )}
            <Table responsive="sm">
                <thead>
                    <tr>
                        <th></th>
                        <th><a href="#nombre" onClick={() => ordenar("name")}>Nombre {seleccion === "name" && (ascendente ? <TbSortAscendingLetters /> : <TbSortDescendingLetters />)}</a></th>
                        <th><a href="#clasificacion" onClick={() => ordenar("clasificacion")}>Clasificación {seleccion === "clasificacion" && (ascendente ? <TbSortAscendingLetters /> : <TbSortDescendingLetters />)}</a></th>
                    </tr>
                </thead>
                <tbody>
                    {currentListProducts.map(item => (
                        <tr key={item._id}>
                            <td ><FaBoxes /></td>
                            <td>{item.name}</td>
                            <td >
                                <a href={"#"+item.name} onClick={() => setShowChangeClasification(item.name)}>
                                    {showChangeClasification === item.name ?
                                        (<Form.Select
                                            onChange={(e) => { setIdProducto(item._id); setClasificacion(e.target.value)}}
                                            defaultValue={item.clasification.type}>
                                            <option value="A">A</option>
                                            <option value="B">B</option>
                                            <option value="C">C</option>
                                        </Form.Select>)
                                        : (<p className={item.clasification.type === "A" ? "color-urgente" : item.clasification.type === "B" ? "color-importante" : "color-proximo"}>Clasficación {item.clasification.type}</p>)}
                                </a>
                            </td>
                        </tr>
                    )
                    )}
                </tbody>
            </Table>
            <div className='row'>
                <div className="col-sm-9">
                    <Pagination className="paginationInfo">
                        <Pagination.Prev
                            onClick={() => paginate(currentPage - 1)}
                            disabled={currentPage === 1}
                        />
                        {createPaginationItem(1)}
                        {totalPages > 1 && (<>
                            {createListPagination()}
                            {createPaginationItem(totalPages)}
                        </>)}
                        <Pagination.Next
                            onClick={() => paginate(currentPage + 1)}
                            disabled={currentPage === totalPages}
                        />
                    </Pagination>
                </div>
                <div className="col-sm-1">
                    <select
                        defaultValue="5"
                        onChange={(event) => { setPerPage(event.target.value); paginate(1) }}
                        value={PerPage}
                    >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                </div>
                <div className="col-sm-2">
                    Total de productos: {lista.length}
                </div>
            </div>
        </div>
    );
};

export default ProductosABC;