import React, { useEffect, useState } from "react";
import './Navbar.css';
import { FaBars, FaSearch, FaBell, FaUser } from 'react-icons/fa';
import { AiFillSetting } from 'react-icons/ai';
import { RiAccountBoxFill } from 'react-icons/ri';
import { BiHelpCircle } from 'react-icons/bi';
import { MdModeEditOutline, MdOutlineLogout } from 'react-icons/md'
import Cookies from 'universal-cookie';
import api from "../../services/api";

const cookies = new Cookies();

function Navbar() {

    const [show, setShow] = useState(true);

    useEffect(() => {
        if (cookies.get('token')) {
            setShow(false)
        }
    }, [])

    const salir = async () => {
        cookies.remove('refreshToken', { path: "/" })
        cookies.remove('token', { path: "/" })
        cookies.remove('name', { path: "/" })
        cookies.remove('email', { path: "/" })
        cookies.remove('idPerfil', { path: "/" })
        cookies.remove('perfil', { path: "/" })
        cookies.remove('perfil', { path: "/" })
        cookies.remove('token', { path: "/" })
        window.location.href = "/auth"
        const refreshToken = cookies.get("refreshToken")
        await api.post("/user/logout", {
            token: refreshToken,
        });
    }


    //Para expandir el boton responsive
    const [isNavExpanded, setIsNavExpanded] = useState(false)
    return (
        <nav className="navigation" >
            <a href="/" className="brand-name">
                Scandere Inventory Suite
            </a>

            <button className="icon-bars" onClick={() => { setIsNavExpanded(!isNavExpanded); }} hidden={show}>
                <FaBars />
            </button>
            <div className={isNavExpanded ? "navigation-menu expanded" : "navigation-menu"} hidden={show}>
                <ul>
                    <li >
                        <div className={isNavExpanded ? "" : "search"}>
                            <input type="search" className="searchTerm"
                                placeholder='Search products'></input>
                            <button type="submit" className="searchButton">
                                <FaSearch />
                            </button>
                        </div>
                    </li>
                    <li>
                        <a href="/home" className="content-a"><MdModeEditOutline /></a>
                    </li>
                    <li>
                        <a href="/about" className="content-a"><FaBell /></a>
                    </li>
                    <li>
                        <div className="dropdown">
                            <div className="content-a"><FaUser /></div>
                            <div className="dropdown-content">
                                <div className="content-b">{cookies.get('name')}</div>
                                <a href="/profile" className="content-b"><RiAccountBoxFill /> Perfil</a>
                                <a href="/" className="content-b"><AiFillSetting /> Configuración</a>
                                <a href="/" className="content-b"><BiHelpCircle /> Ayuda</a>
                                <a href="/" className="content-b" onClick={salir}><MdOutlineLogout /> Salir</a>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </nav>
    );
}
export default Navbar