import React, { useState, useEffect } from "react";
import { MdAdd, MdDelete, MdLibraryAdd, MdPlusOne } from "react-icons/md";
import Modal from "react-bootstrap/Modal";
import Badge from "react-bootstrap/Badge";
import api from "../services/api";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2"; //npm install sweetalert2
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Fab from '@mui/material/Fab';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import FormGroup from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Table,
  Button,
} from "reactstrap";
import Cookies from "universal-cookie";
import { BiMinus, BiPlus } from "react-icons/bi";

const cookies = new Cookies();

const defaultState = {
  name: "",
  state: "",
  serialNumber: "",
  observations: "",
  sku: "",
  amount: 1,
};
const defaultStateError = {
  name: "",
  state: "",
  serialNumber: "",
  observations: "",
  sku: "",
  amount: 1,
};
const optionState = ["Bien", "Con defectos"];

function RowForm({
  onChange,
  onRemove,
  state,
  serialNumber,
  observations,
  sku,
  amount
}) {
  let [count, setCount] = useState(amount);
  let [error, setError] = useState(true);
  function incrementCount() {
    count = count + 1;
    setCount(count);
    onChange("amount", count)
  }
  function decrementCount() {
    count = count - 1;
    setCount(count);
    onChange("amount", count)
  }
  return (
    <Stack direction="row" spacing={1}>
      <TextField id="outlined-basic" label="Número de serie" variant="outlined" value={serialNumber}
        onChange={(e) => onChange("serialNumber", e.target.value)}
      />
      <TextField id="outlined-basic" label="SKU" variant="outlined" value={sku} onChange={(e) => onChange("sku", e.target.value)}
        required
        error={sku ? false : true}
        helperText={sku ? "" : "El SKU es obligatorio"}
      />
      <TextField
        id="outlined-select-currency"
        select
        label="Estado"
        defaultValue="Bien"
        value={state}
        onChange={(e) => onChange("state", e.target.value)}
      >
        {optionState.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
      <TextField id="outlined-basic" label="Observaciones" variant="outlined" value={observations} onChange={(e) => onChange("observations", e.target.value)} />
      <FormGroup row>
        <IconButton aria-label="edit" disabled={count === 1 && "disabled"} onClick={decrementCount}>
          -
        </IconButton>
        <TextField
          id="outlined-number"
          style={{ width: "50px" }}
          type="number"
          value={amount}
          onChange={(e) => onChange("amount", e.target.value)}
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} />
        <IconButton aria-label="edit" onClick={incrementCount}>
          +
        </IconButton>
      </FormGroup>
      <IconButton color="error" aria-label="edit" onClick={onRemove}>
        <MdDelete />
      </IconButton>
    </Stack>
  );
}

const ListPurchaseOrder = () => {
  const { id } = useParams();

  //Valida el formulario
  const [validoOrden, setValidoOrden] = useState(false);
  const [orden, setOrden] = useState({});
  const [name, setName] = useState("");
  const [rows, setRows] = useState([defaultState]);
  const [errors, setErrors] = useState([defaultStateError]);
  const [botonDisabled, setBotonDisabled] = useState(true);

  //Modal Agregar
  const [show, setShow] = useState(false);
  //Handle del modal add
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };
  //Efffect para obtener productos
  useEffect(() => {
    getOrden();
  }, [orden, id]);

  const handleOnChange = (index, name, value) => {
    const copyRows = [...rows];
    if (name === "sku") {

    }
    copyRows[index] = {
      ...copyRows[index],
      [name]: value,
    };
    setRows(copyRows);
  };

  const handleOnAdd = () => {
    setRows(rows.concat(defaultState));
  };
  const handleOnRemove = (index) => {
    const copyRows = [...rows];
    copyRows.splice(index, 1);
    setRows(copyRows);
  };

  //Funcion para obtener los productos
  const getOrden = async () => {
    if (!validoOrden) {
      await api
        .get("/purchaseOrder/getPurshaseOrderById/" + id)
        .then((res) => {
          setOrden(res.data.purchaseOrder);
          setValidoOrden(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const ingresoStockRoom = (nameProduct) => {
    setName(nameProduct);
    var products = JSON.parse(
      localStorage.getItem(nameProduct) ||
      '[{"name": "", "state": "", "serialNumber": "", "observations": "", "sku": "", "amount": 1}]'
    );
    setRows(products);
    handleShow();
  };
  const addProductToStockRoom = async () => {
    rows.map((item) => (item.name = name));
    localStorage.setItem(name, JSON.stringify(rows));
    Swal.fire({
      icon: "success",
      title: "Realizado",
      showConfirmButton: false,
      timer: 1500,
    });
    handleClose();
    setBotonDisabled(false);
  };

  const finish = async () => {
    let nuevoProductos = [];
    const productos = [...orden.products];
    productos.forEach((item) => {
      const product = localStorage.getItem(item.name);
      if (localStorage.getItem(item.name)) {
        const newProduct = JSON.parse(product);
        nuevoProductos = nuevoProductos.concat(newProduct);
      }
    });
    const stockRoom = {
      idPurchaseOrder: id,
      creator: cookies.get("idUsuario"),
      namePersonDilevery: cookies.get("name"),
      products: nuevoProductos,
    };
    console.log(stockRoom);
    setBotonDisabled(true);
    await api
      .post("/stockRoom/create", stockRoom)
      .then((res) => {
        if (res.data.status) {
          productos.forEach((item) => {
            if (localStorage.getItem(item.name)) {
              localStorage.removeItem(item.name);
            }
          });
          Swal.fire({
            icon: "success",
            title: res.data.message,
            showConfirmButton: false,
            timer: 1500,
          });
          window.location.replace("");
        }
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: err.response.data.message,
          showConfirmButton: true,
        });
      });
  };
  return (
    <div className="Contenedor">
      {validoOrden && (
        <>
          <Card>
            <CardHeader style={{ backgroundColor: "black", color: "white" }}>
              Ingreso de Bodega
            </CardHeader>
            <CardBody>
              <Row>
                <Col sm={3}>
                  <strong>No. Orden de Compra:</strong> {orden.orderNumber}
                </Col>
                <Col sm={5}>
                  <strong>Nombre del proveedor:</strong> {orden.supplier?.name}
                </Col>
                <Col sm={4}>
                  <strong>Fecha de pedido: </strong>{" "}
                  {(new Date(orden.applicationDate).getMonth() > 8
                    ? new Date(orden.applicationDate).getMonth() + 1
                    : "0" + (new Date(orden.applicationDate).getMonth() + 1)) +
                    "/" +
                    (new Date(orden.applicationDate).getDate() > 9
                      ? new Date(orden.applicationDate).getDate()
                      : "0" + new Date(orden.applicationDate).getDate()) +
                    "/" +
                    new Date(orden.applicationDate).getFullYear()}
                </Col>
              </Row>
              <br />
              <Row>
                <Col sm={6}></Col>
                <Col sm={3}>
                  <Link to="/listingresoorden">Lista ingresos de bodega</Link>
                </Col>
                <Col sm={3}>
                  <Button
                    color="primary"
                    onClick={finish}
                    disabled={botonDisabled}
                  >
                    Finalizar Ingreso de Bodega
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <br />
          <Card>
            <Table hover responsive size="lg">
              <thead>
                <tr className="h6">
                  <th>Opción</th>
                  <th>Nombre</th>
                  <th>Cantidad</th>
                  <th>Por entregar</th>
                </tr>
              </thead>
              <tbody>
                {!orden.products ? (
                  <th>
                    <td colSpan={4}>No hay productos</td>
                  </th>
                ) : (
                  <>
                    {orden.products.map((item) => (
                      <tr key={item._id}>
                        <td>
                          <Button
                            color="outline-dark"
                            disabled={item.toDilevery ? false : true}
                            onClick={() => ingresoStockRoom(item.name)}
                          >
                            <MdLibraryAdd />
                          </Button>
                        </td>
                        <td>{item.name}</td>
                        <td>{item.amount}</td>
                        <td>
                          {item.toDilevery ? (
                            item.toDilevery
                          ) : (
                            <Badge bg="primary">Completado</Badge>
                          )}
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
            </Table>
          </Card>
        </>
      )}
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{name}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mb-3">
          <Row className="mb-3">
            {rows.map((row, index) => (
              <RowForm
                {...row}
                errors={errors}
                onChange={(name, value) => handleOnChange(index, name, value)}
                onRemove={() => handleOnRemove(index)}
                key={index}
              />
            ))}
          </Row>
          <Fab color="primary" aria-label="edit" onClick={handleOnAdd}>
            <AddIcon />
          </Fab>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button color="primary" onClick={addProductToStockRoom}>
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ListPurchaseOrder;
