import React, { useEffect, useState } from "react";
import Cookies from 'universal-cookie';
import api from "../../services/api";
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Swal from 'sweetalert2';
import {
    FaCaretLeft,
    FaCaretRight
} from "react-icons/fa";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import {
    HiInformationCircle
} from "react-icons/hi";
import ListModels from './ListModels';
import './pages.css';
import Modal from 'react-bootstrap/Modal';
import GraficaModelo from "./GraficaModelo";

const cookies = new Cookies();


const Modelo = ({ producto, showPanelModelo, setShowPanelModelo }) => {
    const [nameModelo, setNameModelo] = useState("");
    const [demanda, setDemanda] = useState("0");
    const [tipoMantenimiento, setTipoMantenimiento] = useState("porcentaje");
    const [mantenimiento, setMantenimiento] = useState("0");
    const [tipoPorOrdenar, setTipoPorOrdenar] = useState("porcentaje");
    const [porOrdenar, setPorOrdenar] = useState("0");
    const [tiempoAnticipacion, setTiempoAnticipacion] = useState("0");
    const [maximoTiempoAnticipacion, setMaximoTiempoAnticipacion] = useState("0");
    const [businessDays, setBusinessDays] = useState("240");
    const [description, setDescription] = useState("");
    const [showPanelListaModelo, setShowPanelListaModelo] = useState(false);
    const [listaModelo, setListaModelo] = useState([]);
    const [product, setProduct] = useState({});
    const [show, setShow] = useState(false);
    const [chartData, setChartData] = useState([]);

    const handleClose = () => setShow(false);
    const handleShow = async () => {
        await api.get('/products/' + producto._id)
            .then(res => {
                setShow(true);
                setProduct(res.data.producto);
                const data = [{ name: 'Cantidad óptima + stock de seguridad', uv: (res.data.producto.model?.optimalAmount + res.data.producto.model?.securityStock), pv: 2400, amt: 2400 },
                { name: 'Cantidad óptima', uv: res.data.producto.model?.optimalAmount, pv: 2400, amt: 2400 },
                { name: 'Stock actual', uv: res.data.producto.stock, pv: 2400, amt: 2400 },
                { name: 'Punto de reorden', uv: res.data.producto.model?.reorderPoint, pv: 2400, amt: 2400 }];
                const dataDescending = [...data].sort((a, b) => b.uv - a.uv);
                setChartData(dataDescending);
            })
    }

    useEffect(() => {
        getModelos();
    }, []);

    const getModelo = item => {
        setMantenimiento(item.maintenance.value);
        setPorOrdenar(item.forAsking.value);
        setNameModelo(item.name);
        setDescription(item.description);
        setTipoMantenimiento(item.maintenance.type);
        setTipoPorOrdenar(item.forAsking.type);
        setTiempoAnticipacion(item.leadTime);
        setMaximoTiempoAnticipacion(item.maxLeadTime);
        setBusinessDays(item.businessDays);
    };

    const getModelos = async () => {
        try {
            const res = await api.get('/models/')
            setListaModelo(res.data)
        } catch (err) {
            console.log(err)
        }
    }
    //Funcion para guardar o actualizar el modelo
    const guardarModelo = async () => {
        console.log(mantenimiento)
        const modelo = {
            name: nameModelo,
            typeMaintenance: tipoMantenimiento,
            valueMaintenance: mantenimiento,
            typePorOrdenar: tipoPorOrdenar,
            valuePorOrdenar: porOrdenar,
            tiempoAnticipacion: tiempoAnticipacion,
            maxtiempoAnticipacion: maximoTiempoAnticipacion,
            businessDays: businessDays,
            creator: cookies.get("idUsuario"),
            description: description
        };
        const res = await api.post('/models/create', modelo);
        const mensaje = res.data.message;
        if (res.data.status) {
            //Refresacar la pagina
            getModelos();
            //Alerta de agregado
            Swal.fire({
                icon: 'success',
                title: res.data.message,
                showConfirmButton: false,
                timer: 2000
            })
        } else if (mensaje === "El modelo ya existe") {
            Swal.fire({
                title: '¿Estás seguro de actualizar el modelo ' + nameModelo + '?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await api.get('/models/getByName/' + nameModelo)
                        .then(async res2 => {
                            try {
                                await api.put('/models/update/' + res2.data._id, modelo)
                                    .then(res3 => {
                                        if (res3.data.status) {
                                            //refresca la lista de los modelos
                                            getModelos();
                                            Swal.fire({
                                                icon: 'success',
                                                title: res3.data.message,
                                                showConfirmButton: true
                                            })
                                        } else {
                                            Swal.fire({
                                                icon: 'error',
                                                title: res3.data.message,
                                                showConfirmButton: true
                                            })
                                        }
                                    }
                                    );
                            } catch (error) {
                                console.log(error);
                                //logs de error al backend
                                const message = {
                                    message: "Error al actualizar el modelo",
                                    level: 3
                                }
                                await api.post('/logger', message)
                                ///Mensaje de error
                                Swal.fire({
                                    icon: 'error',
                                    title: message.message,
                                    showConfirmButton: false,
                                    timer: 2000
                                })
                            }

                        }
                        );
                }
            });

        } else {
            //logs de error al backend
            const message = {
                message: mensaje,
                level: 3
            }
            await api.post('/logger', message)
            ///Mensaje de error
            Swal.fire({
                icon: 'error',
                title: message.message,
                showConfirmButton: false,
                timer: 2000
            })
        }
    }
    //Funcion para calcular el modelo
    const calcularModelo = async () => {
        await api.put('/products/putModenOnProduct/' + producto._id + '/' + nameModelo)
            .then(resp => {
                if (resp.data.status) {
                    Swal.fire({
                        icon: 'success',
                        title: resp.data.message,
                        showConfirmButton: true
                    })
                }else {
                    Swal.fire({
                        icon: 'error',
                        title: resp.data.message,
                        showConfirmButton: true
                    })
                }
            }).catch(async (err) => {
                console.log(err)
                if (err.response.status !== 500) {
                    Swal.fire({
                        icon: 'error',
                        title: err.response.data.message || "El producto no tiene demanda",
                        showConfirmButton: true
                    })
                } else {
                    //logs de error
                    const mensaje = {
                        message: "Error al cacular el modelo",
                        level: 3
                    };
                    await api.post('/logger', mensaje);
                    ///Mensaje de error
                    Swal.fire({
                        icon: 'error',
                        title: mensaje.message,
                        showConfirmButton: true
                    })
                }
            })
    }
    const colocarDemanda = async () => {
        const demandaProducto = {
            demandaAnualPromedio: demanda
        };
        await api.put('/products/putDemanda/' + producto._id, demandaProducto)
            .then(async response => {
                if (response.data.status) {
                    Swal.fire({
                        icon: 'success',
                        title: response.data.message,
                        showConfirmButton: false,
                        timer: 2000
                    });
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: response.data.message,
                        showConfirmButton: true
                    })
                }
            })
    }
    return (
        <>
            {producto.name && (<>{showPanelModelo && (
                <div className="sectionModel">
                    <h3>Estudio de producto</h3>
                    <h5><strong>Producto: </strong>{producto.name + " "}
                        <Button variant="primary" onClick={handleShow} size="sm">
                            Ver más
                        </Button>
                    </h5>
                    <div className="formModel">
                        <Form>
                            <Form.Group as={Row} className="mb-0" controlId="formHorizontalEmail">
                                <Form.Label column sm={2}>
                                    Nombre del modelo
                                </Form.Label>
                                <Col sm={10}>
                                    <Form.Control type="text" placeholder="Nombre del modelo" onChange={(e) => setNameModelo(e.target.value)} value={nameModelo} />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-0" controlId="formHorizontal">
                                <Form.Label column sm={2}>
                                    Descripción
                                </Form.Label>
                                <Col sm={10}>
                                    <Form.Control as="textarea" rows={3} onChange={(e) => setDescription(e.target.value)} value={description} />
                                </Col>
                            </Form.Group>
                            <div className="tituloForm">Costo</div>
                            <Form.Group as={Row} className="mb-0" controlId="formHorizontal">
                                <Form.Label column sm={2}>
                                    Mantenimiento:
                                    <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-mantenimiento"> Costo que se produce al momento que se realiza el almacenamiento de un producto. Por ejemplo, lucro cesante, costo de almacenaje, salarios involucrados en personal de vigilancia y administración de los almacenes, seguros, impuestos, mermas, pérdidas y costos por servicios públicos.
                                    </Tooltip>}>
                                        <span >
                                            <HiInformationCircle />
                                        </span>
                                    </OverlayTrigger>
                                </Form.Label>
                                <Col sm={10}>
                                    <div className="input-group mb-0">
                                        <div className="input-group-append">
                                            <Form.Select aria-label="Default select example" onChange={(e) => setTipoMantenimiento(e.target.value)} value={tipoMantenimiento}>
                                                <option value="porcentaje">Porcentual</option>
                                                <option value="monetario">Monetario</option>
                                            </Form.Select>
                                        </div>
                                        {tipoMantenimiento === "monetario" ? (
                                            <Form.Control type="number" placeholder="0.00" aria-label="Amount (to the nearest dollar)" onChange={(e) => setMantenimiento(e.target.value)} value={mantenimiento} />
                                        ) : (
                                            <div className="input-group-append">
                                                <Form.Range max={100} onChange={(e) => setMantenimiento(e.target.value)} value={mantenimiento} />
                                            </div>
                                        )}
                                        <div className="input-group-append">
                                            <span className="input-group-text">
                                                {tipoMantenimiento === "porcentaje" && (<>{mantenimiento}</>)}
                                                {tipoMantenimiento === "porcentaje" ? "%" : "$"}
                                            </span>
                                        </div>
                                    </div>
                                </Col>
                                <Form.Label column sm={2}>
                                    Por Ordenar o fijo:
                                    <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-mantenimiento"> Costo que se causa en el instante que pone una orden de compra. No depende de la cantidad pedida, sino es un costo en función de algún tipo de acondicionamiento especial para compra, por ejemplo, papelería, servicios, salarios involucrados, alistamiento de materia prima, combustibles, reparación de una máquina, etc.
                                    </Tooltip>}>
                                        <span >
                                            <HiInformationCircle />
                                        </span>
                                    </OverlayTrigger>
                                </Form.Label>
                                <Col sm={10}>
                                    <div className="input-group mb-0">
                                        <div className="input-group-append">
                                            <Form.Select aria-label="Default select example" onChange={(e) => setTipoPorOrdenar(e.target.value)} value={tipoPorOrdenar}>
                                                <option value="porcentaje">Porcentual</option>
                                                <option value="monetario">Monetario</option>
                                            </Form.Select>
                                        </div>
                                        {tipoPorOrdenar === "monetario" ? (
                                            <Form.Control type="number" placeholder="0.00" aria-label="Amount (to the nearest dollar)" onChange={(e) => setPorOrdenar(e.target.value)} value={porOrdenar} />
                                        ) : (
                                            <div className="input-group-append">
                                                <Form.Range max={100} onChange={(e) => setPorOrdenar(e.target.value)} value={porOrdenar} />
                                            </div>
                                        )}
                                        <div className="input-group-append">
                                            <span className="input-group-text">
                                                {tipoPorOrdenar === "porcentaje" && (<>{porOrdenar}</>)}
                                                {tipoPorOrdenar === "porcentaje" ? "%" : "$"}
                                            </span>
                                        </div>
                                    </div>
                                </Col>
                            </Form.Group>
                            <div className="tituloForm">Tiempo</div>
                            <Form.Group as={Row} className="mb-0" controlId="formHorizontal">
                                <Form.Label column sm={2}>
                                    Anticipación:
                                    <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-mantenimiento">Tiempo en días que transcurre normalmente, entre el momento en que se coloca una orden de compra y el instante en que se recibe la compra.
                                    </Tooltip>}>
                                        <span >
                                            <HiInformationCircle />
                                        </span>
                                    </OverlayTrigger>
                                </Form.Label>
                                <Col sm={10}>
                                    <div className="input-group mb-0">
                                        <Form.Control type="number" placeholder="0" onChange={(e) => setTiempoAnticipacion(e.target.value)} value={tiempoAnticipacion} />
                                        <div className="input-group-append">
                                            <span className="input-group-text">en dias</span>
                                        </div>
                                    </div>
                                </Col>
                                <Form.Label column sm={2}>
                                    Anticipación máxima:
                                    <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-mantenimiento">Tiempo en días que transcurre como máximo, entre el momento en que se coloca una orden de compra y el instante en que se recibe la compra.
                                    </Tooltip>}>
                                        <span >
                                            <HiInformationCircle />
                                        </span>
                                    </OverlayTrigger>
                                </Form.Label>
                                <Col sm={10}>
                                    <div className="input-group mb-0">
                                        <Form.Control type="number" placeholder="0" onChange={(e) => setMaximoTiempoAnticipacion(e.target.value)} value={maximoTiempoAnticipacion} />
                                        <div className="input-group-append ">
                                            <span className="input-group-text">en dias</span>
                                        </div>
                                    </div>
                                </Col>
                                <Form.Label column sm={2}>
                                    Dias laborables:
                                    <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-mantenimiento">Los días al año que el producto estará a la venta, típicamente 240 días al año.</Tooltip>}>
                                        <span >
                                            <HiInformationCircle />
                                        </span>
                                    </OverlayTrigger>
                                </Form.Label>
                                <Col sm={10}>
                                    <div className="input-group mb-0">
                                        <Form.Control type="number" placeholder="0" onChange={(e) => setBusinessDays(e.target.value)} value={businessDays} />
                                        <div className="input-group-append">
                                            <span className="input-group-text">en dias totales en un año</span>
                                        </div>
                                    </div>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Col sm={{ span: 10, offset: 2 }}>
                                    <Button variant="outline-secondary" onClick={guardarModelo}>
                                        Guardar Modelo
                                    </Button>
                                    {" "}
                                    <Button variant="outline-secondary" onClick={calcularModelo}>
                                        Calcular
                                    </Button>
                                </Col>
                            </Form.Group>
                        </Form>
                    </div>
                    <Modal show={show} onHide={handleClose} size="lg">
                        <Modal.Header closeButton>
                            <Modal.Title>{product.name}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <strong>Colección: {product.collectionName}</strong> <br />
                            <strong>Modelo: {product.model?.name ? product.model?.name : "Sin modelo"}</strong> <br />
                            <strong>Precio: </strong> {product.price}<br />
                            <strong>SKU: </strong> {product.sku}<br />
                            <strong>Utilidad: </strong> {product.utility}<br />
                            <strong>Costo: </strong> {product.cost}<br />
                            <strong>Stock: </strong> {product.stock}<br />
                            <Form>
                                <Form.Group as={Row} className="mb-0" controlId="formHorizontalEmail">
                                    <Form.Label column sm={4}>
                                        Demanda anual promedio:
                                        <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-mantenimiento"> Promedio de la demanda de los distintos años.
                                        </Tooltip>}>
                                            <span >
                                                <HiInformationCircle />
                                            </span>
                                        </OverlayTrigger>
                                    </Form.Label>
                                    <Col sm={8}>
                                        <div className="input-group mb-3">
                                            <Form.Control type="number" placeholder={product.averageAnnualDemand ? product.averageAnnualDemand : "Sin demanda"} aria-label="Amount (to the nearest dollar)"
                                                onChange={(e) => setDemanda(e.target.value)} value={product.averageAnnualDemand} />
                                            <div className="input-group-append">
                                                <Button variant="outline-secondary" onClick={colocarDemanda}>
                                                    Colocar demanda
                                                </Button>
                                            </div>
                                        </div>
                                    </Col>
                                </Form.Group>
                            </Form>
                            <strong>Cantidad óptima de pedido: </strong> {product.model?.optimalAmount}<br />
                            <strong>Stock para volver a pedir: </strong> {product.model?.reorderPoint}<br />
                            <strong>Stock de seguridad: </strong> {product.model?.securityStock}<br />
                            <GraficaModelo data={chartData} />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Cerrar
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            )} </>)}
            {producto.name && (
                <div className={showPanelModelo ? "botonCerrarPanelDerecha" : "botonCerrarPanel"}>
                    <button className="botonCerrar" onClick={() => setShowPanelModelo(!showPanelModelo)}>{showPanelModelo ? <FaCaretLeft /> : <FaCaretRight />}</button>
                </div>
            )}
            {producto.name && (<>{!showPanelModelo && (<h1 className="letraLateral">ESTUDIO DE MODELO</h1>)}</>)}
            {showPanelListaModelo && (
                <div className='panel'>
                    <ListModels listaModelo={listaModelo} listarmodelo={getModelos} getModelo={getModelo} />
                </div>)}
            <div className={showPanelListaModelo ? "botonCerrarPanelDerecha" : "botonCerrarPanel"}>
                <button className="botonCerrar" onClick={() => setShowPanelListaModelo(!showPanelListaModelo)}>{showPanelListaModelo ? <FaCaretLeft /> : <FaCaretRight />}</button>
            </div>
            {!showPanelListaModelo && (<h1 className="letraLateral">VER MODELOS</h1>)}
        </>
    );
};
export default Modelo;
