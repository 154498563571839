import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { MdRemoveRedEye, MdOutlineModeEditOutline, MdDelete, MdAddCircle, MdLibraryAdd } from 'react-icons/md';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import DatePicker from "react-datepicker";
import api from "../services/api";
import { Link } from "react-router-dom";
import Swal from 'sweetalert2';

const StockRoom = () => {
    const [ordenes, setOrdenes] = useState([]);
    //hooks para la busqueda
    const [ordenesBusqueda, setOrdenesBusqueda] = useState([])
    const [busqueda, setBusqueda] = useState("");

    //Efffect para obtener productos
    useEffect(() => {
        getOrdenes();
    }, [])
    //Funcion para obtener los productos
    const getOrdenes = async () => {
        await api.get('/purchaseOrder/').then((res) => {
            setOrdenes(res.data.purchaseOrder);
            setOrdenesBusqueda(res.data.purchaseOrder)
        }).catch((err) => {
            console.log(err)
        });
    }

    //busqueda
    const handleChange = e => {
        setBusqueda(e.target.value);
        filtrar(e.target.value);
    }
    //el filtro de la busqueda por el termino de busqueda
    const filtrar = (terminoBusqueda) => {
        var resultadosBusqueda = ordenes.filter((item) => {
            if (item.orderNumber?.toString().toLowerCase().includes(terminoBusqueda.toLowerCase())
            ) {
                return item;
            }
        });
        setOrdenesBusqueda(resultadosBusqueda);
    }
    //Para eliminar la Orden de Compra
    const eliminarOrdendeCompra = (id) => {
        Swal.fire({
            title: '¿Estás seguro de eliminar la orden de compra?',
            text: "¡No podrás revertirlo!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const res = await api.delete('/purchaseOrder/delete/' + id)
                if (res.data.status) {
                    //Refresacar la pagina
                    getOrdenes();
                    //Alerta de editado
                    Swal.fire({
                        icon: 'success',
                        title: res.data.message,
                        showConfirmButton: false,
                        timer: 2000
                    })
                } else {
                    //logs de error
                    const mensaje = {
                        message: "Error al eliminar la orden de compra",
                        level: 3
                    }
                    await api.post('/logger', mensaje)
                    ///Mensaje de error
                    Swal.fire({
                        icon: 'error',
                        title: res.data.message,
                        showConfirmButton: false,
                        timer: 2000
                    })
                }
            }
        });
    }
    return (
        <div className='Contenedor'>
            <div className='row'>
                <div className='col-sm-12'><h3>Lista de Ordenes de compra</h3></div>
                <div className='col-sm-10'></div>
                <div className='col-sm-2'>
                    <Link to={"/purchaseOrder"} relative="path">
                        <Button color="primary" size="sm"
                        //    onClick={() => abrirOrdendeCompra(item._id)}
                        >
                            <MdAddCircle /> Nuevo Orden
                        </Button>
                    </Link>
                </div>

                <div className='col-sm-12'>
                    <div className='cuerpo-table'>
                        <div className="containerInput">
                            <input
                                className="form-control inputBuscar"
                                value={busqueda}
                                placeholder="Búsqueda por el número de orden"
                                onChange={handleChange}
                            />
                        </div>
                        <Table hover responsive size="lg">
                            <thead>
                                <tr className="h6">
                                    <th>Opciones</th>
                                    <th>Proveedor</th>
                                    <th>Número de Orden</th>
                                    <th>Fecha de Solicitud</th>
                                    <th>Total de Pago</th>
                                </tr>
                            </thead>
                            <tbody>
                                {ordenesBusqueda && ordenesBusqueda.map(item => (
                                    <tr key={item._id}>
                                        <td>
                                            <Button variant="danger" size="sm"
                                                onClick={() => eliminarOrdendeCompra(item._id)}
                                            >
                                                <MdDelete />
                                            </Button>
                                            {" "}
                                            <Link to={"/purchaseOrder/" + item._id} relative="path">
                                                <Button color="primary" size="sm"
                                                //    onClick={() => abrirOrdendeCompra(item._id)}
                                                >
                                                    <MdRemoveRedEye />
                                                </Button>
                                            </Link>
                                            {" "}
                                            <Link to={"/admStockRoom/" + item._id} relative="path">
                                                <Button color="primary" size="sm"
                                                //    onClick={() => abrirOrdendeCompra(item._id)}
                                                >
                                                    <MdLibraryAdd />
                                                </Button>
                                            </Link>
                                        </td>
                                        <td>{item.supplier?.name}</td>
                                        <td>{item.orderNumber}</td>
                                        <td>{((new Date(item.applicationDate).getMonth() > 8) ? (new Date(item.applicationDate).getMonth() + 1) : ('0' + (new Date(item.applicationDate).getMonth() + 1))) + '/' + ((new Date(item.applicationDate).getDate() > 9) ? new Date(item.applicationDate).getDate() : ('0' + new Date(item.applicationDate).getDate())) + '/' + new Date(item.applicationDate).getFullYear()}</td>
                                        <td>$ {item.totalToPay}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div></div>
    )
}

export default StockRoom