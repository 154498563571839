import React, { useState, useEffect } from 'react';
import './pages.css';
import {
    FaBoxes
} from "react-icons/fa";
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';
import api from "../../services/api";

const ProductsProducStudy = ({ coleccion, clasificacion, listaProductos, abrirModelo }) => {
    const [show, setShow] = useState(false);
    const [listaModelo, setListaModelo] = useState([]);
    const [modelo, setModelo] = useState("");
    useEffect(() => {
        getModelos();
    }, []);

    const getModelos = async () => {
        try {
            const res = await api.get('/models/');
            setListaModelo(res.data);
        } catch (err) {
            console.log(err)
        }
    }
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const abrirColeccion = () => {
        const item = { name: coleccion, clase: clasificacion };
        abrirModelo(item);
    }
    //Funcion para calcular el modelo
    const aplicarModelo = async () => {
        await api.put('/products/putModenOnCollectionAndClasification/' + clasificacion + '/' + coleccion + '/' + modelo)
            .then(resp => {
                if (resp.data.status) {
                    Swal.fire({
                        icon: 'success',
                        title: resp.data.message,
                        showConfirmButton: true
                    })
                }
                handleClose();
            }).catch(async (err) => {
                console.log(err)
                if (err.response.status !== 500) {
                    Swal.fire({
                        icon: 'error',
                        title: err.response.data.message || "El producto no tiene demanda",
                        showConfirmButton: true
                    })
                } else {
                    //logs de error
                    const mensaje = {
                        message: "Error al cacular el modelo",
                        level: 3
                    };
                    await api.post('/logger', mensaje);
                    ///Mensaje de error
                    Swal.fire({
                        icon: 'error',
                        title: mensaje.message,
                        showConfirmButton: true
                    })
                }
            });
    }
    return (
        <>
            <div>
                <h3>Productos <FaBoxes /></h3>
                <h5>Clase {clasificacion} {() => abrirColeccion()}</h5>
                <div className='listaStudio'>
                    <ListGroup >
                        <ListGroup.Item
                            action
                            variant="light"
                            className="d-flex justify-content-between align-items-start"
                        >
                            <div className="ms-2 me-auto">
                                <div className="fw-bold"><strong>Colección: </strong>{coleccion}</div>
                            </div>
                            <Button bg="primary" pill="true" size="sm" onClick={handleShow}>
                                Aplicar modelo
                            </Button>
                        </ListGroup.Item>
                        {listaProductos.map(item => (
                            <ListGroup.Item
                                variant="light"
                                action
                                onClick={() => abrirModelo(item)}
                                key={item._id}
                            >
                                {item.image === undefined ? <FaBoxes /> : <img src={item.image} alt="imagen de perfil" height='100' />}
                                {" "}
                                {item.name}
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </div>
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Clasificación {clasificacion} - {coleccion}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Seleccionar el modelo</Form.Label>
                            <Form.Select
                                id="selectToastPlacement"
                                className="mt-2"
                                autoFocus
                                onChange={(e) => setModelo(e.target.value)}
                                value={modelo}
                            >
                                <option>Escoger el modelo</option>
                                {listaModelo.map((p) => (
                                    <option key={p} value={p.name} >
                                        {p.name}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={aplicarModelo}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>

    )
}

export default ProductsProducStudy