import React, { useState } from 'react';
import { LineChart, Line, CartesianGrid, YAxis, XAxis, Tooltip  } from "recharts"



import api from "../../services/api";

import './pages.css';

const GraficaModelo = ({ data }) => {

    return (
        <LineChart width={700} height={400} data={data}>
            <Line type="monotone" dataKey="uv" stroke="green" />
            <CartesianGrid stroke="gray" />
            <YAxis />
            <XAxis dataKey="name" />
            <Tooltip />
        </LineChart>
    );
};

export default GraficaModelo;