import React, { useState, useEffect } from 'react';
import './styles.css'
import api from "../services/api";
import {
    FaAngleRight,
    FaAngleDown,
    FaCaretLeft,
    FaCaretRight
} from "react-icons/fa";
import ListGroup from 'react-bootstrap/ListGroup';
import ProductsProducStudy from "../Components/Pages/ProductsProducStudy"
import Modelo from '../Components/Pages/Modelo';
import Button from 'react-bootstrap/Button';
import ModalAplicarModel from '../Components/Pages/ModalAplicarModel';
import Swal from 'sweetalert2';
import LinearProgress from '@mui/material/LinearProgress';
import Cookies from 'universal-cookie';
import { PageErrorPermisos } from './PageErrorPermisos';

const cookies = new Cookies();

const ProductStudy = () => {
    const [clasificacion, setClasificacion] = useState("");
    const [coleccion, setColleccion] = useState("");
    const [listaProductos, setListaProductos] = useState([])
    const [producto, setProducto] = useState({});
    const [showPanelClasificacion, setShowPanelClasificacion] = useState(false);
    const [showPanelProducto, setShowPanelProducto] = useState(false);
    const [showPanelModelo, setShowPanelModelo] = useState(false);
    //Permisos
    const [permisos, setPermisos] = useState(undefined);
    //hooks para el modal
    const [show, setShow] = useState(false);
    const [clasificacionModal, setClasificacionModal] = useState("");

    const changeShowModelo = estado => setShowPanelModelo(estado);

    useEffect(() => {
        //Funcion para obtener los permisos del usuario
        const getPermisos = async () => {
            const res = await api.get('/permisos/usuario/' + cookies.get("idPerfil"))
            res.data.permisos.forEach(element => {
                if (element.name === "StudyProduct") {
                    setPermisos(element.permisos)
                }
            });
        }
        getPermisos()
    }, [permisos])
    const abrirProducto = async (colection, clase) => {
        const res = await api.get('/products/getProductByClasificationAndCollection/' + clase + '/' + colection);
        const data = res.data
        setListaProductos(data);
        setClasificacion(clase);
        setColleccion(colection);
        setProducto({});
        setShowPanelProducto(true);
    }



    const abrirModelo = item => {
        setProducto(item);
        setShowPanelModelo(true);
    };
    const hanndleShow = (clasificacion) => {
        setShow(true);
        setClasificacionModal(clasificacion);
    }
    const Lista = () => {
        const [open, setOpen] = useState("");
        const [listaCollection, setListaCollection] = useState([]);
        useEffect(() => {
            //Funcion para agregar los colection
            const getCollections = async (clasificacion) => {
                try {
                    const res = await api.get('/collections/getCollectionByClasification/' + clasificacion);
                    setListaCollection(res.data.collection || []);
                } catch (err) {
                    console.log(err)
                }
            }
            getCollections(open)
        }, [open])
        return (
            <ListGroup >
                {['A', 'B', 'C'].map((clasificacion) => (
                    <>
                        <ListGroup.Item
                            action
                            key={clasificacion}
                            className="d-flex justify-content-between align-items-start"
                            onClick={() => { open === clasificacion ? setOpen("") : setOpen(clasificacion) }}
                        >
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">{open === clasificacion? <FaAngleDown /> : <FaAngleRight />} {clasificacion}</div>
                            </div>
                            <Button bg="primary" pill="true" size="sm" onClick={() => hanndleShow(clasificacion)}>
                                Aplicar modelo
                            </Button>
                        </ListGroup.Item>
                        {open === clasificacion ? <Child clase={clasificacion} listaCollection={listaCollection}/> : ""}
                    </>
                ))}
            </ListGroup>
        );
    };
    const Child = (props) => {
        
        return (
            <ListGroup>
                {props.listaCollection.map(item => (
                    <ListGroup.Item
                        action
                        key={item}
                        onClick={() => abrirProducto(item, props.clase)}
                        className="d-flex justify-content-between align-items-start"
                    >
                        <div className="ms-2 me-auto">
                            <img src="https://static.thenounproject.com/png/2221162-200.png" alt="imagen de coleccion" height='25' />
                            {item}
                        </div>
                    </ListGroup.Item>
                ))}
            </ListGroup>
        );
    };
    if (permisos === undefined) {
        return (
            <LinearProgress />
        )
    }
    if (permisos === 0) {
        return (
            <PageErrorPermisos />
        )
    }
    return (
        <div className='Contenedor-studio d-flex'>
            {!showPanelClasificacion && (<div className='panel' >
                <h3>Clasificacion ABC</h3>
                <div className='listaStudio'>
                    <Lista />
                </div>
            </div>)}
            <div className={!showPanelClasificacion ? "botonCerrarPanelDerecha" : "botonCerrarPanel"}>
                <button className="botonCerrar" onClick={() => setShowPanelClasificacion(!showPanelClasificacion)}>{!showPanelClasificacion ? <FaCaretLeft /> : <FaCaretRight />}</button>
            </div>
            {showPanelClasificacion && (<h1 className="letraLateral">CLASIFICACIÓN ABC</h1>)}
            {coleccion && (<>{showPanelProducto && (
                <div className='panel'>
                    <ProductsProducStudy
                        coleccion={coleccion}
                        clasificacion={clasificacion}
                        listaProductos={listaProductos}
                        abrirModelo={abrirModelo}
                    />
                </div>
            )}
            </>)}
            {coleccion && (
                <div className={showPanelProducto ? "botonCerrarPanelDerecha" : "botonCerrarPanel"}>
                    <button className="botonCerrar" onClick={() => setShowPanelProducto(!showPanelProducto)}>{showPanelProducto ? <FaCaretLeft /> : <FaCaretRight />}</button>
                </div>
            )}
            {coleccion && (<>{!showPanelProducto && (<h1 className="letraLateral">PRODUCTOS</h1>)}</>)}
            <Modelo producto={producto} showPanelModelo={showPanelModelo} setShowPanelModelo={changeShowModelo} />
            {/*Modal para aplicar modelo */}
            <ModalAplicarModel show={show} handleClose={() => setShow(false)} clasificacion={clasificacionModal} />
        </div >
    )
}

export default ProductStudy