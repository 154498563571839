import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import Swal from 'sweetalert2' //npm install sweetalert2
import Cookies from 'universal-cookie';
import ProductsCollectionList from '../Components/Pages/ProductsCollectionList';
import api from "../services/api";
import LoadPage from '../Components/LoadPage/LoadPage';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LinearProgress from '@mui/material/LinearProgress';
import {
    FaCaretLeft,
    FaCaretRight
} from "react-icons/fa"
import Pagination from 'react-bootstrap/Pagination';

import "./styles.css";
import { PageErrorPermisos } from './PageErrorPermisos';

const cookies = new Cookies();

const Collection = () => {
    //Valida el formulario
    const [validated, setValidated] = useState(false);
    const [showListProduct, setShowListProduct] = useState(false)
    const [listaCollection, setListaCollection] = useState([])
    const [image, setImage] = useState({ preview: '', data: '', valido: true })
    const [name, setName] = useState("")
    const [idColeccion, setIdColeccion] = useState("")
    const [description, setDescription] = useState("")
    const [coleccion, setColeccion] = useState([])
    //
    const [permisos, setPermisos] = useState(undefined)
    const [permisosProductos, setPermisosProductos] = useState(undefined)

    //Modal Agregar
    const [show, setShow] = useState(false);
    //Handle del modal add
    const handleClose = () => setShow(false);
    const handleShow = () => {
        if (permisos === 2 || permisos === 3 || permisos > 5) {
            const img = {
                preview: '',
                data: '',
                valido: true
            }
            setName("")
            setImage(img)
            setValidated(false)
            setDescription("")
            setShow(true)
        } else {
            //Alerta de error por no tener permiso
            Swal.fire({
                icon: 'error',
                title: "No tienes permiso",
                showConfirmButton: false,
                timer: 2000
            })
        }

    };
    //Efffect para obtener productos
    useEffect(() => {
        getCollections();
    }, []);

    //Valores iniciales
    const [currentPage, setCurrentPage] = useState(1);
    const [PerPage, setPerPage] = useState(8);

    // Obtiene los indices a partir de la lista
    const totalPages = Math.ceil(listaCollection.length / PerPage);
    const indexOfLast = currentPage * PerPage;
    const indexOfFirst = indexOfLast - PerPage;
    const currentListCollection = listaCollection.slice(indexOfFirst, indexOfLast);

    // Cambiar pagina
    const paginate = pageNumber => setCurrentPage(pageNumber);

    const createPaginationItem = (i) => {
        return <Pagination.Item
            key={i}
            active={i === currentPage}
            onClick={() => paginate(i)}
        >
            {i}
        </Pagination.Item>
    };

    const createListPagination = () => {
        const actualpage = currentPage
        const leftBound = actualpage > 3 ? actualpage - 1 : 2;
        const pageBound = actualpage !== totalPages ? actualpage === 1 ? 3 : actualpage === 2 ? 3 : actualpage : 0;
        const rightBound = pageBound === 0 ? 0 : pageBound + 1 !== totalPages ? pageBound + 1 : 0;
        const leftPage = leftBound + 2 === totalPages ? leftBound - 1 : actualpage === totalPages ? actualpage - 3 : leftBound < totalPages ? leftBound : 0;
        const page = leftBound + 2 === totalPages ? leftBound : actualpage === totalPages ? actualpage - 2 : pageBound < totalPages ? pageBound : 0;
        const rightPage = leftBound + 2 === totalPages ? leftBound + 1 : actualpage === totalPages ? actualpage - 1 : rightBound < totalPages ? rightBound : 0;
        return <>
            {leftPage > 2 && (<Pagination.Ellipsis />)}
            {leftPage > 1 && (<>{createPaginationItem(leftPage)}</>)}
            {page > 1 && (<>{createPaginationItem(page)}</>)}
            {rightPage > 1 && (<>{createPaginationItem(rightPage)}</>)}
            {rightPage < totalPages - 3 && (<Pagination.Ellipsis />)}
        </>
    };


    //Funcion para agregar los productos
    const getCollections = async () => {
        try {
            const res = await api.get('/collections/')
            setListaCollection(res.data)
            getPermisos()
        } catch (err) {
            console.log(err)
        }

    }
    //Funcion para obtener los permisos del usuario
    const getPermisos = async () => {
        const res = await api.get('/permisos/usuario/' + cookies.get("idPerfil"))
        res.data.permisos.forEach(element => {
            if (element.name === "Colecciones") {
                setPermisos(element.permisos)
            }
            if (element.name === "Products") {
                setPermisosProductos(element.permisos)
            }
        });
    }
    //Funcion para agregar los productos 
    const addCollection = async () => {
        const userId = cookies.get('idUsuario');
        let formData = new FormData()
        formData.append('image', image.data)
        formData.append('name', name)
        formData.append('description', description)
        formData.append('creator', userId)
        console.log("hey", formData)
        const res = await api.post('/collections/create', formData)

        const msg = res.data.message
        if (res.data.status) {
            //Refresacar la pagina
            getCollections()
            //Alerta de agregado
            Swal.fire({
                icon: 'success',
                title: msg,
                showConfirmButton: false,
                timer: 2000
            })
            //Para cerrar el modal
            setShow(false)
        } else {
            Swal.fire({
                icon: 'error',
                title: msg,
                showConfirmButton: false,
                timer: 2000
            })
        }

    }
    //Funcion para eliminar un producto 
    const deleteCollection = async (id) => {
        if (permisos === 1 || permisos === 3 || permisos === 5 || permisos === 7) {
            Swal.fire({
                title: '¿Estás seguro de eliminar la colección?',
                text: "¡No podrás revertirlo!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await api.delete('/collections/delete/' + id).then((res) => {
                        if (!res.data.status) {
                            //Refresacar la pagina
                            getCollections()
                            //Alerta de eliminado
                            Swal.fire({
                                icon: 'success',
                                title: res.data.message,
                                showConfirmButton: false,
                                timer: 2000
                            })
                        }
                    }).catch(async (err) => {
                        console.log(err)
                        if (err.response.status !== 500) {
                            Swal.fire({
                                icon: 'error',
                                title: err.response.data.message || "No se puede eliminar la colección",
                                showConfirmButton: true
                            })
                        }
                    })
                }
            })
        } else {
            //Alerta de error por no tener permiso
            Swal.fire({
                icon: 'error',
                title: "No tienes permiso",
                showConfirmButton: false,
                timer: 2000
            })
        }
    }
    //funcion para obtener un product. Al presionar el boton edit pasa el producto de la fila seleccionada para obtner el producto y mostralo en el modal
    const getCollection = async (item) => {
        //Mostrar el modal
        handleShowEdit(true)
        setIdColeccion(item._id)
        setName(item.name)
        const img = {
            preview: item.image,
            data: item.image,
            valido: true
        }
        setImage(img)
        setDescription(item.description)

    }
    //Funcion para actualizar la colección
    const updateCollection = async (e) => {
        e.preventDefault();
        const id = idColeccion
        let formData = new FormData()
        formData.append('image', image.data)
        formData.append('name', name)
        formData.append('description', description)
        await api.put('/collections/update/' + id, formData).then((res) => {
            const msg = res.data.message
            if (res.data.status) {
                //Refresacar la pagina
                getCollections()
                //Alerta de editado
                Swal.fire({
                    icon: 'success',
                    title: msg,
                    showConfirmButton: false,
                    timer: 2000
                })
                //Para cerrar el modal
                setShowEdit(false)
            } else {
                //Alerta de editado
                Swal.fire({
                    icon: 'error',
                    title: msg,
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        }).catch((err) => {
            Swal.fire({
                icon: 'error',
                title: err.response.data.message,
                showConfirmButton: false,
                timer: 2000
            })
        })


    }
    //Modal Edit
    const [showEdit, setShowEdit] = useState(false);
    //Handle del modal edit
    const handleCloseEdit = () => setShowEdit(false);
    const handleShowEdit = () => {
        if (permisos === 2 || permisos === 3 || permisos > 5) {
            setValidated(false)
            setShowEdit(true);
        } else {
            //Alerta de error por no tener permiso
            Swal.fire({
                icon: 'error',
                title: "No tienes permiso",
                showConfirmButton: false,
                timer: 2000
            })
        }
    }
    //Funcion que valida la extension del archivo y almmacena su estado
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        let valido = true
        switch (file.type) {
            case 'image/png':
                valido = true
                break;
            case 'image/jpg':
                valido = true
                break;
            case 'image/jpeg':
                valido = true
                break;
            case '':
                valido = true
                break;
            default:
                valido = false
        }
        const img = {
            preview: URL.createObjectURL(file),
            data: file,
            valido: valido
        }
        setImage(img)
    }
    const showProducts = async (name) => {
        const res = await api.get('/collections/' + name)
        setColeccion(res.data)
        setShowListProduct(true);
    }
    const cerrarLista = () => {
        setShowListProduct(!showListProduct);
    }
    if (permisos === undefined) {
        return (
            <LinearProgress />
        )
    }
    if (permisos < 4) {
        return (
            <PageErrorPermisos />
        )
    }
    return (
        <div className="Contenedor">
            <div className='encabezado'>
                <h3>Colecciones</h3>
                <div className='botones'>
                    <Button variant="primary" onClick={handleShow}>
                        Añadir Colección
                    </Button>
                </div>
            </div>
            <div className='d-flex'>
                <Container>
                    <Row>
                        {currentListCollection.map(item => (
                            <Col sm={3} key={item._id}>
                                <div className="wsk-cp-product" >
                                    <div className="wsk-cp-img">
                                        {item.image === undefined ? <img src="https://static.thenounproject.com/png/2221162-200.png" alt="imagen de coleccion" /> : <img src={item.image} alt="imagen de perfil" />}</div>
                                    <div className="wsk-cp-text">
                                        <div className="category">
                                            <button onClick={() => showProducts(item.name)}>Ver productos</button>
                                        </div>
                                        <div className="title-product">
                                            <h3>{item.name}</h3>
                                        </div>
                                        <div className="description-prod">
                                            <p>{item.description}</p>
                                        </div>
                                        <div className="card-footer">
                                            <div className="wcf-left"><Button variant="primary" onClick={() => getCollection(item)}>Editar</Button></div>
                                            <div className="wcf-right"><Button variant="danger" onClick={() => deleteCollection(item._id)}>Eliminar</Button></div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        ))}
                    </Row>
                    <div className='row'>
                        <div className="col-sm-8">
                            <Pagination className="paginationInfo">
                                <Pagination.Prev
                                    onClick={() => paginate(currentPage - 1)}
                                    disabled={currentPage === 1}
                                />
                                {createPaginationItem(1)}
                                {totalPages > 1 && (<>
                                    {createListPagination()}
                                    {createPaginationItem(totalPages)}
                                </>)}
                                <Pagination.Next
                                    onClick={() => paginate(currentPage + 1)}
                                    disabled={currentPage === totalPages}
                                />
                            </Pagination>
                        </div>
                        <div className="col-sm-2">
                            <select
                                defaultValue="5"
                                onChange={(event) => { setPerPage(event.target.value); paginate(1) }}
                                value={PerPage}
                            >
                                <option value="8">8</option>
                                <option value="20">20</option>
                                <option value="32">32</option>
                                <option value="40">40</option>
                            </select>
                        </div>
                        <div className="col-sm-2">
                            Total de colecciones: {listaCollection.length}
                        </div>
                    </div>
                </Container>
                <div className={showListProduct ? "botonCerrarPanelDerecha" : "botonCerrarPanel"}>
                    <button className="botonCerrar" onClick={() => cerrarLista(true)}>{!showListProduct ? <FaCaretLeft /> : <FaCaretRight />}</button>
                </div>
                {showListProduct && (
                    <ProductsCollectionList items={coleccion} permisos={permisosProductos} showProducts={showProducts} />
                )}
            </div>

            <Modal show={show} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Añadir Colección</Modal.Title>
                </Modal.Header>
                <Formik
                    validate={() => {
                        setValidated(false)
                        let errores = {};
                        // Validacion nombre
                        if (!name) {
                            errores.name = '*Por favor ingresa una nombre. Campo obligatorio*'
                        } else if (!/^[\wÀ-ÿ\s,"-.:-;+*~´°!"#$%&/()=?|¬¨^`<>]{1,100}$/.test(name)) {
                            errores.name = 'El nombre no es correcto, no puede sobre pasar los 100 caracteres'
                        }
                        if (!/^[\wÀ-ÿ\s,"-.:-;+*~´°!"#$%&/()=?|¬¨^`<>]*$/.test(description)) {
                            errores.description = 'La descripción es incorrecta'
                        }
                        if (!image.valido) {
                            errores.file = 'La tipo de archivo es incorrecto. Solo se permite tipo .jpg, .png y jpeg'
                        }
                        if (!errores.name) {
                            setValidated(true)
                        }
                        return errores;
                    }}
                >
                    {({
                        handleBlur,
                        touched,
                        errors,
                    }) => (
                        <Form >
                            <Modal.Body>
                                <h6>*Los nombre es obligatorio*</h6>
                                <Row className="mb-3">
                                    <Form.Group className="position-relative mb-3">
                                        <Form.Label>Imagen de la colección</Form.Label>
                                        {image.preview && <img src={image.preview} alt="imagen de perfil" width='100' height='100' />}
                                        <Form.Control
                                            type="file"
                                            required
                                            name="file"
                                            onChange={handleFileChange}
                                            onBlur={handleBlur}
                                            isValid={touched.file && !errors.file}
                                            isInvalid={!!errors.file}
                                        />
                                        <Form.Control.Feedback type="invalid" tooltip>
                                            {errors.file}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="12" >
                                        <Form.Label>Nombre</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="name"
                                            name="name"
                                            placeholder="Nombre"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            onBlur={handleBlur}
                                            isValid={touched.name && !errors.name}
                                            isInvalid={!!errors.name}
                                        />
                                        <Form.Control.Feedback type="invalid" >
                                            {errors.name}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="12">
                                        <Form.Label>Descripción</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            id="description"
                                            name="description"
                                            placeholder="Escribir una descripción de la colección"
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                            onBlur={handleBlur}
                                            isValid={touched.description && !errors.description}
                                            isInvalid={!!errors.description}
                                        />
                                        <Form.Control.Feedback type="invalid" >
                                            {errors.description}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Cancelar
                                </Button>
                                <Button variant="primary" onClick={addCollection} disabled={!validated}>
                                    Guardar
                                </Button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>
            <Modal show={showEdit} onHide={handleCloseEdit} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title><h3>Editar Colección</h3></Modal.Title>
                </Modal.Header>
                <Formik
                    validate={() => {
                        let errores = {};
                        // Validacion nombre
                        if (!name) {
                            errores.name = '*Por favor ingresa una nombre. Campo obligatorio*'
                        } else if (!/^[\wÀ-ÿ\s,"-.:-;+*~´°!"#$%&/()=?|¬¨^`<>]{1,100}$/.test(name)) {
                            errores.name = 'El nombre no es correcto, no puede sobre pasar los 100 caracteres'
                        }
                        if (!/^[\wÀ-ÿ\s,"-.:-;+*~´°!"#$%&/()=?|¬¨^`<>]*$/.test(description)) {
                            errores.description = 'La descripción es incorrecta'
                        }
                        if (!errores.name) {
                            setValidated(true)
                        }
                        if (!errores.name) {
                            setValidated(true)
                        }
                        return errores;
                    }}
                >
                    {({
                        handleBlur,
                        touched,
                        errors,
                    }) => (
                        <Form>
                            <Modal.Body>
                                <h6>*El nombre es obligatorio*</h6>
                                <Row className="mb-3">
                                    <Form.Group className="position-relative mb-3">
                                        <Form.Label>Imagen de la colección</Form.Label>
                                        {image.preview && <img src={image.preview} alt="imagen de perfil" width='100' height='100' />}
                                        <Form.Control
                                            type="file"
                                            required
                                            name="file"
                                            onChange={handleFileChange}
                                            onBlur={handleBlur}
                                            isValid={touched.file && !errors.file}
                                            isInvalid={!!errors.file}
                                        />
                                        <Form.Control.Feedback type="invalid" tooltip>
                                            {errors.file}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="12" >
                                        <Form.Label>Nombre</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="name"
                                            name="name"
                                            placeholder="Nombre"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            onBlur={handleBlur}
                                            isValid={touched.name && !errors.name}
                                            isInvalid={!!errors.name}
                                        />
                                        <Form.Control.Feedback type="invalid" >
                                            {errors.name}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="12">
                                        <Form.Label>Descripción</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            id="description"
                                            name="description"
                                            placeholder="Escribir una descripción de la colección"
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                            onBlur={handleBlur}
                                            isValid={touched.description && !errors.description}
                                            isInvalid={!!errors.description}
                                        />
                                        <Form.Control.Feedback type="invalid" >
                                            {errors.description}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleCloseEdit}>
                                    Cancelar
                                </Button>
                                <Button variant="primary" onClick={updateCollection} disabled={!validated}>
                                    Guardar
                                </Button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </div >
    )
}

export default Collection;