import React, { useState, useEffect } from 'react';
import './styles.css'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import { MdDelete, MdOutlineModeEditOutline } from 'react-icons/md';
import Swal from 'sweetalert2' //npm install sweetalert2
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Formik } from 'formik';
import Pagination from 'react-bootstrap/Pagination';
import api from "../services/api";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import Cookies from 'universal-cookie';

const cookies = new Cookies();


function Provider() {
    //Valida el formulario
    const [validated, setValidated] = useState(false);
    //Hooks para agregar y listar los prdouctos
    const [listProvider, setListProvider] = useState([]);
    const [name, setName] = useState("");
    const [identification, setIdentification] = useState("");
    const [address, setAddress] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [webPage, setWebPage] = useState("");
    const [showModalUpdate, setShowModalUpdate] = useState(false);
    const [idSupplier, setIdSupplier] = useState("");

    //hooks para la busqueda
    const [providers, setProviders] = useState([]);
    const [busqueda, setBusqueda] = useState("");

    //Efffect para obtener productos
    useEffect(() => {
        getSuppliers();
    }, [])

    //Valores iniciales
    const [currentPage, setCurrentPage] = useState(1);
    const [PerPage, setPerPage] = useState(5);

    // Obtiene los indices a partir de la lista
    const totalPages = Math.ceil(providers.length / PerPage);
    const indexOfLast = currentPage * PerPage;
    const indexOfFirst = indexOfLast - PerPage;
    const currentListPrviders = providers.slice(indexOfFirst, indexOfLast);

    // Cambiar pagina
    const paginate = pageNumber => setCurrentPage(pageNumber);

    const createPaginationItem = (i) => {
        return <Pagination.Item
            key={i}
            active={i === currentPage}
            onClick={() => paginate(i)}
        >
            {i}
        </Pagination.Item>
    };
    //Funcion para obtener los proveedores
    const getSuppliers = async () => {
        const res = await api.get('/suppliers/')
        setListProvider(res.data.suppliers);
        setProviders(res.data.suppliers);
    }

    //Modal Agregar
    const [show, setShow] = useState(false);
    //Handle del modal add
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setName("");
        setIdentification("");
        setAddress("");
        setPhone("");
        setWebPage("");
        setEmail("");
        setShow(true);
        setValidated(false)
    };
    //funcion para obtener un proveedor. Al presionar el boton edit pasa el proveedor de la fila seleccionada para obtener
    // el producto y mostralo en el modal
    const getSupplier = (item) => {
        setIdSupplier(item._id)
        setName(item.name);
        setIdentification(item.identification);
        setAddress(item.direction);
        setPhone((item.phone?.toString() || ''));
        setWebPage(item.webPage);
        setEmail(item.email);
        setShow(true);
        setShowModalUpdate(true);
        setValidated(true);
    }
    const createListPagination = () => {
        const actualpage = currentPage
        const leftBound = actualpage > 3 ? actualpage - 1 : 2;
        const pageBound = actualpage !== totalPages ? actualpage === 1 ? 3 : actualpage === 2 ? 3 : actualpage : 0;
        const rightBound = pageBound === 0 ? 0 : pageBound + 1 !== totalPages ? pageBound + 1 : 0;
        const leftPage = leftBound + 2 === totalPages ? leftBound - 1 : actualpage === totalPages ? actualpage - 3 : leftBound < totalPages ? leftBound : 0;
        const page = leftBound + 2 === totalPages ? leftBound : actualpage === totalPages ? actualpage - 2 : pageBound < totalPages ? pageBound : 0;
        const rightPage = leftBound + 2 === totalPages ? leftBound + 1 : actualpage === totalPages ? actualpage - 1 : rightBound < totalPages ? rightBound : 0;
        return <>
            {leftPage > 2 && (<Pagination.Ellipsis />)}
            {leftPage > 1 && (<>{createPaginationItem(leftPage)}</>)}
            {page > 1 && (<>{createPaginationItem(page)}</>)}
            {rightPage > 1 && (<>{createPaginationItem(rightPage)}</>)}
            {rightPage < totalPages - 3 && (<Pagination.Ellipsis />)}
        </>
    };
    //el filtro de la busqueda por el nombre
    const filtrar = (terminoBusqueda) => {
        var resultadosBusqueda = listProvider.filter((item) => {
            if (item.name.toString().toLowerCase().includes(terminoBusqueda.toLowerCase())
                || item.identification.toString().toLowerCase().includes(terminoBusqueda.toLowerCase())
            ) {
                return item;
            }
        });
        setProviders(resultadosBusqueda);
    }
    //busqueda
    const handleChange = e => {
        setBusqueda(e.target.value);
        filtrar(e.target.value);
    }
    //Para agregar un nuevo proveedor 
    const addProvider = async () => {
        const nuevoProveedor = {
            name: name,
            identification: identification,
            direction: address,
            phone: phone,
            webPage: webPage,
            email: email,
            creator: cookies.get("idUsuario"),
            contacts: []
        }

        await api.post('/suppliers/create', nuevoProveedor).then((res) => {
            if (res.data.status) {
                //Refresacar la pagina
                getSuppliers()
                //Alerta de agregado
                Swal.fire({
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 2000
                })
                //Para cerrar el modal
                setShow(false)
            }
        }).catch(async (err) => {
            Swal.fire({
                icon: 'error',
                title: err.response.data.message,
                showConfirmButton: true
            })
        })
    }
    //Funcion para eliminar un producto 
    const deleteProduct = async (id) => {
        Swal.fire({
            title: '¿Estás seguro de eliminar el proveedor?',
            text: "¡No podrás revertirlo!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await api.delete('/suppliers/delete/' + id).then((res) => {
                    if (res.data.status) {
                        //Refresacar la pagina
                        getSuppliers();
                        //Alerta de editado
                        Swal.fire({
                            icon: 'success',
                            title: res.data.message,
                            showConfirmButton: false,
                            timer: 2000
                        })
                    }
                })
            }
        }).catch((err) => {
            Swal.fire({
                icon: 'error',
                title: err.response.data?.message,
                showConfirmButton: true
            })
        })
    }
    //Funcion para actualizar el proveedor
    const updateProvider = async (e) => {
        e.preventDefault();
        const nuevoProveedor = {
            name: name,
            identification: identification,
            direction: address,
            phone: phone,
            webPage: webPage,
            email: email,
            creator: cookies.get("idUsuario"),
            contacts: []
        }
        await api.put('/suppliers/update/' + idSupplier, nuevoProveedor).then((res) => {
            if (res.data.status) {
                //Refresacar la pagina
                getSuppliers();
                //Alerta de actualizado
                Swal.fire({
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 2000
                })
                //Para cerrar el modal
                setShow(false);
                setShowModalUpdate(false)
            }
        }).catch((err) => {
            Swal.fire({
                icon: 'error',
                title: err.response.data?.message,
                showConfirmButton: true
            })
        })
    }
    return (
        <div className='Contenedor'>
            <div className='row'>
                <div className='col-sm-12'><h3>Proveedores</h3></div>
                <div className='col-sm-10'></div>
                <div className='col-sm-2'>
                    <Button variant="primary" onClick={handleShow}>
                        Añadir Proveedor
                    </Button>
                </div>
                <div className='col-sm-12'>
                    <div className="containerInput">
                        <input
                            className="form-control inputBuscar"
                            value={busqueda}
                            placeholder="Búsqueda por el nombre o identificación del proveedor"
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className='col-sm-12'>
                    <div className='cuerpo-table'>

                        <Table hover responsive size="lg">
                            <thead>
                                <tr className="h5">
                                    <th colSpan={2}>Opciones</th>
                                    <th>RUC/Cédula</th>
                                    <th>Nombre</th>
                                    <th>Dirección</th>
                                    <th>Telefono</th>
                                    <th>Correo electrónico</th>
                                    <th>Pagina web</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentListPrviders && currentListPrviders.map(item => (
                                    <tr key={item._id} className="p">
                                        <td><Button variant="outline-dark"
                                            onClick={() => getSupplier(item)}
                                        ><MdOutlineModeEditOutline /></Button></td>
                                        <td><Button variant="outline-danger"
                                            onClick={() => deleteProduct(item._id)}
                                        ><MdDelete /></Button></td>
                                        <td>{item.identification}</td>
                                        <td>{item.name}</td>
                                        <td>{item.direction}</td>
                                        <td>{item.phone}</td>
                                        <td>{item.email}</td>
                                        <td>{item.webPage}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <div className='row'>
                            <div className="col-sm-8">
                                <Pagination className="paginationInfo">
                                    <Pagination.Prev
                                        onClick={() => paginate(currentPage - 1)}
                                        disabled={currentPage === 1}
                                    />
                                    {createPaginationItem(1)}
                                    {totalPages > 1 && (<>
                                        {createListPagination()}
                                        {createPaginationItem(totalPages)}
                                    </>)}
                                    <Pagination.Next
                                        onClick={() => paginate(currentPage + 1)}
                                        disabled={currentPage === totalPages}
                                    />
                                </Pagination>
                            </div>
                            <div className="col-sm-2">
                                <select
                                    defaultValue="5"
                                    onChange={(event) => { setPerPage(event.target.value); paginate(1) }}
                                    value={PerPage}
                                >
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                            <div className="col-sm-2">
                                Total de proveedores: {providers.length}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*Modal para crear un nuevo proveedor*/}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {showModalUpdate ? "Actualizar Proveedor" : "Añadir Proveedor"}
                    </Modal.Title>
                </Modal.Header>
                <Formik
                    validate={() => {
                        setValidated(false)
                        let errores = {};
                        // Validacion nombre
                        if (!name) {
                            errores.name = '*Por favor ingresa una nombre. Campo obligatorio*'
                        } else if (!/^[\wÀ-ÿ\s,"-.:-;+*~´°!"#$%&/()=?|¬¨^`<>]{1,100}$/.test(name)) {
                            errores.name = 'El nombre no es correcto, no puede sobre pasar los 100 caracteres'
                        }

                        // Validacion price
                        if (webPage.length > 0) {
                            if (!/https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}/.test(webPage)) {
                                errores.webPage = 'La pagina web no es incorrecta. Ejemplo: www.test.com'
                            }
                        }

                        // Validacion costo
                        if (email.length > 0) {
                            // Validacion address
                            if (!/^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(email)) {
                                errores.email = 'El correo electrónico es incorrecta. Ejemplo: usuario@email.com'
                            }
                        }
                        if (!/^[\wÀ-ÿ\s,"-.:-;+*~´°!"#$%&/()=?|¬¨^`<>]*$/.test(address)) {
                            errores.address = 'La dirección no es incorrecta'
                        }

                        if (errores) {
                            setValidated(true)
                        }
                        return errores;
                    }}
                >
                    {({
                        handleBlur,
                        touched,
                        errors,
                    }) => (
                        <Form >
                            <Modal.Body className="mb-3">
                                <h6>El nombre es obligatorio</h6>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="6" >
                                        <Form.Label>Nombre del proveedor</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="name"
                                            name="name"
                                            placeholder="Nombre del proveedor"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            onBlur={handleBlur}
                                            isValid={touched.name && !errors.name}
                                            isInvalid={!!errors.name}
                                        />
                                        <Form.Control.Feedback type="invalid" >
                                            {errors.name}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="6">
                                        <Form.Label>RUC o Cédula del proveedor</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="identification"
                                            name="identification"
                                            placeholder="RUC o Cédula del proveedor"
                                            value={identification}
                                            onChange={(e) => setIdentification(e.target.value)}
                                            onBlur={handleBlur}
                                            isValid={touched.identification && !errors.identification}
                                            isInvalid={!!errors.identification}
                                        />
                                        <Form.Control.Feedback type="invalid" >
                                            {errors.identification}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="12">
                                        <Form.Label>Telefono celular</Form.Label>
                                        <PhoneInput
                                            placeholder="Ingrese el número de telefono"
                                            defaultCountry="EC"
                                            value={phone}
                                            onChange={setPhone} />
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="6">
                                        <Form.Label>Página web</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="webPage"
                                            name="webPage"
                                            placeholder="Página web"
                                            value={webPage}
                                            onChange={(e) => setWebPage(e.target.value)}
                                            onBlur={handleBlur}
                                            isValid={touched.webPage && !errors.webPage}
                                            isInvalid={!!errors.webPage}
                                        />
                                        <Form.Control.Feedback type="invalid" >
                                            {errors.webPage}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="6">
                                        <Form.Label>Correo electrónico</Form.Label>
                                        <Form.Control
                                            type="email"
                                            id="email"
                                            name="email"
                                            placeholder="email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            onBlur={handleBlur}
                                            isValid={touched.email && !errors.email}
                                            isInvalid={!!errors.email}
                                        />
                                        <Form.Control.Feedback type="invalid" >
                                            {errors.email}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="12">
                                        <Form.Label>Dirección</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            id="ad"
                                            name="address"
                                            placeholder="Dirección"
                                            value={address}
                                            onChange={(e) => setAddress(e.target.value)}
                                            onBlur={handleBlur}
                                            isValid={touched.address && !errors.address}
                                            isInvalid={!!errors.address}
                                        />
                                        <Form.Control.Feedback type="invalid" >
                                            {errors.address}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Cancelar
                                </Button>
                                <Button variant="primary"
                                    onClick={showModalUpdate ? updateProvider : addProvider}
                                    disabled={!validated}>
                                    Guardar
                                </Button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </div >
    )
}
export default Provider;
