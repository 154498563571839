import React, { useEffect, useState } from 'react'
import Table from "react-bootstrap/Table";
import api from "../services/api";

const ListStockRoom = () => {
    const [productos, setPropductos] = useState([]);
    useEffect(() => {
        getProdductos();
    }, [])
    const getProdductos = async () => {
        await api.get('/stockRoom/').then((res) => {
            setPropductos(res.data.stockRoom);
        }).catch((err) => {
            console.log(err);
        });
    }
    return (
        <div className='Contenedor'>
            <div className='row'>
                <div className='col-sm-12'><h3>Lista de Ingreso a Bodega</h3></div>
                <div className='col-sm-12'>
                    <div className='cuerpo-table'>
                        <Table hover responsive size="lg">
                            <thead>
                                <tr className="h6">
                                    <th>Número de Orden</th>
                                    <th>Producto</th>
                                    <th>Estado</th>
                                    <th>Número de Serie</th>
                                    <th>SKU</th>
                                    <th>Observaciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {productos && productos.map(item => (
                                    <>
                                        {item.products && item.products.map(producto => (
                                            <tr >
                                                <td>{item.orderNumber}</td>
                                                <td>{producto.name}</td>
                                                <td>{producto.state}</td>
                                                <td>{producto.serialNumber}</td>
                                                <td>{producto.sku}</td>
                                                <td>{producto.observations}</td>
                                            </tr>
                                        )
                                        )}
                                    </>
                                ))}
                            </tbody>

                        </Table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ListStockRoom