import React, { useState, useEffect } from 'react';
import api from "../../services/api";
import { Row, Col, Table } from 'react-bootstrap';


const VistaWeb = ({ id }) => {
    const [purchaseOrder, setPurchaseOrder] = useState({});
    const [total, setTotal] = useState(0);
    const [subTotal, setSubTotal] = useState(0);
    const [igv, setIgv] = useState(0);
    //Efffect para obtener la orden de compra
    useEffect(() => {
        getPurchaseOrder();
    }, [])

    //Funcion para obtener las ordenes de compra
    const getPurchaseOrder = async () => {
        await api.get('/purchaseOrder/getPurshaseOrderById/' + id).then((res) => {
            setPurchaseOrder(res.data.purchaseOrder);
            calcularTotal(res.data.purchaseOrder.products);
        }).catch((err) => {
            console.log(err)
        });
    }
    const calcularTotal = (arrayProductos) => {
        let t = 0;
        let st = 0;
        let imp = 0;

        if (arrayProductos.length > 0) {

            arrayProductos.forEach((p) => {
                t = p.totalPrice + t
            })

            st = t / (1.15)
            imp = t - st
        }

        setSubTotal(st.toFixed(2))
        setIgv(imp.toFixed(2))
        setTotal(t.toFixed(2))
    }

    return (
        <div className='container'>
            <Row>
                <img
                    src="https://picsum.photos/600/400" alt="Company logo" height={100} width={100}
                />
            </Row>
            <Row>
                <Col sm={4}>Orden de Compra</Col>
                <Col sm={8}></Col>
            </Row>
            <Row>
                <Col sm={9}></Col>
                <Col sm={1}>No:</Col>
                <Col sm={2}>5655</Col>
            </Row>

            <Row>
                <Col sm={4}>Condicones de adquisión:</Col>
                <Col sm={8}></Col>
            </Row>
            <Row>
                <Col sm={4}>Proveedor:</Col>
                <Col sm={8}>{purchaseOrder?.supplier?.name}</Col>
            </Row>
            <Row>
                <Col sm={4}>Fecha de solicitud:</Col>
                <Col sm={8}>{((new Date(purchaseOrder.applicationDate).getMonth() > 8) ? (new Date(purchaseOrder.applicationDate).getMonth() + 1) : ('0' + (new Date(purchaseOrder.applicationDate).getMonth() + 1))) + '/' + ((new Date(purchaseOrder.applicationDate).getDate() > 9) ? new Date(purchaseOrder.applicationDate).getDate() : ('0' + new Date(purchaseOrder.applicationDate).getDate())) + '/' + new Date(purchaseOrder.applicationDate).getFullYear()}
                </Col>
            </Row>
            <Row>
                <Col sm={4}>Fecha de entrega:</Col>
                <Col sm={8}>{((new Date(purchaseOrder.deliveryDate).getMonth() > 8) ? (new Date(purchaseOrder.deliveryDate).getMonth() + 1) : ('0' + (new Date(purchaseOrder.deliveryDate).getMonth() + 1))) + '/' + ((new Date(purchaseOrder.deliveryDate).getDate() > 9) ? new Date(purchaseOrder.deliveryDate).getDate() : ('0' + new Date(purchaseOrder.deliveryDate).getDate())) + '/' + new Date(purchaseOrder.applicationDate).getFullYear()}</Col>
            </Row>
            <Row>
                <Col sm={4}>Condiciones de pago::</Col>
                <Col sm={8}>{purchaseOrder?.paymentConditions}</Col>
            </Row>
            <Row>
                <Col sm={4}>Condiciones de entrega:</Col>
                <Col sm={8}>{purchaseOrder?.placeDelivery}</Col>
            </Row>
            <Row>
                <Col sm={4}>Garantía(meses):</Col>
                <Col sm={8}>{purchaseOrder?.warranty}</Col>
            </Row>
            <Row>
                <Col sm={4}>Dirección de envío:</Col>
                <Col sm={8}>{purchaseOrder?.shippingAddress}</Col>
            </Row>
            <Row>
                <Col sm={4}>Correo de envío:</Col>
                <Col sm={8}>{purchaseOrder?.shippingMail}</Col>
            </Row>
            <Table bordered responsive>
                <thead>
                    <tr>
                        <th>ITEM</th>
                        <th>CANTIDAD</th>
                        <th>PRODUCTO</th>
                        <th>PRECIO UNITARIO</th>
                        <th>PRECIO TOTAL</th>
                    </tr>
                </thead>
                <tbody>
                    {purchaseOrder?.products?.map((product, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{product.amount}</td>
                            <td>{product.name}</td>
                            <td>{product.unitPrice}</td>
                            <td>{(product.amount * product.unitPrice).toFixed(2)}</td>
                        </tr>
                    ))}
                    <tr>
                        <td colSpan={5}>SUBTOTAL</td>
                        <td>{Number.parseFloat(subTotal).toFixed(2)}</td>
                    </tr>
                    <tr>
                        <td colSpan={5}>IMPUESTOS (15%)</td>
                        <td>{Number.parseFloat(igv).toFixed(2)}</td>
                    </tr>
                    <tr>
                        <td colSpan={5}>TOTAL</td>
                        <td>{Number.parseFloat(total).toFixed(2)}</td>
                    </tr>
                </tbody>
            </Table>
        </div>
    )
}

export default VistaWeb