import React, {useState, useEffect} from "react";
import './Sidebar.css';
import { SiderbarData } from './SidebarData'
import Cookies from 'universal-cookie';

const cookies = new Cookies();

function Sidebar() {
    //Para ocultar el sidebar antes de iniciar
    const [ show, setShow ] = useState(true);

    useEffect(() => {
        if (cookies.get('token')){
            setShow(false)
        }
    }, [])
    return (
        <div className="Sidebar" hidden={show}>
            <ul className="SidebarList">
                {SiderbarData.map((val, key) => {
                    return (
                        <li 
                        key={key} 
                        className="rowSidebar"
                        id={window.location.pathname === val.link ? "active" : ""}
                        onClick={() => { window.location.pathname = val.link }}>
                    {" "}
                    <div>{val.icon}</div>{" "}
                    <div>
                        {val.title}
                    </div>
                </li>
                );
                })}
            </ul>
        </div>
    );
}
export default Sidebar;