import React from 'react';
import { Sidebar, Menu, MenuItem, useProSidebar, SubMenu, menuClasses, MenuItemStyles } from "react-pro-sidebar";
import DashboardIcon from '@mui/icons-material/Dashboard';
import AbcIcon from '@mui/icons-material/Abc';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import InventoryIcon from '@mui/icons-material/Inventory';
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";
import { Link } from 'react-router-dom';

const Layout = () => {
    const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } = useProSidebar();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [hasImage, setHasImage] = React.useState(false);

    return (
        <Sidebar
            breakPoint="md"
            transitionDuration={800}
            style={{ height: "100vh" }}
            defaultCollapsed
            backgroundColor={colors.grey}
            onMouseEnter={() => collapsed && collapseSidebar()}
            onMouseLeave={() => !collapsed && collapseSidebar()}
        >
            <Menu color="black">
                <div style={{ height: "64px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <img src="https://scandere.net/static/media/logo.03a4efd1064d784486d2.png" height="auto" width="100%" alt="Logo" />
                </div>
                <MenuItem icon={<DashboardIcon />} component={<Link to="/dashboard" />}>Dashboard</MenuItem>
                <SubMenu icon={<InventoryIcon />} label="Inventario">
                    <MenuItem style={{
                        background: colors.grey,
                    }}
                        component={<Link to="/products" />}
                    > Productos</MenuItem>
                    <MenuItem style={{
                        background: colors.grey,
                    }}
                        component={<Link to="/collections" />}
                    > Colecciones</MenuItem>
                    <MenuItem
                        component={<Link to="/products/providers" />}
                    > Proveedores</MenuItem>
                    <MenuItem
                        component={<Link to="/listPurchaseOrder" />}
                    > Orden de Compra</MenuItem>
                </SubMenu >
                <MenuItem icon={<AbcIcon />} component={<Link to="/clasificacion" />}>Clasificación ABC</MenuItem>
                <MenuItem icon={<CoPresentIcon />} component={<Link to="/productStudy" />}>Estudio de Producto</MenuItem>
            </Menu>
        </Sidebar>
    )
}

export default Layout