import ListCategorias from "../Components/Pages/ListCategorias";
import React, { useState, useEffect } from 'react';
import Swal from "sweetalert2";
import api from "../services/api";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const Dashboard = () => {
    const [permisosProductos, setPermisosProductos] = useState(0);
    const [permisosClasificacionABC, setPermisosClasificacionABC] = useState(0);

    //Funcion para obtener los permisos del usuario
    const getPermisos = async () => {
        const res = await api.get('/permisos/usuario/' + cookies.get("idPerfil"))
        res.data.permisos.forEach(element => {
            if (element.name === "Products") {
                setPermisosProductos(element.permisos)
            } else if (element.name === "ClasificacionABC") {
                setPermisosClasificacionABC(element.permisos)
            }
        });
    }

    useEffect(() => {
        const verificarProductos = async () => {
            const res = await api.get('/products/')
            if (!res.data.length) {
                if (permisosProductos === 2 || permisosProductos === 3 || permisosProductos > 5) {
                    Swal.fire({
                        icon: 'warning',
                        title: "No hay productos almacenados",
                        text: 'Debes agregar productos para comenzar',
                        showConfirmButton: true,
                    }).then(async () => {
                        window.location.href = '/products'
                    }
                    )
                }
            } else {
                if (permisosClasificacionABC === 2) {
                    const res1 = await api.get('/configuracion/')
                    if (res1.data.length === 0) {
                        Swal.fire({
                            icon: 'warning',
                            title: "No hay una configuracion de los productos",
                            text: 'Debes crear una configuracion de clasificación de los productos para comenzar',
                            showConfirmButton: true,
                        }).then(async () => {
                            window.location.href = '/clasificacion'
                        })
                    }
                }
            }
        }
        getPermisos();
        verificarProductos();
    }, [permisosClasificacionABC, permisosProductos])

    return (
        <Box container spacing={2}>
            <Grid container spacing={1}>
                <Grid item lg={12} md={12}>
                    <h2>Proximas compras</h2>
                </Grid>
                <Grid item lg={4} md={6} sm={4} xs={12}>
                    <ListCategorias clasificacion={"A"} />
                </Grid>
                <Grid item lg={4} md={6} sm={4} xs={12}>
                    <ListCategorias clasificacion={"B"} />
                </Grid>
                <Grid item lg={4} md={6} sm={4} xs={12}>
                    <ListCategorias clasificacion={"C"} />
                </Grid>
            </Grid>
        </Box>

    )
}
export default Dashboard 