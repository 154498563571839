import React, { useState } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import Card from '../FormElements/Card'
import { MdDelete, MdOutlineModeEditOutline } from 'react-icons/md';
import Swal from 'sweetalert2';
import api from "../../services/api";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Formik } from 'formik';
import { useNavigate } from 'react-router';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import './pages.css';


const ProductsCollectionList = props => {
    const [idProducto, setIdProducto] = useState("");
    const [image, setImage] = useState({ preview: '', data: '', valido: true });
    const [name, setName] = useState("");
    const [sku, setSKU] = useState("");
    const [cost, setcost] = useState(0);
    const [price, setPrice] = useState(0);
    const [stock, setStock] = useState(0);
    const [colecciones, setColecciones] = useState("");
    const [observaciones, setObservaciones] = useState("");
    const [modelo, setModelo] = useState("");
    const [listaColecciones, setListaColecciones] = useState([])
    //Valida el formulario
    const [validated, setValidated] = useState(false);
    const navigate = useNavigate()

    //funcion para obtener un product. Al presionar el boton edit pasa el producto de la fila seleccionada para obtner el 
    //producto y mostralo en el modal
    const getProduct = async (item) => {
        console.log(item)
        if (props.permisos === 2 || props.permisos === 3 || props.permisos === 6 || props.permisos === 7) {
            //Mostrar el modal
            const img = {
                preview: item.image,
                data: '',
                valido: true
            }
            setImage(img)
            setIdProducto(item._id)
            setName(item.name)
            setSKU(item.sku)
            setPrice(item.price)
            setcost(item.cost)
            setStock(item.stock)
            setColecciones(item.collectionName)
            setModelo(item.modelo)
            setObservaciones(item.observations)
            handleShowEdit(true)
        } else {
            //Alerta de error por no tener permiso
            Swal.fire({
                icon: 'error',
                title: "No tienes permiso",
                showConfirmButton: false,
                timer: 2000
            })
        }
    }
    //Modal Edit
    const [showEdit, setShowEdit] = useState(false);
    //Handle del modal edit
    const handleCloseEdit = () => setShowEdit(false);
    const handleShowEdit = () => {
        getCollections();
        setValidated(false)
        setShowEdit(true);
    }
    //Funcion para agregar los productos
    const getCollections = async () => {
        const res = await api.get('/collections/')
        setListaColecciones(res.data)
    }
    //Funcion para eliminar un producto 
    const deleteProduct = async (item) => {
        if (props.permisos === 1 || props.permisos === 3 || props.permisos === 5 || props.permisos === 7) {
            Swal.fire({
                title: '¿Estás seguro de eliminar el producto?',
                text: "¡No podrás revertirlo!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const res = await api.delete('/products/deleteProduct/' + item._id)
                    if (res.data.status) {
                        //Refresacar la pagina
                        navigate(0)
                        //Alerta de editado
                        Swal.fire({
                            icon: 'success',
                            title: res.data.message,
                            showConfirmButton: false,
                            timer: 2000
                        })
                    } else {
                        //logs de error
                        const mensaje = {
                            message: "Error al eliminar un producto",
                            level: 3
                        }
                        await api.post('/logger', mensaje)
                        ///Mensaje de error
                        Swal.fire({
                            icon: 'error',
                            title: res.data.message,
                            showConfirmButton: false,
                            timer: 2000
                        })
                    }
                }
            });
        } else {
            //Alerta de error por no tener permiso
            Swal.fire({
                icon: 'error',
                title: "No tienes permiso",
                showConfirmButton: false,
                timer: 2000
            })
        }

    }
    //Funcion que valida la extension del archivo y almmacena su estado
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        let valido = true
        switch (file.type) {
            case 'image/png':
                valido = true
                break;
            case 'image/jpg':
                valido = true
                break;
            case 'image/jpeg':
                valido = true
                break;
            case '':
                valido = true
                break;
            default:
                valido = false
                ///Mensaje de error
                Swal.fire({
                    icon: 'error',
                    title: "Formato de la imagen no válido. Solo .jpeg, .jpg y .png",
                    showConfirmButton: false,
                    timer: 2000
                })
        }
        if (valido) {
            const img = {
                preview: URL.createObjectURL(file),
                data: file,
                valido: valido
            }
            setImage(img)
        }

    }

    //Funcion para actualizar el product
    const updateProduct = async (e) => {
        e.preventDefault();
        const id = idProducto
        let formData = new FormData()
        formData.append('image', image.data)
        formData.append('name', name)
        formData.append('sku', sku)
        formData.append('cost', cost)
        formData.append('price', price)
        formData.append('stock', stock)
        formData.append('coleccion', colecciones)
        formData.append('modelo', modelo)
        formData.append('observaciones', observaciones)
        console.log(colecciones)
        await api.put('/products/updateProduct/' + id, formData).then(async (res) => {
            if (res.data.status) {
                //Refresacar la pagina
                navigate(0);
                //Alerta de actualizado
                Swal.fire({
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 2000
                })
                //Para cerrar el modal
                setShowEdit(false)
            } else {
                //logs de error
                const mensaje = {
                    message: "Error al actualizar un producto",
                    level: 3
                }
                await api.post('/logger', mensaje)
                //Alerta de actualizado
                Swal.fire({
                    icon: 'error',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        }).catch(async (err) => {
            if (err.response.status !== 500) {
                Swal.fire({
                    icon: 'error',
                    title: err.response.data.message || "No se puede eliminar la colección",
                    showConfirmButton: true
                })
            } else {
                //logs de error
                const mensaje = {
                    message: "Error al agregar un producto a partir de un archivo",
                    level: 3
                };
                await api.post('/logger', mensaje);
                ///Mensaje de error
                Swal.fire({
                    icon: 'error',
                    title: mensaje.message,
                    showConfirmButton: true
                })
            }
        })
    }
    if (props.items.length === 0) {
        return (
            <Card>
                <h2>No se encontraron productos. Quizas quieras crear uno</h2>
                <Button href="/products">Crear productos</Button>
            </Card>
        );
    }
    return (
        <div className="listaProductsCollections">
            <strong>Colección: </strong>{props.items[0].collectionName}
            <div>
                <ListGroup>
                    {props.items.map(producto => (
                        <ListGroup.Item key={producto._id}
                            variant="light"
                            className="d-flex justify-content-between align-items-start"
                        >
                            <tbody>
                                <tr key={producto.name}>
                                    <td >
                                        {producto.image === undefined ? <img src="https://reclycar.es/assets/img/default.png" alt="imagen de perfil" height='50' /> : <img src={producto.image} alt="imagen de perfil" height='50' />} {" "}
                                    </td>
                                    <td>
                                        <div className="fw-bold">{producto.name}</div>
                                    </td>
                                    <td >
                                        <Button variant="outline-dark" onClick={() => getProduct(producto)} ><MdOutlineModeEditOutline /></Button>
                                    </td>
                                    <td >
                                        <Button variant="outline-danger" onClick={() => deleteProduct(producto)}><MdDelete /></Button>
                                    </td>
                                </tr>
                            </tbody>
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            </div>
            <Modal show={showEdit} onHide={handleCloseEdit} size="lg" >
                <Modal.Header closeButton>
                    <Modal.Title><h3>Editar Producto</h3></Modal.Title>
                </Modal.Header>
                <Formik
                    validate={() => {
                        let errores = {};
                        // Validacion nombre
                        if (!name) {
                            errores.name = '*Por favor ingresa una nombre. Campo obligatorio*'
                        } else if (!/^[\wÀ-ÿ\s,"-.:-;+*~´°!"#$%&/()=?|¬¨^`<>]{1,100}$/.test(name)) {
                            errores.name = 'El nombre no es correcto, no puede sobre pasar los 100 caracteres'
                        }
                        // Validacion SKU
                        if (!/^[\wÀ-ÿ\s,"-.:-;+*~´°!"#$%&/()=?|¬¨^`<>]*$/.test(sku)) {
                            errores.sku = 'El SKU no puede tener espacios'
                        }
                        // Validacion price
                        if (!/^\d*(?:\.{0,1}\d{0,4})?$/.test(price)) {
                            errores.price = 'El precio es un número entero. Ejemplo: 100.00. No obligatorio.'
                        }
                        // Validacion costo
                        if (!/^\d*(?:\.{0,1}\d{0,4})?$/.test(cost)) {
                            errores.cost = 'El precio es un número entero. Ejemplo: 145.00. No obligatorio.'
                        }
                        // Validacion stock
                        if (!/^[0-9]*$/.test(stock)) {
                            errores.stock = 'El stock es un número entero. Ejemplo: 20. No obligatorio.'
                        }
                        // Validacion modelo
                        if (!/^[\wÀ-ÿ\s,"-.:-;+*~´°!"#$%&/()=?|¬¨^`<>]*$/.test(modelo)) {
                            errores.modelo = 'La colección es incorrecta'
                        }
                        if (!/^[\wÀ-ÿ\s,"-.:-;+*~´°!"#$%&/()=?|¬¨^`<>]*$/.test(observaciones)) {
                            errores.observaciones = 'La observación es incorrecta'
                        }
                        if (!image.valido) {
                            errores.file = 'La tipo de archivo es incorrecto. Solo se permite tipo .jpg, .png y jpeg'
                        }

                        if (!errores.name) {
                            setValidated(true)
                        }
                        return errores;
                    }}
                >
                    {({
                        handleBlur,
                        touched,
                        errors,
                    }) => (
                        <Form>
                            <Modal.Body>
                                <h6>*Los campos nombre y colecciones son obligatorios*</h6>
                                <Row className="mb-3">
                                    <Form.Group className="position-relative mb-3">
                                        <Form.Label>Imagen de Perfil</Form.Label>
                                        {image.preview && <img src={image.preview} alt="imagen de perfil" width='100' height='100' />}
                                        <IconButton color="primary" aria-label="upload picture" component="label">
                                            <input hidden accept="image/png, image/jpeg, image/jpg" onChange={handleFileChange} type="file" />
                                            <PhotoCamera />
                                        </IconButton>
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="6" >
                                        <Form.Label>Nombre</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="name"
                                            name="name"
                                            placeholder="Nombre"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            onBlur={handleBlur}
                                            isValid={touched.name && !errors.name}
                                            isInvalid={!!errors.name}
                                        />
                                        <Form.Control.Feedback type="invalid" >
                                            {errors.name}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="6">
                                        <Form.Label>SKU</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="sku"
                                            name="sku"
                                            placeholder="SKU"
                                            value={sku == null ? "" : sku}
                                            onChange={(e) => setSKU(e.target.value)}
                                            onBlur={handleBlur}
                                            isValid={touched.sku && !errors.sku}
                                            isInvalid={!!errors.sku}
                                        />
                                        <Form.Control.Feedback type="invalid" >
                                            {errors.sku}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="4">
                                        <Form.Label>Costo</Form.Label>
                                        <Form.Control
                                            type="number"
                                            id="cost"
                                            name="cost"
                                            placeholder="Costo"
                                            value={cost == null ? 0 : cost}
                                            onChange={(e) => setcost(e.target.value)}
                                            onBlur={handleBlur}
                                            isValid={touched.cost && !errors.cost}
                                            isInvalid={!!errors.cost}
                                        />
                                        <Form.Control.Feedback type="invalid" >
                                            {errors.cost}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4">
                                        <Form.Label>Precio</Form.Label>
                                        <Form.Control
                                            type="number"
                                            id="price"
                                            name="price"
                                            placeholder="Precio"
                                            value={price == null ? "0" : price}
                                            onChange={(e) => setPrice(e.target.value)}
                                            onBlur={handleBlur}
                                            isValid={touched.price && !errors.price}
                                            isInvalid={!!errors.price}
                                        />
                                        <Form.Control.Feedback type="invalid" >
                                            {errors.price}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4">
                                        <Form.Label>Stock</Form.Label>
                                        <Form.Control
                                            type="number"
                                            id="stock"
                                            name="stock"
                                            placeholder="Stock"
                                            value={stock == null ? "0" : stock}
                                            onChange={(e) => setStock(e.target.value)}
                                            onBlur={handleBlur}
                                            isValid={touched.stock && !errors.stock}
                                            isInvalid={!!errors.stock}
                                        />
                                        <Form.Control.Feedback type="invalid" >
                                            {errors.stock}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col}>
                                        <Form.Label>Colección</Form.Label>
                                        <Form.Select
                                            as="select"
                                            id="colecciones"
                                            name="colecciones"
                                            value={colecciones}
                                            onChange={(e) => setColecciones(e.target.value)}
                                            onBlur={handleBlur}
                                            isValid={touched.colecciones && !errors.colecciones}
                                            isInvalid={!!errors.colecciones}
                                        >
                                            {listaColecciones.map(item => (
                                                <option value={item.name} key={item._id} >{item.name}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="12">
                                        <Form.Label>Observación</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            id="observaciones"
                                            name="observaciones"
                                            placeholder="observaciones"
                                            value={observaciones}
                                            onChange={(e) => setObservaciones(e.target.value)}
                                            onBlur={handleBlur}
                                            isValid={touched.observations && !errors.observations}
                                            isInvalid={!!errors.observations}
                                        />
                                        <Form.Control.Feedback type="invalid" >
                                            {errors.observations}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleCloseEdit}>
                                    Cancelar
                                </Button>
                                <Button variant="primary" onClick={updateProduct} disabled={!validated}>
                                    Guardar
                                </Button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </div>

    );
};

export default ProductsCollectionList