import React, { useState } from "react";
import './Auth.css';
import Swal from 'sweetalert2'
import Cookies from 'universal-cookie';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import api from "../../services/api";
// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import KeyIcon from '@mui/icons-material/Key';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import AccountCircle from '@mui/icons-material/AccountCircle';

import {
    IconButton,
    InputAdornment
} from '@mui/material';
let timerInterval

//Cookies para mantener el token de la sesion
const cookies = new Cookies();

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    //Submit
    const authSubmitHandler = async event => {
        event.preventDefault();
        try {

            const user = { email, password }
            await api.post('/user/login/', user).then(async (res) => {
                const mensaje = res.data.status
                if (mensaje !== 'Bienvenido') {
                    if (mensaje === "Estas bloqueado por 30 segundos") {
                        Swal.fire({
                            title: '¡Estás bloqueado por 30 segundos!',
                            html:
                                'Espera <strong></strong> segundos para volver a intentar.<br/><br/>',
                            timer: 30000,
                            didOpen: () => {
                                Swal.showLoading()
                                timerInterval = setInterval(() => {
                                    Swal.getHtmlContainer().querySelector('strong')
                                        .textContent = (Swal.getTimerLeft() / 1000)
                                            .toFixed(0)
                                }, 100)
                            },
                            willClose: () => {
                                clearInterval(timerInterval)
                            }
                        })

                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: mensaje,
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
                } else {
                    cookies.set('refreshToken', res.data.refreshToken, { path: "/" })
                    cookies.set('idUsuario', res.data.id, { path: "/" })
                    cookies.set('name', res.data.name, { path: "/" })
                    cookies.set('email', res.data.email, { path: "/" })
                    cookies.set('token', res.data.token, { path: "/" })
                    const idPerfil = res.data.perfil
                    cookies.set('idPerfil', idPerfil, { path: "/" })
                    const perfil = await api.get('/perfil/' + idPerfil, user);
                    cookies.set('perfil', perfil.data.perfil.name, { path: "/" })
                    cookies.set('role', res.data.role, { path: "/" })
                    Swal.fire({
                        icon: 'susccess',
                        title: mensaje,
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        timer: 2000
                    }).then(async () => {
                        window.location.href = '/dashboard'
                    })
                }
            }).catch((err) => {
                Swal.fire({
                    icon: 'error',
                    title: err.response.data.message,
                    showConfirmButton: false,
                    timer: 1500
                })
            })

        } catch (err) { }
    };

    return (
        <div className="Auth-form-container">
            <Form className="Auth-form" onSubmit={authSubmitHandler}>
                <div className="Auth-form-content">
                    <h3 className="Auth-form-title">Ingresar</h3>
                    <FormControl variant="standard" sx={{ m: 1, width: '100%' }}>
                        <InputLabel htmlFor="input-with-icon-adornment">
                            Correo electrónico
                        </InputLabel>
                        <Input
                            id="input-with-icon-adornment"
                            type="email"
                            startAdornment={
                                <InputAdornment position="start">
                                    <AccountCircle />
                                </InputAdornment>
                            }
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </FormControl>
                    <FormControl sx={{ m: 1, width: '100%' }} variant="standard">
                        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                        <Input
                            id="outlined-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            onChange={(e) => setPassword(e.target.value)}
                            startAdornment={
                                <InputAdornment position="start">
                                    <KeyIcon />
                                </InputAdornment>
                            }
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Password"
                        />
                    </FormControl>
                    <div className="d-grid gap-2 mt-3">
                        <Button type="submit" >
                            Ingresar
                        </Button>
                    </div>
                    <p className="forgot-password text-right mt-2">
                        ¿Olvidaste tu <a href="/auth">contraseña?</a>
                    </p>
                </div>
            </Form >
        </div >
    )
}
export default Login