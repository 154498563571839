import React, { useState, useEffect } from 'react';
import './styles.css'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import { MdDelete, MdOutlineModeEditOutline } from 'react-icons/md'
import api from "../services/api";
import Swal from 'sweetalert2' //npm install sweetalert2
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Formik } from 'formik';
import Cookies from "universal-cookie";

const cookies = new Cookies();

function AdminUsers() {
    //Valadida el formulario
    const [validated, setValidated] = useState(false);
    //Hooks para agregar y listar los prdouctos
    const [listaUsuarios, setListaUsuarios] = useState([])
    const [listaPerfiles, setListaPerfiles] = useState([])
    const [idUser, setIdUseruser] = useState("")
    const [name, setName] = useState("")
    const [password, setPassword] = useState("")
    const [image, setImage] = useState({ preview: '', data: '', valido: true })
    const [email, setEmail] = useState("")
    const [role, setRole] = useState("")
    const [perfil, setPerfil] = useState("")

    //Modal Agregar
    const [show, setShow] = useState(false);
    //Handle del modal add
    const handleClose = () => setShow(false);
    const handleShow = () => {
        const img = {
            preview: '',
            data: '',
            valido: true
        }
        setImage(img)
        setName("")
        setEmail("")
        setPerfil("Administrador")
        setPassword("")
        setRole("")
        setImage(img)
        setValidated(false)
        setShow(true)
    };

    //Modal Edit
    const [showEdit, setShowEdit] = useState(false);
    //Handle del modal edit
    const handleCloseEdit = () => setShowEdit(false);
    const handleShowEdit = () => {
        setValidated(false)
        setShowEdit(true);
    }

    //Efffect para obtener productos
    useEffect(() => {
        getUsuarios()
        getPerfiles()
    }, [])

    //Funcion para agregar los productos
    const getUsuarios = async () => {
        const res = await api.get('/user/')
        setListaUsuarios(res.data)
    }
    const getPerfiles = async () => {
        const res = await api.get('/perfil/')
        setListaPerfiles(res.data)
    }
    //Funcion para agregar los productos 
    const addUser = async () => {
        let formData = new FormData()
        formData.append('image', image.data)
        formData.append('name', name)
        formData.append('email', email)
        formData.append('password', password)
        formData.append('perfil', perfil)
        formData.append('role', role)
        const res = await api.post('/user/crear', formData)
        const msg = res.data.status
        if (msg !== 'Bienvenido') {
            //logs de error al backend
            const mensaje = {
                message: "Error al añadir un usuario",
                level: 3
            }
            await api.post('/logger', mensaje)
            ///Mensaje de error
            Swal.fire({
                icon: 'error',
                title: msg,
                showConfirmButton: false,
                timer: 1500
            })
        } else {
            Swal.fire({
                icon: 'success',
                title: "Registrado!!",
                showConfirmButton: false,
                timer: 1500
            })
            //Refresacar la pagina
            getUsuarios()
            //Para cerrar el modal
            setShow(false)
        }
    }
    //funcion para obtener un product. Al presionar el boton edit pasa el producto de la fila seleccionada para obtner el producto y mostralo en el modal
    const getUser = async (item) => {
        const img = {
            preview: item.image,
            data: item.image,
            valido: true
        }
        setImage(img)
        setIdUseruser(item._id)
        setName(item.name)
        setEmail(item.email)
        setImage(img)
        setPerfil(item.perfil)
        setRole(item.role)
        //Mostrar el modal
        handleShowEdit(true)
    }
    //Funcion para actualizar el product
    const updateUser = async (e) => {
        e.preventDefault();
        const id = idUser
        const form = new FormData();
        form.append('image', image.data)
        form.append('name', name)
        form.append('email', email)
        form.append('perfil', perfil)
        form.append('role', role)
        const res = await api.put('/user/update/' + id, form)
        const msg = res.data.status
        //Alerta de editado
        if (msg !== 'Usuario actualizado') {
            //logs de error al backend
            const mensaje = {
                message: "Error al actualizar un usuario",
                level: 3
            }
            await api.post('/logger', mensaje)
            ///Mensaje de error
            Swal.fire({
                icon: 'error',
                title: msg,
                showConfirmButton: false,
                timer: 1500
            })
        } else {
            Swal.fire({
                icon: 'success',
                title: msg,
                showConfirmButton: false,
                timer: 1500
            })
            //Refresacar la pagina
            getUsuarios()
            //Para cerrar el modal
            setShowEdit(false)
        }

    }
    const handleFileChange = (e) => {
        const img = {
            preview: URL.createObjectURL(e.target.files[0]),
            data: e.target.files[0],
        }
        setImage(img)
    }

    //Funcion para eliminar un producto 
    const deleteUser = async (id) => {
        Swal.fire({
            title: '¿Estás seguro de eliminar el usuario?',
            text: "¡No podrás revertirlo!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const res = await api.delete('/user/deleteUser/' + id)
                const msg = res.data.status
                //Alerta de eliminado
                if (msg !== 'Usuario eliminado') {
                    //logs de error al backend
                    const mensaje = {
                        message: "Error al eliminar un usuario",
                        level: 3
                    }
                    await api.post('/logger', mensaje)
                    ///Mensaje de error
                    Swal.fire({
                        icon: 'error',
                        title: msg,
                        showConfirmButton: true,
                    })
                } else {
                    Swal.fire({
                        icon: 'success',
                        title: msg,
                        showConfirmButton: false,
                        timer: 1500
                    })
                    //Refresacar la pagina
                    getUsuarios()
                }
            }
        })
    }
    const changePassword = async (item) => {
        Swal.fire({
            title: "Cambiar contraseña",
            html: `<input type="password" id="password" class="swal2-input" placeholder="Contraseña">
                <input type="password" id="re_password" class="swal2-input" placeholder="Repetir contaseña">`,
            confirmButtonText: 'Sign in',
            focusConfirm: false,
            showCancelButton: true,
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Volver',
            showLoaderOnConfirm: true,
            preConfirm: () => {
                const password = Swal.getPopup().querySelector('#password').value
                const repassword = Swal.getPopup().querySelector('#re_password').value
                if (!password || !repassword) {
                    Swal.showValidationMessage(`Por favor, ingresa todos los datos`)
                } else if (password !== repassword) {
                    Swal.showValidationMessage(`Las Contraseñas no coinciden, vuelve a intentar`)
                }
                return { password: password, repassword: repassword }
            },
            allowOutsideClick: () => !Swal.isLoading()

        }).then(async(result) => {
            if (result.isConfirmed) {
                const usuario = {
                    newPassword: result.value.password,
                    idUserAdministrator: cookies.get("idUsuario")
                }
                await api.put('/user/updatePassword/' + item._id, usuario).then((res) => {
                    if(res.data.status){
                        Swal.fire({
                            icon: 'success',
                            title: res.data.message,
                            showConfirmButton: false,
                            timer: 1500
                        });
                    }else {
                        Swal.fire({
                            icon: 'error',
                            title: res.data.message,
                            showConfirmButton: true
                        })
                    }
                    
                }).catch((err) => {
                    Swal.fire({
                        icon: 'error',
                        title: err.response.data.message,
                        showConfirmButton: true
                    })
                })
            } else {
                //etA_Busqueda("")
            }
        })
    }
    return (
        <div className='Contenedor'>
            <div className='encabezado'>
                <h3>Administración - Usuarios</h3>
                <div className='botones'>
                    <a href='/adminProfile'>Ver perfiles</a>
                    {" "}
                    <Button variant="primary" onClick={handleShow}>
                        Añadir usuario
                    </Button>
                </div>
            </div>
            <div className='cuerpo-table'>
                <Table hover responsive>
                    <thead>
                        <tr className="h5">
                            <th></th>
                            <th>Nombre de usuario</th>
                            <th>Correo Electrónico</th>
                            <th>Perfil</th>
                            <th>Roles</th>
                        </tr>
                    </thead>
                    <tbody>
                        {listaUsuarios.map(item => (
                            <tr key={item._id} className="p">
                                <td>{item.image === undefined ? <img src="https://reclycar.es/assets/img/default.png" alt="imagen de perfil" height='50' /> : <img src={item.image} alt="imagen de perfil" height='50' />} {" "}</td>
                                <td>{item.name}</td>
                                <td>{item.email}</td>
                                <td>{item.perfil}</td>
                                <td>{item.role}</td>
                                <td><Button variant="outline-primary" onClick={() => changePassword(item)} size="sm">Cambiar contraseña</Button></td>
                                <td><Button variant="outline-dark" onClick={() => getUser(item)} size="sm"><MdOutlineModeEditOutline /></Button></td>
                                <td><Button variant="outline-danger" onClick={() => deleteUser(item._id)} size="sm"><MdDelete /></Button></td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
            <Modal show={show} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Añadir Usuario</Modal.Title>
                </Modal.Header>
                <Formik
                    validate={() => {
                        setValidated(false)
                        let errores = {};
                        // Validacion nombre
                        if (!name) {
                            errores.name = '*Por favor ingresa una nombre. Campo obligatorio*'
                        } else if (!/^[\wÀ-ÿ\s,"-.:-;+*~´°!"#$%&/()=?|¬¨^`<>]{1,100}$/.test(name)) {
                            errores.name = 'El nombre no es correcto, no puede sobre pasar los 100 caracteres'
                        }
                        // Validacion email
                        if (!email) {
                            errores.email = '*Por favor ingresa una correo electrónico. Campo obligatorio*'
                        } else if (!/^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(email)) {
                            errores.email = 'El correo electrónico no es correcto. No usar acentos o letras latinas'
                        }
                        // Validacion Password
                        if (!password) {
                            errores.password = '*Por favor ingresa una contraseña. Campo obligatorio*'
                        } else if (password.length < 6) {
                            errores.password = 'El contraseña debe ser mayor a 6 caracteres'
                        }
                        if (!errores.name && !errores.email && !errores.password) {
                            setValidated(true)
                        }
                        return errores;
                    }}
                >
                    {({
                        handleBlur,
                        touched,
                        errors,
                    }) => (
                        <Form >
                            <Modal.Body>
                                <Form.Group as={Col} md="12" >
                                    <Form.Group controlId="formFile" className="mb-3">
                                        <Form.Label>Imagen de Perfil</Form.Label>
                                        {image.preview && <img src={image.preview} alt="imagen de perfil" width='100' height='100' />}
                                        <Form.Control
                                            type="file"
                                            name='file'
                                            onChange={handleFileChange}
                                        />
                                    </Form.Group>
                                    <Form.Label>Nombre</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="name"
                                        name="name"
                                        placeholder="Nombre de usuario"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        onBlur={handleBlur}
                                        isValid={touched.name && !errors.name}
                                        isInvalid={!!errors.name}
                                    />
                                    <Form.Control.Feedback type="invalid" >
                                        {errors.name}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="12" >
                                    <Form.Label>Correo electrónico</Form.Label>
                                    <Form.Control
                                        type="email"
                                        id="email"
                                        name="email"
                                        placeholder="Correo electrónico"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        onBlur={handleBlur}
                                        isValid={touched.email && !errors.email}
                                        isInvalid={!!errors.email}
                                    />
                                    <Form.Control.Feedback type="invalid" >
                                        {errors.email}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="12" >
                                    <Form.Label>Contraseña</Form.Label>
                                    <Form.Control
                                        type="password"
                                        id="password"
                                        name="Contraseña"
                                        placeholder="Contraseña"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        onBlur={handleBlur}
                                        isValid={touched.password && !errors.password}
                                        isInvalid={!!errors.password}
                                    />
                                    <Form.Control.Feedback type="invalid" >
                                        {errors.password}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="12" >
                                    <Form.Label>Perfil</Form.Label>
                                    <Form.Select
                                        as="select"
                                        id="perfil"
                                        name="perfil"
                                        onChange={e => setPerfil(e.target.value)}
                                    >
                                        {listaPerfiles.map(item => (
                                            <option value={item.name} key={item._id}>{item.name}</option>

                                        ))}
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} md="12" >
                                    <Form.Label>Role</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="role"
                                        name="role"
                                        placeholder="role"
                                        value={role}
                                        onChange={(e) => setRole(e.target.value)}
                                    />
                                </Form.Group>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Cancelar
                                </Button>
                                <Button variant="primary" onClick={addUser} disabled={!validated}>
                                    Guardar
                                </Button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>
            <Modal show={showEdit} onHide={handleCloseEdit} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title><h3>Editar Usuario</h3></Modal.Title>
                </Modal.Header>
                <Formik
                >
                    {() => (
                        <Form>
                            <Modal.Body>
                                <h6>Nombre: {name}</h6>
                                <h6>Correo electrónico: {email}</h6>
                                <Row className="mb-3">
                                    <Form.Group controlId="formFile" className="mb-3">
                                        <Form.Label>Imagen de Perfil</Form.Label>
                                        {image.preview && <img src={image.preview} alt="imagen de perfil" width='100' height='100' />}
                                        <Form.Control
                                            type="file"
                                            name='file'
                                            onChange={handleFileChange}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" >
                                        <Form.Label>Perfil</Form.Label>
                                        <Form.Select
                                            id="perfil"
                                            name="perfil"
                                            defaultValue={perfil}
                                            onChange={(e) => setPerfil(e.target.value)}
                                        >
                                            {listaPerfiles.map(item => (
                                                <option key={item._id}>{item.name}</option>

                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" >
                                        <Form.Label>Role</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="role"
                                            name="role"
                                            placeholder="Role"
                                            value={role}
                                            onChange={(e) => setRole(e.target.value)}
                                        />
                                    </Form.Group>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleCloseEdit}>
                                    Cancelar
                                </Button>
                                <Button variant="primary" onClick={updateUser}>
                                    Guardar
                                </Button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </div >
    )
}
export default AdminUsers
