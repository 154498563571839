import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import api from "../../services/api";
import Swal from 'sweetalert2';

function ModalAplicarModel({ show, handleClose, clasificacion }) {
    const [listaModelo, setListaModelo] = useState([]);
    const [modelo, setModelo] = useState("");
    useEffect(() => {
        getModelos();
    }, []);

    const getModelos = async () => {
        try {
            const res = await api.get('/models/');
            setListaModelo(res.data);
        } catch (err) {
            console.log(err)
        }
    }
    //Funcion para calcular el modelo
    const aplicarModelo = async () => {
        if (!modelo) {
            Swal.fire({
                icon: 'error',
                title: "Escoga un modelo",
                showConfirmButton: false
            })
        } else {
            Swal.fire({
                position: 'center',
                title: 'Espera un momento...',
                showConfirmButton: false,
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading()
                },
            })
            await api.put('/products/putModenOnClasification/' + clasificacion + '/' + modelo)
                .then(resp => {
                    Swal.close();
                    if (resp.data.status) {
                        Swal.fire({
                            icon: 'success',
                            title: resp.data.message,
                            showConfirmButton: true
                        })
                    }
                    handleClose();
                }).catch(async (err) => {
                    Swal.close();
                    if (err.response.status !== 500) {
                        Swal.fire({
                            icon: 'error',
                            title: err.response.data.message || "El producto no tiene demanda",
                            showConfirmButton: true
                        })
                    } else {
                        //logs de error
                        const mensaje = {
                            message: "Error al cacular el modelo",
                            level: 3
                        };
                        await api.post('/logger', mensaje);
                        ///Mensaje de error
                        Swal.fire({
                            icon: 'error',
                            title: mensaje.message,
                            showConfirmButton: true
                        })
                    }
                });
        }
    }
    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Clasificación: {clasificacion} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Seleccionar el modelo</Form.Label>
                            <Form.Select
                                id="selectToastPlacement"
                                className="mt-2"
                                autoFocus
                                onChange={(e) => setModelo(e.target.value)}
                                value={modelo}
                            >
                                <option value="">Escoger el modelo</option>
                                {listaModelo.map((p) => (
                                    <option key={p} value={p.name} >
                                        {p.name}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={aplicarModelo} disabled={!modelo}>
                        Aplicar modelo
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default ModalAplicarModel;