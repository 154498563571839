import React from 'react'
import { Link } from 'react-router-dom'

export const PageErrorPermisos = () => {
    return (
        <article style={{ padding: "100px" }}>
            <h1>Oops!</h1>
            <p>No tienes permiso para esta página</p>
            <div className="flexGrow">
                <Link to="/">Visita nuestra página principal</Link>
            </div>
        </article>
    )
}
