import React, { useEffect, useState } from "react";
import Cookies from 'universal-cookie';
import api from "../../services/api";
import Button from 'react-bootstrap/Button';
import Swal from 'sweetalert2';
import ListGroup from 'react-bootstrap/ListGroup';
import { MdDelete } from 'react-icons/md'


const cookies = new Cookies();

const ListModels = ({ listaModelo, listarmodelo, getModelo }) => {

    //Efffect para obtener productos
    useEffect(() => {
    }, [])

    const deleteModelo = (item) => {
        Swal.fire({
            title: '¿Estás seguro de eliminar el modelo "'+item.name+'"?',
            text: "¡No podrás revertirlo!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const res = await api.delete('/models/delete/' + item._id)
                const msg = res.data.message
                if (res.data.status) {
                //Refresacar la pagina
                listarmodelo();
                //Alerta de editado
                Swal.fire({
                    icon: 'success',
                    title: msg,
                    showConfirmButton: false,
                    timer: 2000
                })
            } else {
                //logs de error
                const mensaje = {
                    message: "Error al eliminar el modelo",
                    level: 3
                }
                await api.post('/logger', mensaje)
                ///Mensaje de error
                Swal.fire({
                    icon: 'error',
                    title: msg,
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        }
        });
}
return (
    <>
        <h3>Lista de modelos</h3>
        <ListGroup as="ol">
            {listaModelo.map(item => (
                <ListGroup.Item
                    action
                    as="li"
                    key={item.name}
                    onClick={() => getModelo(item)}
                    className="d-flex justify-content-between align-items-start"
                >
                    <div className="ms-2 me-auto">
                        <div className="fw-bold">{item.name}</div>
                        {item.description}
                    </div>
                    <Button variant="danger" onClick={() => deleteModelo(item)}>
                        <MdDelete />
                    </Button>
                </ListGroup.Item>
            ))}
        </ListGroup>
    </>
);
};
export default ListModels;